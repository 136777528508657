import {
  Grid,
  Tooltip,
  IconButton,
  Paper,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { styled } from "@mui/material/styles";

import ClassCard from "components/TeacherPanel/components/ClassCard";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const TeacherCard = ({ teacher, editMode = true, fullWidth = false }) => {
  return (
    <Grid item xs={fullWidth ? 12 : 4}>
      <Item>
        <>
          {teacher.firstName} {teacher.lastName}
        </>

        {editMode ? (
          <>
            <Tooltip title="редактирай">
              <IconButton>
                <EditIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="изтрий">
              <IconButton>
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </>
        ) : null}
      </Item>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>класове</Typography>
        </AccordionSummary>
        <AccordionDetails>
          {teacher.classes.map((cl, idx) => {
            return (
              <ClassCard
                userId={teacher.id}
                key={cl.id}
                classData={cl}
                editMode={false}
                quizLink={false}
                fullWidth
              />
            );
          })}
        </AccordionDetails>
      </Accordion>
    </Grid>
  );
};

export default TeacherCard;


import { Link } from "react-router-dom";

import Layout from 'components/Layout';

import { ReactComponent as Kids } from './assets/kids.svg';
import { ReactComponent as Kindergarden } from './assets/kindergarden.svg';
import { ReactComponent as Teddy } from './assets/teddy.svg';
import infoCasel from './assets/CASEL.jpg';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { Typography } from '@mui/material';
import {
    Paper,
} from '@mui/material';

import './Home.scss';
import HomeInformation from './HomeInformation';
import { info } from 'sass';


const ForTheProgram = () => {
    return (
        <Layout>
            <section className="home-section">
                <Paper elevation={12}>
                    <div className="home-container container">
                        <div className="quote">
                            <h1>“Основно умение в социалното познание е емпатията - осъзнаването, че другите чувстват и мислят, без да ни го назовават с думи”.
                            </h1>
                            <h2>Даниъл Голман</h2>
                        </div>
                    </div>
                </Paper>
                <Paper elevation={12}>
                    <div className="home-container container">

                        <div className="home-info">
                            <h1>“Окрилено начало: Програма за социално-емоционално образование”</h1>
                            <p style={{ display: "block" }}>Фондация “Лъчезар Цоцорков” финансира <a href="https://tsotsorkovfoundation.bg/project/%d0%bf%d1%80%d0%be%d0%b3%d1%80%d0%b0%d0%bc%d0%b0-%d1%81%d0%be%d1%86%d0%b8%d0%b0%d0%bb%d0%bd%d0%be-%d0%b8-%d0%b5%d0%bc%d0%be%d1%86%d0%b8%d0%be%d0%bd%d0%b0%d0%bb%d0%bd%d0%be-%d0%be%d0%b1%d1%80%d0%b0/" target="blank">13 организации</a>, които разработиха различни интервенции в сферата на социално-емоционалното образование в продължение на една година.  Проектите обхванаха училища в над 80 населени места, 400+ учители, 6300+ родители, 4100+ деца и 3 детски градини.
                            </p>
                            <br />
                            <p>След апробация и оценка, интервенциите бяха синтезирани в система за въвеждане на СЕУ от екип от педагози, директори, методисти, и детски психолози.  Системата “Окрилено начало: Програма за социално-емоционално образование” съдържа 4 помагала, обучение за учители, подкрепящи дейности и е тествана в 8 начални училища сред 174 учители и 1947 деца.
                            </p>
                        </div>
                        <div className="home-graphics">
                            <Kindergarden />
                        </div>
                    </div>
                </Paper>
            </section>
        </Layout>
    )
}

export default ForTheProgram;
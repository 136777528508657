import React, { useState } from 'react';
import { EventDetails, EventDetailsEn } from "components/EventDetails";
import Layout from "components/Layout";
import { Switch, FormControlLabel, Container } from '@mui/material';

const OECD = () => {
    const [language, setLanguage] = useState('EN');
    const toggleLanguage = (event) => {
        setLanguage(language === 'EN' ? 'BG' : 'EN');
    };
    return (
        <Layout>
            <Container>
                <FormControlLabel
                    control={<Switch checked={language === 'BG'} onChange={toggleLanguage} />}
                    label={language === 'EN' ? 'Switch to Bulgarian' : 'Switch to English'}
                />
                {language === 'EN' ? <EventDetailsEn /> : <EventDetails />}
            </Container>
        </Layout>   
    )

}

export default OECD;
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Switch, FormControlLabel, Container } from '@mui/material';
import Layout from 'components/Layout/Layout';
import ReactPlayer from 'react-player';

import './LiveStream.scss';
import CountdownCircle from './CountdownCircle';
import { useParams } from 'react-router-dom';

const LiveStream = ({
}) => {
  let search = window.location.search;
  let params = new URLSearchParams(search);
  let isLive = params.get('live');
  const [time, setTime] = useState(moment.utc());
  const [isUpcomming, setIsUpcomming] = useState(true);
  const url = "https://www.twitch.tv/oecd2024"
  const date = moment.utc("2024-04-26T08:20:00Z");
  const now = moment();
  useEffect(() => {
    if (!moment.utc().isBefore(moment(date))) {
      setIsUpcomming(false);
    }
  }, [time])

  useEffect(() => {
    if (isUpcomming) {
      var timer = setInterval(() => setTime(moment.utc()), 1000)
      return function cleanup() {
        clearInterval(timer)
      }
    }

  });
  const [language, setLanguage] = useState('EN');
  const toggleLanguage = (event) => {
    setLanguage(language === 'EN' ? 'BG' : 'EN');
  };


  return (
    <Layout title={`Представяне на Първи Доклад от втори кръг изследване на ОИСР касаещо социално-емоционалните умения на учениците `}>
      <section className="live-stream-hero-section">
        <div className="live-stream-hero-container container">
          <h1 className="heading-name">{language === 'BG' ? 'Представяне на Първи Доклад от втори кръг изследване на ОИСР касаещо социално-емоционалните умения на учениците ' : 'Global Launch of the OECD’s 2023 Survey on Social and Emotional Skills'}</h1>
        </div>
      </section>
      <Container>
        <FormControlLabel
          control={<Switch checked={language === 'BG'} onChange={toggleLanguage} />}
          label={language === 'EN' ? 'Switch to Bulgarian' : 'Switch to English'}
        />
      </Container>
      <section className="live-stream-section">
        <div className="live-stream-container container">
          {now.isBefore(moment(date)) && isUpcomming && !isLive ?
            <div className="counter-display">
              {language === "BG"? <h2>Остават:</h2> : <h2>Time remaining:</h2>}
              <CountdownCircle date={moment(date)} language={language} setIsUpcomming={setIsUpcomming}></CountdownCircle>
            </div>
            :
            <div className="flex-items">
              <div className='react-player-container'>
                <ReactPlayer
                  className='react-player'
                  url={url}
                  width='100%'
                  height='auto'
                />
              </div>
              <iframe
                className='slido'
                src={`https://app.sli.do/event/hkthnnjh`}
                height="100%"
                width="100%"
                frameBorder="0"
                title={`live stream`}
              >
              </iframe>
            </div>
          }
        </div>

      </section>
    </Layout>
  );
};


export default LiveStream;

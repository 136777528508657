import AudioButton from "components/Demo/AudioButton";


const QuizHeader = ({
  question,
  index,
  isAudioPlaying,
  setIsAudioPlaying,
  audio,
  setAudio,
}) => {
  return (
    <section className="question-section question-header">
      <h1 className="question-heading">{index + 1}. {question.title}
        {question.audio?.title != undefined ?
          <AudioButton
            audioPath={`/audio/${question.audio?.title}`}
            isAudioPlaying={isAudioPlaying}
            setIsAudioPlaying={setIsAudioPlaying}
            audio={audio}
            setAudio={setAudio}
          ></AudioButton>
          : null}
      </h1>

    </section >
  )
}

export default QuizHeader;

import {
    LOGIN_SUCCESS,
    LOGOUT_SUCCESS,
    ADD_CLASS_SUCCESS,
    EDIT_CLASS_SUCCESS,
    DELETE_CLASS_SUCCESS,
    FORGOTTEN_PASSWORD_SUCCESS,
    ACTIVATE_USER_SUCCESS,
    FETCH_USERS_SUCCESS
} from './actionTypes';

import {
    NOTIFICATION_TYPES,
} from '../constants';

import { showNotification } from './notificationActions';
import { auth, db } from '../firebase.js';
import { collection, doc, getDoc, getDocs, setDoc, where, updateDoc, arrayUnion, query } from 'firebase/firestore';
import { v4 as uuid } from "uuid";
import { handleFirebaseError } from 'utils/helpers';

// Simple Action Creators
const fetchLoginSuccess = (payload) => ({
    type: LOGIN_SUCCESS,
    payload,
});

const fetchForgottenEmailSuccess = (payload) => ({
    type: FORGOTTEN_PASSWORD_SUCCESS,
    payload
})

const fetchAddClassSuccess = (payload) => ({
    type: ADD_CLASS_SUCCESS,
    payload,
});

const fetchDeleteClassSuccess = (payload) => ({
    type: DELETE_CLASS_SUCCESS,
    payload,
})

const fetchEditClassSuccess = (payload) => ({
    type: EDIT_CLASS_SUCCESS,
    payload,
})

const logoutSuccess = () => ({
    type: LOGOUT_SUCCESS,
});

const fetchActivateUserSuccess = (payload) => ({
    type: ACTIVATE_USER_SUCCESS,
    payload,
})

const fetchDisableUserSuccess = (payload) => ({
    type: ACTIVATE_USER_SUCCESS,
    payload,
})

const fetchChangeAdministratorSuccess = (payload) => ({
    payload,
})

const fetchUsersSuccess = (payload) => ({
    type: FETCH_USERS_SUCCESS,
    payload,
});

export const login = (data) => async (dispatch) => {
    try {
        const response = await auth.signInWithEmailAndPassword(data.email, data.password);
        const userDoc = await getDoc(doc(db, "users", response.user.uid));
        const userData = userDoc.data();
        let schoolData = [];
        if (userData.school) {
            let schoolDoc = await getDoc(doc(db, "schools", userData.school));
            schoolData = schoolDoc.data();
            schoolData.id = userData.school;
        }

        if (userData.roles.includes("ADMINISTRATOR")) {
            let teachersInSchool = await getDocs(query(collection(db, "users"), where("school", "==", userData.school)));
            let teachers = [];
            teachersInSchool.forEach(t => {
                teachers.push({ id: t.id, ...t.data() });
            });
            schoolData.teachers = teachers;
        }

        let teacherSessions = [];
        if (userData.sessions) {
            teacherSessions = await getDocs(query(collection(db, "sessions"), where("teacher", "==", response.user.uid)));
        }
        let sessions = [];
        teacherSessions.forEach(session => {
            let sessionData = session.data();
            sessionData.id = session.id;
            sessions.push(sessionData);
        });
        let user = {
            user: {
                id: response.user.uid,
                email: response.user.email,
                roles: userData.roles,
                classes: userData.classes,
                school: schoolData,
                sessions: sessions,
                firstName: userData.firstName,
                lastName: userData.lastName,
                active: userData.active,
            },
            jwt: response.user.refreshToken,
        }
        dispatch(fetchLoginSuccess(user));
        dispatch(showNotification({
            type: NOTIFICATION_TYPES.SUCCESS,
            message: "Влязохте успешно!"
        }))

    } catch (error) {
        let message = handleFirebaseError(error.code);

        dispatch(showNotification({
            type: NOTIFICATION_TYPES.ERROR,
            message: message
        }));
        throw error;
    }
};

export const logout = (userEmail) => async (dispatch) => {
    try {
        // await authService.logout(userEmail);
        dispatch(logoutSuccess());
    } catch (error) {
        throw error;
    }
};

export const register = (registerData) => async (dispatch) => {
    try {
        let schoolData = registerData.schoolData;
        let schoolUId = `${schoolData.region}|${schoolData.town}|${schoolData.name}`;
        let userData = registerData.userData;
        let schoolDoc = await getDoc(doc(db, "schools", schoolUId));
        let schoolExists = false;
        const response = await auth.createUserWithEmailAndPassword(userData.email, userData.password);
        let school;

        if (schoolDoc.exists()) {
            school = schoolDoc;
            schoolExists = true;
        }
        else {
            school = doc(db, "schools", schoolUId);
            await setDoc(school, schoolData);
        }

        let user = {
            email: userData.email,
            roles: ["TEACHER"],
            classes: [],
            school: schoolUId,
            sessions: [],
            firstName: userData.firstName,
            lastName: userData.lastName,
            active: false,
        };

        if (!schoolExists) {
            user.roles.push("ADMINISTRATOR");
        }

        const userRef = doc(db, "users", response.user.uid);
        await setDoc(userRef, user);
        if (!schoolExists) {
            await updateDoc(doc(db, "schools", schoolUId), {
                administrator: userRef.id
            });
        }
        await updateDoc(doc(db, "schools", schoolUId), {
            teachers: arrayUnion(userRef.id)
        })

        if (response.statusCode === 400) {
            throw new Error(response.statusMessage);
        }

        dispatch(showNotification({
            type: NOTIFICATION_TYPES.SUCCESS,
            message: "Успешна регистрация!"
        }))
    } catch (error) {
        let message = handleFirebaseError(error.code);

        dispatch(showNotification({
            type: NOTIFICATION_TYPES.ERROR,
            message: message
        }));
        throw error;
    }
};

export const forgottenPassword = (email) => async (dispatch) => {

    auth.sendPasswordResetEmail(email)
        .then((res) => {
            dispatch(showNotification({
                type: NOTIFICATION_TYPES.INFO,
                message: "Проверете електронната си поща"
            }));
        })
        .catch((error) => {
            let message = handleFirebaseError(error.code);
            dispatch(showNotification({
                type: NOTIFICATION_TYPES.ERROR,
                message
            }));
        });
}

export const addClass = (userId, classData) => async (dispatch) => {
    try {
        const userRef = doc(db, "users", userId);
        classData.id = uuid();
        await updateDoc(userRef, {
            classes: arrayUnion(classData)
        })

        let updatedUser = await getDoc(userRef);
        let userData = updatedUser.data();
        userData.id = userId;


        let teacherSessions = await getDocs(query(collection(db, "sessions"), where("teacher", "==", userData.id)));
        let sessions = [];
        teacherSessions.forEach(session => {
            let sessionData = session.data();
            sessionData.id = session.id;
            sessions.push(sessionData);
        });
        userData.sessions = sessions;
        let schoolDoc = await getDoc(doc(db, "schools", userData.school));
        let schoolData = schoolDoc.data();
        schoolData.id = userData.school;

        if (userData.roles.includes("ADMINISTRATOR")) {
            let teachersInSchool = await getDocs(query(collection(db, "users"), where("school", "==", userData.school)));
            let teachers = [];
            teachersInSchool.forEach(t => {
                teachers.push({ id: t.id, ...t.data() });
            });
            schoolData.teachers = teachers;
        }

        userData.school = schoolData;


        dispatch(fetchAddClassSuccess(userData));
    } catch (err) {
        throw err;
    }
};

export const deleteClass = (userId, classId) => async (dispatch) => {
    try {
        const userRef = doc(db, "users", userId);
        let updatedUser = await getDoc(userRef);
        let userData = updatedUser.data();
        userData.id = userId;
        userData.classes = userData.classes.filter(x => x.id != classId);

        await updateDoc(userRef, {
            classes: userData.classes
        })

        let schoolDoc = await getDoc(doc(db, "schools", userData.school));
        let schoolData = schoolDoc.data();
        schoolData.id = userData.school;
        if (userData.roles.includes("ADMINISTRATOR")) {

            let teachersInSchool = await getDocs(query(collection(db, "users"), where("school", "==", userData.school)));
            let teachers = [];
            teachersInSchool.forEach(t => {
                teachers.push({ id: t.id, ...t.data() });
            });
            schoolData.teachers = teachers;

        }
        userData.school = schoolData;
        let teacherSessions = await getDocs(query(collection(db, "sessions"), where("teacher", "==", userData.id)));
        let sessions = [];
        teacherSessions.forEach(session => {
            let sessionData = session.data();
            sessionData.id = session.id;
            sessions.push(sessionData);
        });
        userData.sessions = sessions;

        dispatch(fetchDeleteClassSuccess(userData));
    } catch (err) {
        throw err;
    }
};


export const editClass = (userId, classData) => async (dispatch) => {
    try {
        const userRef = doc(db, "users", userId);
        let updatedUser = await getDoc(userRef);
        let userData = updatedUser.data();
        userData.id = userId;
        let newClasses = [];
        for (let index = 0; index < userData.classes.length; index++) {
            const element = userData.classes[index];
            if (element.id === classData.id) {
                newClasses.push(classData);
                userData.classes[index] = classData;
            }
            else {
                newClasses.push(userData.classes[index])
            }
        }

        await updateDoc(userRef, {
            classes: newClasses
        })

        let schoolDoc = await getDoc(doc(db, "schools", userData.school));
        let schoolData = schoolDoc.data();
        schoolData.id = userData.school;

        if (userData.roles.includes("ADMINISTRATOR")) {
            let teachersInSchool = await getDocs(query(collection(db, "users"), where("school", "==", userData.school)));
            let teachers = [];
            teachersInSchool.forEach(t => {
                teachers.push({ id: t.id, ...t.data() });
            });
            schoolData.teachers = teachers;

        }
        userData.school = schoolData;

        let teacherSessions = await getDocs(query(collection(db, "sessions"), where("teacher", "==", userData.id)));
        let sessions = [];
        teacherSessions.forEach(session => {
            let sessionData = session.data();
            sessionData.id = session.id;
            sessions.push(sessionData);
        });
        userData.sessions = sessions;

        dispatch(fetchEditClassSuccess(userData));
    } catch (err) {
        throw err;
    }
};

export const activateUser = (userId) => async (dispatch) => {
    try {
        const userRef = doc(db, "users", userId);
        await updateDoc(userRef, {
            active: true
        })

        let updatedUser = await getDoc(userRef);
        let userData = updatedUser.data();
        userData.id = userId;
        // dispatch(fetchAddClassSuccess(userData));
        dispatch(fetchActivateUserSuccess(userData))
    } catch (err) {
        throw err;
    }
}

export const disableUser = (userId) => async(dispatch) => {
    try {
        const userRef = doc(db, "users", userId);
        await updateDoc(userRef, {
            active: false,
            roles: ['TEACHER']
        })

        let updatedUser = await getDoc(userRef);
        let userData = updatedUser.data();
        userData.id = userId;
        dispatch(fetchDisableUserSuccess(userData))
    } catch (err) {
        throw err;
    }
}

export const fetchUsers = () => async (dispatch) => {
    try {
        let usersDocs = await getDocs(collection(db, "users"));
        let users = [];
        usersDocs.docs?.forEach(async (u) => {
            let userData = u.data();
            userData.id = u.id;
            users.push(userData);
        })
        dispatch(fetchUsersSuccess(users));
    } catch (error) {
        throw error;
    }
}

export const changeAdministrator = (userId, schoolId) => async (dispatch) => {
    try {
        // get School
        // get currentAdministrator
        // get newAdministrator

        // school. admin = newAdmin
        // currentAdmin.roles remove Administrator
        // newAdministrator add Role Administrator

    }
    catch {

    }
}
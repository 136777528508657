import React from 'react';
import './TermsAndConditions.scss';
import Layout from 'components/Layout';

const TermsAndConditions = () => {
  return (
    <Layout>
      <div className="terms-container">
        <h1>ОБЩИ УСЛОВИЯ</h1>
        <h2>НА ОНЛАЙН ПЛАТФОРМА ЗА ДИРЕКТНА ОЦЕНКА НА СОЦИАЛНО-ЕМОЦИОНАЛНИТЕ КОМПЕТЕНТНОСТИ НА УЧЕНИЦИ ОТ 1-4 КЛАС</h2>

        <h3>Определения</h3>
        <p>Освен ако изрично не е посочено друго в този документ, термините, използвани в настоящите Общи условия, ще бъдат дефинирани както следва:</p>
        <p><strong>Доставчик</strong> – фондация „Лъчезар Цоцорков“, фондация, регистрирана в Търговския регистър и регистъра на юридическите лица с нестопанска цел към Агенцията по вписванията , ЕИК 205724187, със седалище и адрес на управление: 1504 гр. София, р-н Оборище, ул. Върбица №5, ет. 1, ап. 2, адрес за кореспонденция: email: sel@tsotsorkovfoundation.bg</p>

        <p>представлявано от Димитър Лъчезаров Цоцорков, Велислава Лъчезарова Цоцоркова, Атанаска Стоянова Цоцоркова. Доставчикът одобрява изпратена заявка от Администратора на Училище за използване на SEL.BG</p>

        <p><strong>Училище</strong> – учебно заведение, в което е интегрирана дигиталната платформа SEL.BG</p>
        <p><strong>Администратор</strong> - Оправомощено лице да представлява училището; Администраторът на Училището одобрява подадената от Учителя заявка за създаване на профили.</p>

        <p><strong>Потребител</strong> – учители и директори, ученици от 1-4 клас, регистрирани в платформата SEL.BG</p>
        <p><strong>Учител</strong> – физически лица – учители на ученици от 1-4 клас, в учебни заведения, интегрирали платформата SEL.BG</p>
        <p>Директори - физически лица директори на основни училища, които са регистрирани в платформата SEL.BG, анализиращи социално-емоционалните резултати на ученици 1-4 клас.</p>
        <p>Ученици – физически лица – учащи деца от 1-4 клас в училища, интегрирали дигиталната платформа SEL.BG</p>

        <p>Учителски профил - страница на учителски профил, видима за потребителите на SEL.BG</p>
        <p>Профил на клас –страница, създадена от учител/директор на съответния клас от ученици от 1-4 клас в училища, интегрирали дигиталната платформа SEL.BG</p>
        <p>Профил на ученик – персонална страница, създадена от всеки учител за всеки отделен ученик от 1-4 клас в училища, интегрирали дигиталната платформа SEL.BG</p>
        <p>Родител/Настойник - законен представител на Ученик;</p>
        <p>SEL.BG – онлайн платформа/система за директна оценка на социално-емоционалните компетентности на ученици от 1-4 клас</p>
        <p>Цел – извършване на оценка на социално – емоционалната компетентност на ученици от 1-4 клас</p>
        <p>Въпроси – въпроси, свързани с картинни изображения, на които Ученикът отговаря за постигане на Целта и които са разработени специално за деца от 1-4 клас с цел да оценят социалното и емоционалното развитие на децата.</p>
        <p>Резултати – Резултатите от отговорите на въпроси, предмет на платформата; Резултатите не се видни за Учениците, а само за Учителя и Училището</p>
        <h3>Приложимост на общите условия</h3>
        <p>Тези Общи условия уреждат взаимоотношенията между Доставчика и Училището, представлявано от Администратора и неговите Потребители по отношение на достъпа до онлайн платформата за директно оценяване на социално-емоционалните компетентности на ученици 1-4 клас SEL.BG и правото на използване на уеб базираната система SEL.BG.</p>
        <p>С приемането на тези условия вие се съгласявате с тях.</p>
        <p>С приемането на тези условия Вие заявявате, че имате право да представлявате Училището/или Учителя и сте надлежно информирани, че с приемането им за възникват права и задължения, както са посочени по-долу.</p>
        <p>Настоящите Общи условия могат да бъдат изменяни по преценка на Доставчика. По този начин, за да бъде валидно споразумението, Доставчикът следва да информира Училището, чрез Администратора в срок от 20 (двадесет) дена след тяхното изменение. Измененията влизат в сила и ще бъдат обвързващи за Училището и Потребителите след изтичане на посочения срок.</p>

        <h3>Регистрация</h3>
        <p>Доставчикът предоставя на Потребителите правото да използва уеб базираната система SEL.BG след регистрация, направена от Учителя/Директора, които създават профили на клас и ученик, като посочват име, фамилия, както и общината, към която е училището на ученика от 1-4 клас.</p>
        <p>Ученикът може да използва профила след запознаване на Учителя, Директора и Родителя/Настойника му със SEL.BG и изпълнение от страна на Училището/Учителя или Директора с изискванията на действащото българско и европейско законодателство в областта на защита на личните данни.</p>

        <h3>Права и задължения на потребителя</h3>
        <p>Потребителят е длъжен да използва услугата лично и не може да прехвърля правото си на ползване на SEL.BG  на трети лица.</p>
        <p>Потребителят се съгласява и декларира, че няма да използва SEL.BG или данните/резултатите от нея за:</p>
        <ul>
          <li>използване на SEL.BG по незаконен начин или по какъвто и да е друг начин, който би могъл да увреди, забрани, претовари или наруши ползването на платформата, правата на Доставчика или правата на Потребителите;</li>
          <li>неправилно деклариране или представяне по друг начин на самоличността на Потребителите, включително, но без да се ограничава до използването на псевдоним, качване, публикуване, предаване, споделяне, съхраняване или по друг начин публично предоставяне на платформа SEL.BG на лична информация на трета страна, ограничения, адреси, телефонни номера, имейл адреси, номера и други.</li>
          <li>качване, публикуване, предаване, споделяне или по друг начин предоставяне на материали, които съдържат софтуерни вируси или друг компютърен код, файлове или програми, предназначени да прекъсват, унищожават или ограничават функционалността на всеки компютърен софтуер или хардуер или телекомуникационно оборудване.</li>
          <li>сплашване или друг вид тормоз.</li>
          <li>публикуване, променяне, разпространяване или възпроизвеждане по какъвто и да е начин всички материали, търговски марки или друга собствена информация, принадлежащи на други лица, без да получат предварителното писмено съгласие на собственика на такива права.</li>
        </ul>
        <p>Доставчикът притежава всички права на интелектуална собственост върху софтуера на SEL.BG, включително, но не само, наименования, търговски марки, дизайн и други.</p>
        <p>Освен ако не е предвидено друго в ЗАПСП, Училището или Потребителите нямат право да изменят, адаптират, превеждат или по друг начин да създава производни произведения от SEL.BG, нито да прехвърля или преотстъпва, изцяло или отчасти, на друго лице правата си да ползват Платформата.</p>

        <p>Потребителят признава глобалния характер на интернет и се съгласява да спазва всички приложими местни правила, включително, но не само правилата за онлайн поведение и приемливо съдържание. По-конкретно, Потребителят се съгласява да спазва всички приложими закони относно предаването на данни</p>

        <p>В случай на искове на трети лица срещу Доставчика срещу действия на Потребителя, Потребителят е длъжен да понесе всички правни, административни и други разходи, свързани с тези искове.</p>
        <p>В случай на неизпълнение от страна на Потребителя на горните условия, Доставчикът има право да прекрати достъпа му до услугата незабавно.</p>
        <p>Потребител, приел Условията на SEL.BG, има правото да използва услугите, ресурсите и информацията, както и Резултатите, които са генерирани след преминаване през Въпросите, освен в случаите, когато е налице нарушение на тези Условия, както и Злоумишлено действие от негова страна.</p>
        <p>Всеки Потребител на Сайта има правото да дава своето мнение, препоръка и предложение на е-mail адреса, посочен по-горе, с цел подобряване качеството на съдържанието, ресурсите, функционалностите и информацията, която Доставчикът му предоставя за ползване чрез публикуването им в Сайта.</p>

        <h3>Права и задължения на доставчика</h3>
        <p>Доставчикът се задължава да поддържа пълната функционалност на уеб базираната система SEL.BG и да изпълнява всички изисквания на приложимото право при предоставяне на услугата.</p>
        <p>Доставчикът не носи отговорност за:</p>
        <ul>
          <li>Всякакви проблеми и / или прекъсвания на функционирането на SEL.BG, които се дължат на причини извън неговия контрол.</li>
          <li>Функционирането на SEL.BG, ако Потребителят не е спазил техническите изисквания за работа на компютърните системи.</li>
          <li>Наличността, съдържанието, автентичността или сигурността на външни уебсайтове или други източници на информация.</li>
          <li>Действия, небрежност или пропуски от страна на трети страни, включително, но не само: доставчици на телекомуникации, компютърни и интернет услуги, подизпълнители или за грешки или пропуски на техните устройства, оборудване или системи.</li>
          <li>Отговорите на въпросите.</li>
          <li>Резултатите от участие в платформата.</li>
        </ul>

        <h3>Защита на личните данни</h3>
        <p>Преди регистрация родителят или настойникът на субекта на данните (ученик от 1-4 клас), следва да изрази/даде информирано съгласие личните данни на детето му, а именно училище, клас, имена, дата на раждане, пол, email адрес, да бъдат обработвани от Училището/Учителя/Директора и Доставчика на услугата, за нейните цели и по начин, по който сметне за необходим. Подробна информация за това как Доставчикът обработва лични данни, можете да намерите тук: [URL or placeholder for the link].</p>

        <h3>Поверителност</h3>
        <p>Доставчикът и Потребителите считат за поверителна цялата информация, обменяна между тях във връзка с използването на платформата, както и Резултатите от използването на платформата. Доставчикът и Потребителите са длъжни да пазят такава информация поверителна и да се въздържат от действия, които биха нарушили или навредили на тяхната репутация и интереси.</p>

        <h3>Отказ от отговорност</h3>
        <p>Доставчикът не носи отговорност за:</p>
        <ul>
          <li>Начина на ползване на Платформата от Потребителите, отговорите на Въпроси и Резултатите.</li>
          <li>Начина на ползване на Резултатите.</li>
          <li>Пропуснати ползи и нанесени щети, настъпили вследствие изтриването на данни, информация, или какъвто и да е вид съдържание, разположено на Сайта; спирането, променянето или ограничаването на услугите и/или ресурсите, разположени в платформата; изтриването, модифицирането, загубването на данни, пренасяни, използвани, записвани или станали достъпни за Потребителите на Сайта.</li>
          <li>Вреди, причинени върху софтуера, хардуера или телекомуникационните съоръжения, вследствие използването на съдържание, данни, ресурси, информация и функционалности, които Платформата предоставя на своите Потребители.</li>
        </ul>
      </div>
    </Layout>
  );
}

export default TermsAndConditions;
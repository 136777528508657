import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import {getFirestore} from 'firebase/firestore';

const app = firebase.initializeApp({
  apiKey: "AIzaSyAtonPBBgniWFw4nEsi0r_mEg09xzNeb40",
  authDomain: "eqquizapp.firebaseapp.com",
  databaseURL: "https://eqquizapp-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "eqquizapp",
  storageBucket: "eqquizapp.appspot.com",
  messagingSenderId: "275234657006",
  appId: "1:275234657006:web:76b7e94126a659642519ff"
});

export const auth = app.auth();
export const db = getFirestore(app);
export default app;
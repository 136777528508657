import {
    ADD_CLASS_SUCCESS,
    DELETE_CLASS_SUCCESS,
    LOGIN_SUCCESS,
    LOGOUT_SUCCESS,
    UPDATE_USER_SUCCESS,
    FETCH_USERS_SUCCESS,
} from '../actions/actionTypes';


import { ROLES } from '../constants.js';

const initialState = {
    jwtToken: '',
    user: {
        email: '',
        roles: [],
        _id: '',
        verified: false,
        active: false,
        firstName: '',
        lastName: '',
        classes: [{
            name: '',
            id: '',
            students: [{
                name: '',
                number: '',
            }]
        }],
    },
    users: []
};

const auth = (state = initialState, action) => {
    switch (action.type) {
        case LOGIN_SUCCESS:
            return { ...state, jwtToken: action.payload.jwt, user: action.payload.user }
        case LOGOUT_SUCCESS:
            return initialState;
        case ADD_CLASS_SUCCESS:
        case DELETE_CLASS_SUCCESS:
        case UPDATE_USER_SUCCESS:
            return { ...state, user: action.payload }
        case FETCH_USERS_SUCCESS:
            return { ...state, users: action.payload }
        default:
            return state;
    }
};

export default auth;

// User
export const isAuthenticated = (state) => Boolean(state.jwtToken);
export const isAdmin = (state) => Boolean(state.user.roles.includes(ROLES.ADMIN));
export const isAdministrator = (state) => Boolean(state.user.roles.includes(ROLES.ADMINISTRATOR));
export const isActive = (state) => Boolean(state.user.active);
export const isTeacher = (state) => Boolean(state.user.roles.includes(ROLES.TEACHER));
export const getUserRoles = (state) => state.user.roles;
export const getUser = (state) => state.user;
export const getUsers = (state) => state.users;
export const getUserSchool = (state) => state.user.school;
export const getIdToken = (state) => state.jwtToken;
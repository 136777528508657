import React, { useState } from "react";
import moment from "moment";
import {
  Button,
  Paper,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Slide,
  ToggleButtonGroup,
  ToggleButton,
} from "@mui/material";
import Draggable from "react-draggable";

import "./CreateSessionModal.scss";
import { SESSION_TYPES } from "../../../../constants";

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const CreateSessionModal = ({
  open,
  handleClose,
  handleSubmitForm,
  sessions,
}) => {
  const [sessionType, setSessionType] = useState(undefined);
  const handleSubmit = (event) => {
    event.preventDefault();
    if (sessionType) {
      let sameSession = sessions.filter(x => x.year === moment().year() && x.type === sessionType)
      if (sameSession.length === 0) {
        handleSubmitForm(sessionType);
        console.log("has such session", sameSession);
      }


      handleClose();
    }
  };

  const handleDialogClose = () => {
    handleClose();
  };

  const handleChange = (event, nextView) => {
    setSessionType(nextView);
  };

  return (
    <Dialog
      open={open}
      // onSubmit={handleSubmit}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
      scroll="paper"
      TransitionComponent={Transition}
    >
      <form onSubmit={handleSubmit}>
        <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
          Започни сесия
        </DialogTitle>
        <DialogContent>
          <ToggleButtonGroup
            color="primary"
            orientation="vertical"
            value={sessionType}
            exclusive
            onChange={handleChange}
            fullWidth
          >
            <ToggleButton value={SESSION_TYPES.INITIAL} disabled={sessions.filter(x => x.year === moment().year() && x.type === SESSION_TYPES.INITIAL).length > 0 ? true : false} aria-label="list">
              Първоначална
            </ToggleButton>
            <ToggleButton value={SESSION_TYPES.FINAL} disabled={sessions.filter(x => x.year === moment().year() && x.type === SESSION_TYPES.FINAL).length > 0 ? true : false} aria-label="module">
              Финална
            </ToggleButton>
          </ToggleButtonGroup>


        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose}>Отказ</Button>
          <Button type="submit">Започни сесия</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default CreateSessionModal;

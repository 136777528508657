import { db } from '../firebase.js';
import { collection, doc, getDoc, setDoc, getDocs } from 'firebase/firestore';

import {
    FETCH_QUIZ_ANSWERS_SUCCESS,
    SUBMIT_QUIZ_SUCCESS
} from './actionTypes';

// Simple Action Creators
const fetchQuizAnswersSuccess = (payload) => ({
    type: FETCH_QUIZ_ANSWERS_SUCCESS,
    payload,
});
const submitQuizAnswersSuccess = (payload) => ({
    type: SUBMIT_QUIZ_SUCCESS,
    payload,
});

// Complex Action Creators
export const saveQuizAnswers = (data) => async (dispatch) => {
    let answer;
    try {
        let answerId = `${data.sessionId}|${data.schoolId}|${data.classId}|${data.student.number}`;
        let answerDoc = await getDoc(doc(db, "answers", answerId));
        if (!answerDoc.exists()) {
            answer = doc(db, "answers", answerId);
            await setDoc(answer, data);
        }
        else {
            // TODO already answered the session
        }

    } catch (error) {
        throw error;
    }

    dispatch(submitQuizAnswersSuccess(answer));
};

export const fetchQuizAnswers = () => async (dispatch) => {
    try {
        let answers = [];
        const quizAnswers = await getDocs(collection(db, "answers"));
        quizAnswers.forEach(a => {
            answers.push(a.data());
        });
        dispatch(fetchQuizAnswersSuccess(answers));
    }
    catch (error) {
        throw error;
    }

   
}
import React from "react";
import { connect } from 'react-redux';
import { useEffect, useState } from "react";

import { getAnswers, isActive } from 'reducers';
import { fetchQuizAnswers } from 'actions/quizActions';

import { useTeacherPanel } from "./hooks";
import { TransitionUp, TabPanel, a11yProps } from "./helpers";
import { styled } from "@mui/material/styles";
import * as XLSX from 'xlsx';

import {
  Button,
  Grid,
  Tabs,
  Tab,
  Snackbar,
  Icon,
  Box,
  Paper,
  Modal,
  useTheme,
  useMediaQuery,
  IconButton,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";


import Layout from "components/Layout";
import CreateClassModal from "./components/CreateClassModal";
import EditClassModal from "./components/EditClassModal/EditClassModal";
import CreateSessionModal from "./components/CreateSessionModal";

import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Divider,
} from "@mui/material";

import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import PrintIcon from '@mui/icons-material/Print';

import "./TeacherPanel.scss";
import { SKILLS_TEXTS } from "constants";

const TeacherPanel = ({
  user,
  isAuthenticated,
  isActive,
  addClass,
  editClass,
  createSession,
  deleteClass,
  deleteSession,
  selectedClass,
  answers,
  fetchQuizAnswers
}) => {
  const {
    localState,
    handleTabChange,
    handleClose,
    showCreateClassModalHandler,
    closeCreateClassModalHandler,
    closeEditClassModalHandler,
    showCreateSessionModalHandler,
    closeCreateSessionModalHandler,
    onClassCreateFinishHandler,
    onClassEditFinishHandler,
    onSessionCreateFinishHandler,
    userClasses,
    userSessions,
  } = useTeacherPanel({
    user,
    isAuthenticated,
    addClass,
    editClass,
    createSession,
    deleteClass,
    deleteSession,
    selectedClass,
  });

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [areAnswersLoading, setAreAnswersLoading] = useState(true);
  const [teacherAnswers, setTeacherAnswers] = useState(null);
  const [resultsByClass, setResultsByClass] = useState(null);
  const [open, setOpen] = useState(false);
  const [currentAnswer, setCurrentAnswer] = useState(null);

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  }));

  const generateExcel = () => {
    const ws = XLSX.utils.json_to_sheet([
      ...currentAnswer.answersPoints.slice(1).map((point, idx) => ({
        '#': idx + 1,
        'отговор': currentAnswer.selectedAnswers[idx + 1],
        'точки': point,
        'време': `${currentAnswer.answersTime[idx + 1]}s`
      }))
    ]);

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Отговори");

    XLSX.writeFile(wb, `${currentAnswer.student.name}-${currentAnswer.year}-${currentAnswer.type}.xls`);
  };

  useEffect(() => {
    fetchQuizAnswers().then(() => {
      setAreAnswersLoading(false);
    })
  }, []);

  useEffect(() => {
    if (!areAnswersLoading) {
      let filteredByTeacherId = Object.values(answers).filter(x => x.teacherId === user.id);
      setTeacherAnswers(filteredByTeacherId);
    }
  }, [answers, areAnswersLoading]);

  var groupBy = function (xs, key) {
    return xs.reduce(function (rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  };

  useEffect(() => {
    if (teacherAnswers) {
      let groupedBySessionId = groupBy(teacherAnswers, "sessionId");
      let answersData = [];
      Object.keys(groupedBySessionId).forEach(s => {
        let groupedByClass = groupBy(groupedBySessionId[s], "classId");
        let groupedClasses = Object.values(groupedByClass);
        let classesData = [];
        groupedClasses.forEach(cl => {
          let teacherClass = user.classes.filter(c => c.id === cl[0].classId)[0];
          classesData.push({
            id: cl[0].classId,
            name: teacherClass.name,
            answers: cl.sort((a, b) => a.student.number < b.student.number),
          })

        });
        let data = {
          year: groupedBySessionId[s][0].year,
          type: groupedBySessionId[s][0].type,
          sessionId: s,
          classes: classesData.sort((a, b) => {
            if (a.name < b.name) {
              return -1;
            }
            if (a.name > b.name) {
              return 1;
            }
            return 0;
          })
        }



        answersData.push(data)

      })

      setResultsByClass(answersData);
    }
  }, [teacherAnswers]);

  const handleDetailsOpen = (answer) => {
    setCurrentAnswer(answer);
    setOpen(true);
  };

  const handleDetailsClose = () => {
    setOpen(false);
  };



  return (
    <Layout>
      <link
        rel="stylesheet"
        href="https://fonts.googleapis.com/icon?family=Material+Icons"
      />

      <CreateClassModal
        open={localState.isCreateClassModalVisible}
        handleClose={closeCreateClassModalHandler}
        handleSubmitForm={onClassCreateFinishHandler}
      />

      <EditClassModal
        open={localState.isEditClassModalVisible}
        handleClose={closeEditClassModalHandler}
        handleSubmitForm={onClassEditFinishHandler}
        classData={localState.selectedClass}
      />

      <CreateSessionModal
        open={localState.isCreateSessionModalVisible}
        handleClose={closeCreateSessionModalHandler}
        handleSubmitForm={onSessionCreateFinishHandler}
        classes={user.classes}
        sessions={user.sessions}
      />

      <Snackbar
        autoHideDuration={3000}
        open={localState.isSnackbarOpen}
        onClose={handleClose}
        TransitionComponent={TransitionUp}
        message="Успешно добавихте клас"
        transitionDuration={500}
      />

      <section className="teacher-panel-section">
        <Box sx={{ width: "100%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={localState.tab}
              onChange={handleTabChange}
              aria-label="basic tabs example"
            >
              <Tab label="Класове" {...a11yProps(0)} />
              <Tab label="Сесии" {...a11yProps(1)} />
              <Tab label="Резултати" {...a11yProps(2)} />
            </Tabs>
          </Box>
          <TabPanel value={localState.tab} index={0}>
            <Button
              className="add-button"
              onClick={showCreateClassModalHandler}
            >
              <span>Добави клас</span>
              <Icon>add_circle</Icon>
            </Button>
            <Grid
              container
              direction={{ xs: "column", sm: "row" }}
              spacing={{ xs: 1, sm: 2, md: 4 }}
            >
              {userClasses}
            </Grid>
          </TabPanel>
          <TabPanel value={localState.tab} index={1}>
            {isActive ?
              <Button
                className="add-button"
                onClick={showCreateSessionModalHandler}
              >
                <span>Стартирай сесия</span>
                <Icon>add_circle</Icon>
              </Button>
              :
              <Button
                className="add-button"
                disabled
                onClick={showCreateSessionModalHandler}
              >
                <span>Стартирай сесия</span>
                <Icon>add_circle</Icon>
              </Button>
            }
            <Grid
              container
              direction={{ xs: "column", sm: "row" }}
              spacing={{ xs: 1, sm: 2, md: 4 }}
            >
              {userSessions}
            </Grid>
            {!isActive ?
              <Item>Ще можете да създавате сесии след валидиране на профила ви от училищния администратор</Item>
              : null}
          </TabPanel>
          <TabPanel value={localState.tab} index={2}>
            {
              resultsByClass ?

                resultsByClass.map((s) => {
                  return (
                    <Grid item xs={4} key={s.sessionId}>
                      <Item>
                        {s.year} {s.type}
                      </Item>
                      <Accordion>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography>класове</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          {s.classes?.map((cl) => {
                            return cl ? (
                              <Grid item xs={12} key={cl.id}>
                                <Item>
                                  <>{cl.name}</>
                                </Item>
                                <Accordion>
                                  <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                  >
                                    ученици
                                  </AccordionSummary>
                                  <AccordionDetails>
                                    <Box>
                                      <Grid container spacing={2} alignItems="center" style={{ marginLeft: 0 }}>
                                        <Grid item xs={2}>
                                          {"#"}
                                        </Grid>

                                        <Grid item xs={2}>
                                          {"Име"}
                                        </Grid>

                                        <Grid item xs={2}>
                                          {"Време(сек)"}
                                        </Grid>
                                        {/* <Grid item xs={2}>
                                          {"Точки / Макс"}
                                        </Grid> */}
                                      </Grid>
                                      <Divider></Divider>
                                    </Box>
                                    {cl.answers.map((answer) => {
                                      return (
                                        <Box key={answer.id}>
                                          <Grid key={answer.id} container spacing={2} alignItems="center" style={{ margin: 0 }}>
                                            <Grid item xs={2} style={{ padding: 8 }}>
                                              {answer.student.number}{" "}
                                            </Grid>

                                            <Grid item xs={2} style={{ padding: 8 }}>
                                              {answer.student.name}{" "}
                                            </Grid>

                                            <Grid item xs={2} style={{ padding: 8 }}>
                                              {answer.totalTime}{" s"}
                                            </Grid>
                                            {/* <Grid item xs={2} style={{ padding: 8 }}>
                                              {answer.points}{" / "}{answer.max}
                                            </Grid> */}
                                            {/* <Grid item style={{ padding: 8 }}>
                                              <Button variant="outlined" color="primary" onClick={() => handleDetailsOpen(answer)}>Детайли</Button>
                                            </Grid> */}
                                          </Grid>
                                          <Divider></Divider>
                                        </Box>

                                      );
                                    })}
                                    {currentAnswer ?
                                      <Modal
                                        open={open}
                                        onClose={handleDetailsClose}
                                        aria-labelledby="student-details-modal"
                                        aria-describedby="modal-to-display-student-answer-details"
                                      >
                                        <Box
                                          style={{
                                            margin: 'auto',
                                            width: isMobile ? '80%' : '60%',
                                            backgroundColor: 'white',
                                            padding: '20px',
                                            overflowY: 'auto',
                                            borderRadius: '8px'
                                          }}
                                        >
                                          <Box display="flex" justifyContent="space-between" alignItems="center">
                                            <Box display="flex" alignItems="center">
                                              <Typography variant="h6">Повече информация: {currentAnswer.student.name}</Typography>
                                              <IconButton onClick={generateExcel}>
                                                <PrintIcon />
                                              </IconButton>
                                            </Box>
                                            <IconButton onClick={handleDetailsClose}>
                                              <CloseIcon />
                                            </IconButton>
                                          </Box>

                                          <Box mb={3}>
                                            {Object.entries(currentAnswer.pointsByType).map(([key, value]) => (
                                              <Typography key={key} variant="body1">{SKILLS_TEXTS[key]}: {value.toFixed(2)}%</Typography>
                                            ))}
                                            <Typography variant="body1">{"Време"}: {Math.floor(currentAnswer.totalTime / 60)}мин {currentAnswer.totalTime % 60}сек</Typography>
                                          </Box>

                                          <Typography variant="h6">Отговори на въпроси</Typography>

                                          {currentAnswer && (
                                            <Grid
                                              container
                                              spacing={2}
                                              wrap="wrap"
                                              alignItems="center"
                                              className="scrollable"
                                              style={{
                                                margin: 0,
                                                maxHeight: '400px',
                                                overflowY: 'scroll',
                                                scrollbarWidth: 'thin',
                                                scrollbarColor: '#888 #f5f5f5',

                                              }}
                                            >
                                              <Table style={{ marginTop: 0 }}>
                                                <TableHead>
                                                  <TableRow>
                                                    <TableCell>#</TableCell>
                                                    <TableCell>Отговор</TableCell>
                                                    <TableCell>Точки</TableCell>
                                                    <TableCell>Време</TableCell>
                                                  </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                  {currentAnswer.answersPoints.slice(1).map((point, idx) => (
                                                    <TableRow
                                                      key={idx}
                                                      style={{
                                                        backgroundColor: point === 0 ? '#f77b72' : point === 1 ? "#EDE0A6" : '#80bf84',
                                                        color: 'white'
                                                      }}
                                                    >
                                                      <TableCell>{idx + 1}</TableCell>
                                                      <TableCell>"{currentAnswer.selectedAnswers[idx + 1]}"</TableCell>
                                                      <TableCell>{point}</TableCell>
                                                      <TableCell>{currentAnswer.answersTime[idx + 1]}s</TableCell>
                                                    </TableRow>
                                                  ))}
                                                </TableBody>
                                              </Table>


                                            </Grid>
                                          )}
                                        </Box>
                                      </Modal>
                                      : null
                                    }
                                  </AccordionDetails>
                                </Accordion>
                              </Grid>
                            ) : null;
                          })}
                        </AccordionDetails>
                      </Accordion>
                    </Grid>)
                })
                : null
            }
          </TabPanel>
        </Box>
      </section>
    </Layout >
  );
};


const mapStateToProps = (state) => ({
  answers: getAnswers(state),
  isActive: isActive(state),

});

const mapDispatchToProps = {
  fetchQuizAnswers,
};


export default connect(mapStateToProps, mapDispatchToProps)(TeacherPanel);

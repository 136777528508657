import moment from "moment";
import PropTypes from "prop-types";

import { Typography, Box, Slide, Alert } from "@mui/material";

export function createSessionData(sessionType, user) {
  const date = moment();
  const sessionYear = date.year();
  return {
    teacher: user.id,
    school: user.school.id,
    type: sessionType,
    year: sessionYear,
  };
}

export function TransitionUp(props) {
  return (
    <Slide {...props} direction="up">
      <Alert variant="filled" severity="success" sx={{ width: "100%" }}>
        Успешно добавихте класа си!
      </Alert>
    </Slide>
  );
}

export function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

import React from 'react';
import { Grid, Container, Typography, Link } from '@mui/material';

const Footer = () => {
    return (
        <footer style={{ backgroundColor: '#1c2e5f', color: 'white', padding: '20px 0' }}>
            <Container maxWidth="lg">
                <Grid container spacing={5}>
                    <Grid item xs={12} sm={3}>
                    <Typography variant="h6" color="inherit">
                            Информация
                        </Typography>
                        <Link href="/terms" color="inherit">Общи условия</Link>
                    </Grid>
                    <Grid item xs={0} sm={3}>
                    </Grid>
                    <Grid item xs={0} sm={3}>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <Typography variant="h6" color="inherit">
                            Контакти
                        </Typography>
                        {/* <Typography>+359 882646024</Typography> */}
                        {/* <Typography>Пон - Пет ...: 10:00 - 18:00</Typography> */}
                        <Link href="mailto:sel@tsotsorkovfoundation.bg" color="inherit">sel@tsotsorkovfoundation.bg</Link>
                    </Grid>
                </Grid>
                <Grid container justifyContent="center" style={{ marginTop: '20px' }}>
                    <Typography variant="body2">
                        Фондация "Лъчезар Цоцорков" © 2023
                    </Typography>
                </Grid>
            </Container>
        </footer>
    );
};

export default Footer;

import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import { getUser, isAuthenticated, isAdmin, isAdministrator, isTeacher } from 'reducers';
import { logout } from 'actions/authActions';

import AppBar from '@mui/material/AppBar';
import {
    Box,
    Toolbar,
    IconButton,
    Typography,
    Menu,
    MenuItem,
    Button,
    Container,
    Drawer,
    List,
    ListItem,
    ListItemText,
    Collapse,
} from '@mui/material';

import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import './Navigation.scss';

const pages = [
    {
        label: 'За СЕО', submenu: [
            { label: 'Обща информация', path: '/info' },
            { label: 'ОИСР', path: '/oisr' },
            { label: 'Доклади', path: '/reports' }
        ]
    },
    {
        label: 'За училища', submenu: [
            { label: 'За програмата', path: '/program' },
            { label: 'Помагала', path: '/materials' },
            { label: 'Оценка', path: '/evaluation' }
        ]
    },
    { label: 'За нас', path: '/about' }
];

const Navigation = ({
    isAuthenticated,
    isAdmin,
    isAdministrator,
    isTeacher,
    user,
    logout,
}) => {
    const [anchorElSub, setAnchorElSub] = useState({});
    const [submenuIndex, setSubmenuIndex] = useState(null);
    const [mobileOpen, setMobileOpen] = useState(false);
    const [mobileSubmenuOpen, setMobileSubmenuOpen] = useState({});

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const handleSubMenuClick = (event, index) => {
        if (submenuIndex === index) {
            setAnchorElSub({});
            setSubmenuIndex(null);
        } else {
            setAnchorElSub({ ...anchorElSub, [index]: event.currentTarget });
            setSubmenuIndex(index);
        }
    };

    const handleMobileSubmenuClick = (index) => {
        setMobileSubmenuOpen((prevState) => ({
            ...prevState,
            [index]: !prevState[index],
        }));
    };

    const handleCloseSubMenu = () => {
        setAnchorElSub({});
        setSubmenuIndex(null);
    };

    const drawer = (
        <Box sx={{ textAlign: 'center' }}>
            <IconButton sx={{ float: 'right' }} onClick={handleDrawerToggle}>
                <CloseIcon />
            </IconButton>
            <Typography variant="h6" sx={{ my: 2 }}>
                SEL
            </Typography>
            <List>
                {pages.map((page, index) => (
                    <div key={index}>
                        <ListItem button onClick={() => page.submenu ? handleMobileSubmenuClick(index) : handleDrawerToggle()} component={page.submenu ? 'div' : Link} to={!page.submenu ? page.path : undefined}>
                            <ListItemText primary={page.label} />
                        </ListItem>
                        {page.submenu && (
                            <Collapse in={mobileSubmenuOpen[index]} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding>
                                    {page.submenu.map((subpage) => (
                                        <ListItem button key={subpage.label} component={Link} to={subpage.path} onClick={handleDrawerToggle} sx={{ pl: 4 }}>
                                            <ListItemText primary={subpage.label} />
                                        </ListItem>
                                    ))}
                                </List>
                            </Collapse>
                        )}
                    </div>
                ))}
                {isAdmin && (
                    <ListItem button component={Link} to="/admin-panel" onClick={handleDrawerToggle}>
                        <ListItemText primary="Админ Панел" />
                    </ListItem>
                )}
                {isAdministrator && (
                    <ListItem button component={Link} to="/administration" onClick={handleDrawerToggle}>
                        <ListItemText primary="Администрация" />
                    </ListItem>
                )}
                {isTeacher && (
                    <ListItem button component={Link} to="/panel" onClick={handleDrawerToggle}>
                        <ListItemText primary="Учителски панел" />
                    </ListItem>
                )}
                {isAuthenticated ? (
                    <ListItem button onClick={() => { logout(user.email); handleDrawerToggle(); }}>
                        <ListItemText primary="Изход" />
                    </ListItem>
                ) : (
                    <ListItem button component={Link} to="/login" onClick={handleDrawerToggle}>
                        <ListItemText primary="Вход" />
                    </ListItem>
                )}
            </List>
        </Box>
    );

    return (
        <AppBar position="sticky" sx={{ backgroundColor: '#1c2e5f' }}>
            <Container maxWidth="xl">
                <Toolbar disableGutters>
                    <Typography
                        variant="h6"
                        noWrap
                        component={Link}
                        to="/"
                        sx={{
                            mr: 2,
                            display: { xs: 'none', md: 'flex' },
                            fontFamily: 'monospace',
                            fontWeight: 700,
                            letterSpacing: '.3rem',
                            color: 'inherit',
                            textDecoration: 'none',
                        }}
                    >
                        SEL
                    </Typography>
                    <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            edge="start"
                            onClick={handleDrawerToggle}
                        >
                            <MenuIcon />
                        </IconButton>
                    </Box>
                    <Typography
                        variant="h5"
                        noWrap
                        component="a"
                        href="/"
                        sx={{
                            mr: 2,
                            display: { xs: 'flex', md: 'none' },
                            flexGrow: 1,
                            fontFamily: 'monospace',
                            fontWeight: 700,
                            letterSpacing: '.3rem',
                            color: 'inherit',
                            textDecoration: 'none',
                        }}
                    >
                        SEL
                    </Typography>
                    <Box className="nav-items" sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                        {pages.map((page, index) => (
                            <div
                                key={index}
                                style={{ position: 'relative' }}
                            >
                                <Button
                                    aria-controls={Boolean(submenuIndex === index) ? `basic-menu-${index}` : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={Boolean(submenuIndex === index) ? 'true' : undefined}
                                    onClick={(event) => page.submenu ? handleSubMenuClick(event, index) : undefined}
                                    sx={{ my: 2, color: 'white', display: 'block' }}
                                    component={page.submenu ? 'div' : Link}
                                    to={!page.submenu ? page.path : undefined}
                                >
                                    <Typography textAlign="center">{page.label}</Typography>
                                </Button>
                                {page.submenu && (
                                    <Menu
                                        id={`basic-menu-${index}`}
                                        anchorEl={anchorElSub[index]}
                                        open={submenuIndex === index}
                                        onClose={handleCloseSubMenu}
                                        MenuListProps={{
                                            'aria-labelledby': `basic-button-${index}`,
                                        }}
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'left',
                                        }}
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'left',
                                        }}
                                        sx={{ mt: 1 }}
                                    >
                                        {page.submenu.map((subpage) => (
                                            <MenuItem
                                                key={subpage.label}
                                                component={Link}
                                                to={subpage.path}
                                                onClick={handleCloseSubMenu}
                                            >
                                                <Typography textAlign="center">{subpage.label}</Typography>
                                            </MenuItem>
                                        ))}
                                    </Menu>
                                )}
                            </div>
                        ))}
                        {isAdmin && (
                            <Button component={Link} to="/admin-panel" sx={{ my: 2, color: 'white', display: 'block' }}>
                                <Typography textAlign="center">Админ Панел</Typography>
                            </Button>
                        )}
                        {isAdministrator && (
                            <Button component={Link} to="/administration" sx={{ my: 2, color: 'white', display: 'block' }}>
                                <Typography textAlign="center">Администрация</Typography>
                            </Button>
                        )}
                        {isTeacher && (
                            <Button component={Link} to="/panel" sx={{ my: 2, color: 'white', display: 'block' }}>
                                <Typography textAlign="center">Учителски панел</Typography>
                            </Button>
                        )}
                        {isAuthenticated ? (
                            <Button onClick={() => logout()} sx={{ my: 2, color: 'white', display: 'block' }}>
                                <Typography textAlign="center">Изход</Typography>
                            </Button>
                        ) : (
                            <Button component={Link} to="/login" sx={{ my: 2, color: 'white', display: 'block' }}>
                                <Typography textAlign="center">Вход</Typography>
                            </Button>
                        )}
                    </Box>
                </Toolbar>
            </Container>
            <Drawer
                anchor="left"
                open={mobileOpen}
                onClose={handleDrawerToggle}
                ModalProps={{
                    keepMounted: true,
                }}
            >
                {drawer}
            </Drawer>
        </AppBar>
    );
};

const mapStateToProps = (state) => ({
    isAuthenticated: isAuthenticated(state),
    isAdministrator: isAdministrator(state),
    isAdmin: isAdmin(state),
    isTeacher: isTeacher(state),
    user: getUser(state),
});

const mapDispatchToProps = {
    logout
};

export default connect(mapStateToProps, mapDispatchToProps)(Navigation);

import { useEffect, useReducer, useMemo } from "react";
import { useNavigate } from "react-router-dom";

import ClassCard from "../components/ClassCard";
import SessionCard from "../components/SessionCard";

import { createSessionData } from "../helpers";

import { localReducer, localInitialState } from "../state";
import {
  updateIsCreateClassModalVisible,
  updateIsEditClassModalVisible,
  updateSelectedClass,
  updateIsCreateSessionModalVisible,
  updateIsSnackbarOpen,
  updateTab,
} from "../state/action-creators";

export const useTeacherPanel = (props) => {
  const {
    user,
    isAuthenticated,
    addClass,
    editClass,
    createSession,
    deleteClass,
    deleteSession,
    selectedClass,
  } = props;

  const navigate = useNavigate();

  const [localState, localDispatch] = useReducer(
    localReducer,
    localInitialState
  );

  const handleTabChange = (_, newTab) => localDispatch(updateTab(newTab));
  const handleClose = () => localDispatch(updateIsSnackbarOpen(false));

  const showCreateClassModalHandler = () =>
    localDispatch(updateIsCreateClassModalVisible(true));

  const showEditClassModalHandler = (classData) => {
    localDispatch(updateSelectedClass(classData));
    localDispatch(updateIsEditClassModalVisible(true));
  }

  const closeCreateClassModalHandler = () =>
    localDispatch(updateIsCreateClassModalVisible(false));

  const closeEditClassModalHandler = () =>
    localDispatch(updateIsEditClassModalVisible(false));

  const showCreateSessionModalHandler = () =>
    localDispatch(updateIsCreateSessionModalVisible(true));

  const closeCreateSessionModalHandler = () =>
    localDispatch(updateIsCreateSessionModalVisible(false));

  const onClassCreateFinishHandler = (classData) => {
    addClass(user.id, classData)
      .then(() => localDispatch(updateIsSnackbarOpen(true)))
      .then(user.classes.push(classData))
      .catch((e) => console.log(e));
  };

  const onClassEditFinishHandler = (classData) => {
    editClass(user.id, classData)
      .then(() => localDispatch(updateIsSnackbarOpen(true)))
      .catch((e) => console.log(e));
  }

  const onSessionCreateFinishHandler = (sessionType) => {
    const data = createSessionData(sessionType, user);

    createSession(data)
      .then(() => localDispatch(updateIsSnackbarOpen(true)))
      .catch((e) => console.log(e));
  };

  const userClasses = useMemo(
    () =>
      user.classes.map((cl, idx) => {
        return (
          <ClassCard
            key={cl.id}
            userId={user.id}
            deleteClass={deleteClass}
            classData={cl}
            quizLink={false}
            showEditClassModal={showEditClassModalHandler}
          />
        );
      }),
    [user.id, user.classes, deleteClass]
  );

  const userSessions = useMemo(
    () =>
      user.sessions?.map((s, idx) => {
        return (
          <SessionCard key={s.id} user={user} deleteSession={deleteSession} session={s} />
        );
      }),
    [user, deleteSession]
  );

  useEffect(() => {
    if (!isAuthenticated) {
      return navigate("/login");
    }
  }, [user, navigate, isAuthenticated]);

  return {
    localState,
    handleTabChange,
    handleClose,
    showCreateClassModalHandler,
    closeCreateClassModalHandler,
    showEditClassModalHandler,
    closeEditClassModalHandler,
    showCreateSessionModalHandler,
    closeCreateSessionModalHandler,
    onClassCreateFinishHandler,
    onClassEditFinishHandler,
    onSessionCreateFinishHandler,
    userClasses,
    userSessions,
    selectedClass,
  };
};

export default {
  useTeacherPanel,
};

import React from 'react';
import { Grid, Card, CardContent, CardMedia, Typography, Avatar } from '@mui/material';
import PanelistCardEN from './PanelistCardEN';
import StefaniJonesIMG from "./assets/stefani_jones.jpg"
import AliyahIMG from "./assets/aaliyah_samuel.jpg"
import PetteriImg from "./assets/petteri.jpg"
import AndreasImg from "./assets/andreas_schleicher.jpg"
import ShaleinaImg from "./assets/shailendra_sharma.jpg"
import RaquelImg from "./assets/raquel_teixeira.jpg"
import MilaIMG from "./assets/mila_ivanova.jpg"
import DimitarIMG from "./assets/dimitar_tsotsorkov.jpg"
import DobromirIMG from "./assets/dobromir_ivanov.jpg"
import RobertIMG from "./assets/robert_doble.png"
import NoemiIMG from "./assets/noemi_le_don.png"
import LinaIMG from "./assets/lina_benete.jpg"
import OlenaIMG from "./assets/olena.jpg"
import JoanaIMG from "./assets/joana.jpg"
import ElkinIMG from "./assets/elkin.jpg"

const panelistsData = [
  {

    name: "Stephanie Jones",
    role: "Director of the EASEL Lab at the Harvard Graduate School of Education",
    bio: `Stephanie M. Jones is the Gerald S. Lesser Professor in Child Development and Education and Director of the EASEL Lab at the Harvard Graduate School of Education. Her research, anchored in prevention science, focuses on social, emotional, and behavioral development from early childhood through early adolescence. Her work centers on evaluation research addressing the impact of preschool- and elementary-level social-emotional learning interventions on behavioral and academic outcomes and classroom practices, as well as new curriculum development, implementation, and testing. Jones is also co-Director (with Nonie Lesaux) of the Saul Zaentz Early Education Initiative and Co-PI of the Early Learning Study at Harvard (ELS@H). She serves on numerous national advisory boards and expert consultant groups related to social-emotional development, early childhood education, and child and family anti-poverty policies. Jones holds a BA from Barnard College and a PhD from Yale University.`,
    image: StefaniJonesIMG,
    websites: ["https://easel.gse.harvard.edu/", "https://zaentz.gse.harvard.edu/"]
  },
  {
    name: "Dr. Aaliyah A. Samuel",
    role: "President and CEO of CASEL",
    bio: "Dr. Aaliyah A. Samuel is the President and CEO of CASEL, the nonpartisan, nonprofit organization leading the global movement for evidence-based social and emotional learning (SEL). She also serves as Senior Fellow, Harvard University, Center on the Developing Child. In these roles, she serves as a leading voice in education and oversees strategies to elevate research, policies, and practices that support children’s learning and healthy development.     Dr. Samuel is a bilingual executive leader with expertise from early childhood through higher education. She previously served as Deputy Assistant Secretary, Local, State and National Engagement at the U.S. Department of Education and former Executive Vice President of Government Affairs & Partnerships at NWEA.  She also served as the Director of Education at the National Governors Association (NGA).    She holds an undergraduate degree from Tuskegee University, a Masters from University of South Florida, and a Specialist and Doctorate Degree from NOVA Southeastern.    ",
    image: AliyahIMG
  },
  {
    name: "Petteri Lammi",
    role: "Evaluation Advisor, Education Division, City of Helsinki",
    bio: "Petteri Lammi is an established evaluation and applied research professional, advising the senior leadership of Helsinki’s Evaluation Division on themes related to knowledge and quality management, effectiveness and impact and understanding student experience. Petteri led the National Project Manager team for Helsinki in the SES-survey in 2023. Before joining the City of Helsinki, Petteri was a partner at Saana Consulting leading teams advising public institutions, including the World Bank, World Trade Organization and several UN agencies. Petteri holds an MBA from the University of Strathclyde and a BSc from the University of Helsinki.",
    image: PetteriImg,
  },
  {
    name: "Andreas Schleicher",
    role: "Director for Education and Skills, and Special Advisor on Education Policy to the Secretary-General at OECD in Paris",
    bio: "He initiated and oversees the Programme for International Student Assessment (PISA) and other international instruments that have created a global platform for policy-makers, researchers and educators across nations and cultures to innovate and transform educational policies and practices.    He has worked for over 20 years with ministers and education leaders around the world to improve quality and equity in education. Former U.S. Secretary of Education Arne Duncan said that Schleicher “understands the global issues and challenges as well as or better than anyone I’ve met, and he tells me the truth” (The Atlantic, July 11). Former UK Secretary of State Michael Gove called Schleicher “the most important man in English education” – even though he is German and lives in France.    Before joining the OECD, he was Director for Analysis at the International Association for Educational Achievement (IEA). He studied Physics in Germany and received a degree in Mathematics and Statistics in Australia. He is the recipient of numerous honours and awards, including the “Theodor Heuss” prize, awarded in the name of the first president of the Federal Republic of Germany for “exemplary democratic engagement”.  He holds an honorary Professorship at the University of Heidelberg.    ",
    image: AndreasImg
  },
  {
    name: "Shailendra Sharma",
    role: "Former Education Advisor to the Government of Delhi, India",
    bio: "Shailendra Sharma is an Education Advisor to the Government of Delhi, India. With a career spanning over 22 years, he transitioned from being an education practitioner with a non-profit organisation leading large-scale programs improving access and quality of education to being an education advisor to the Government.    His work is intricately linked with shaping and steering government school system of Delhi, impacting more than 1.8 million children. Shailendra has been instrumental in facilitating the curriculum reforms to strengthen foundational learning skills and build value system through mindset curricula including Happiness, Entrepreneurship, and Deshbhakti (citizenship education) curricula.     He serves as a Salzburg Global Fellow and co-author of “Salzburg Statement on Social Emotional Learning”. He has collaborated with 27 policymakers from 21 countries at the Salzburg Global Seminar to work, learn, and innovate together in the field of education.    ",
    image: ShaleinaImg
  },
  {
    name: "Ms. Raquel Teixeira",
    role: "Secretary of Education for Rio Grande do Sul, Brazil",
    bio: "Prof. Raquel Figueiredo Alessandri Teixeira, PhD, esteemed Secretary of Education for Rio Grande do Sul since April 2021, brings a rich tapestry of experience to her role. Educated globally, she holds advanced degrees in Linguistics and Literature from prestigious institutions including the University of California, Berkeley, and the University of Brasília, with further specialization in Ethnolinguistics and a post-doctorate in Language and Culture from Paris. A respected member of the Advisory Board for eduLab21 - Ayrton Senna Institute since 2014, Prof. Teixeira has also served in multiple secretary roles across Goiás, contributing significantly to Brazil's educational landscape. Her accolades include awards from the French Ministry of Education, highlighting her impactful contributions to education, literature, and linguistics. Prof. Teixeira's leadership extends beyond academia into pivotal advisory and legislative roles, marking her as a luminary in educational policy and development.",
    image: RaquelImg
  },
  {
    name: "Mila Ivanova",
    role: "Senior Specialist in “Content Management and Development”, “Teach for Bulgaria” foundation",
    bio: "Mila Ivanova started as a Specialist in Development and Impact Assessment at Teach for Bulgaria in 2017 and is currently a senior specialist in “Content Management and Development” of prepodavame.bg website. She is responsible for the content, planning and preparation of the resources, authors and special education campaigns.    In 2012 she completed her studies in History of Art/Business in Scotland and in 2013 joined the third batch of teachers in Teach for Bulgaria’s program. She taught English, Entrepreneurship and Marketing in the Vocational High School of Tourism, Samokov, for two years. After two years in the program, she continued to be involved in personal educational projects and taught for one year at a school in Sofia.    She has experience as a trainer and a member of the recruitment team selecting new participants for the organization. In her current position she is responsible for gathering experience and valuable insight from classrooms around the world and in Bulgaria. Only to disseminate these good practices afterwards.",
    image: MilaIMG
  },
  {
    name: "Dimitar Tsotsorkov",
    role: "Founder and chairman of the “Lachezar Tsotsorkov” Foundation",
    bio: "Dimitar Tsotsorkov serves as Chairman of the Management Board of Assarel-Medet JSC, Bulgaria, the biggest Bulgarian company specialized in copper mining and processing of copper ores, and well as CEO at Assarel Investment JSC, Assarel Group’s investment company for projects outside the mining industry.     Dimitar has a joint Bachelor of Science and Bachelor of Arts degree from Richmond University, London and a Master’s degree from Westminster University, London. He has also completed the entry course of the EMBA degree in IMD, Lausanne and numerous professional and academic specializations. He is fluent in English, Russian and German.    Dimitar has wide-ranging experience in mining, diversified investments and international trade. He is in charge of Assarel-Medet JSC new mining projects in Bulgaria, as the most advanced of which engages more than 60 million EUR of investments and shall employ more than 450 people directly,  as well as more than 2000 will benefit indirectly. He is also in charge of the numerous non-core investments of Assarel-Medet JSC, Bulgaria, including a portfolio of large-scale tourism and health assets, logistics projects, and Assarel’s Annual Innovation Project contest.    Dimitar believes in the principles of philanthropy and is actively dedicated to its revival in Bulgaria. He is the founder and chairman of the “Lachezar Tsotsorkov” Foundation.",
    image: DimitarIMG
  },
  {
    name: "Dobromir Ivanov",
    role: "Chief Executive Officer of BESCO - The Bulgarian Entrepreneurial Association",
    bio: "Dobromir Ivanov is the Chief Executive Officer of BESCO - The Bulgarian Entrepreneurial Association - comprising over 500 Bulgarian companies. Over the past six years, BESCO has spearheaded a series of policy advocacy initiatives aimed at improving the country's economy, with the goal of making Bulgaria the top destination for entrepreneurs in Europe. Some of BESCO's main achievements are the acceptance of the Startup Visa and the Variable Capital Company as a legal entity. Dobromir holds specializations from two American universities and has previously served as the Chairman in the Youth Red Cross. His entrepreneurial experience includes founding the largest online bus ticket sales platform in Bulgaria, and in 2017, he served as an advisor to the Minister of Economy during the caretaker government. This year he is one of the co-founders of the first Bulgarian Advocacy Academy, powered by the American University of Bulgaria, in partnership with BESCO.     ",
    image: DobromirIMG,
    websites: ["https://www.linkedin.com/in/dobromir1/"]
  },
  {
    name: "Robert Doble",
    role: "Director of Building Future Generations Sector, Porticus",
    bio: "Robert Doble joined Porticus in February 2022 as Sector Director Building Future Generations. Robert is a well-established leader in the education space with a wealth of experience both on a strategic and policy level. Robert joined Porticus from global children’s charity Theirworld where he lead their policy, advocacy and programmes as a member of the Senior Management Team. Prior, Robert was Advocacy Director at the Lego Foundation where he led the development and implementation of the foundation’s advocacy framework. Robert has also held a number of positions at Save the Children, most recently as Global Education Director where he oversaw their global education work. He holds a PhD in Global Health Policy from the London School of Hygiene Tropical Medicine and has co-authored and authored a number of publications. He also holds an MSc in Conflict, Security and Development from the University of Birmingham and a BSc Econ in International Politics and Third World from the University of Aberystwyth. ",
    image: RobertIMG
  },
  {
    name: "Noémie Le Donné ",
    role: "OECD, Senior Analyst, Project manager      ",
    bio: "Dr Noémie Le Donné leads OECD’s work on social and emotional skills at the Centre for Educational Research and Innovation (CERI). Born in France, she graduated from Paris Graduate School of Economics, Statistics and Finance and holds a doctorate degree in sociology from Sciences Po Paris.     Noémie Le Donné joined the OECD in 2014 and has significantly contributed to international large-scale assessments, including the Programme for International Student Assessment (PISA) and the Teaching and Learning International Survey (TALIS). As an accomplished author, Noémie Le Donné has published numerous research papers and policy briefs on teacher policies, student academic achievement and social and emotional skills.  ",
    image: NoemiIMG
  },
  {
    name: "Lina Benete ",
    role: "Think Equal Chief Executive Officer",
    bio: "Lina has 20 years of experience in education strategy development, programme design and management, monitoring and evaluation, and knowledge management in UNESCO, the World Bank and the Global Partnership for Education. She has led teams to support education planning, financing and implementation in diverse country contexts in Africa, Asia, Europe, Latin America, and the Middle East. Lina has managed country programmes with projects on strengthening transversal competencies, teacher training and teaching and learning materials. She believes that the best way to strengthen education quality is to focus on human values and develop socio-emotional competencies from an early age to ensure sustainable development.",
    image: LinaIMG
  },
  {
    name: "Oleksandr Elkin",
    role: "Inspirer, Chair of the “Ed Camp” Ukraine Board",
    bio: `Oleksandr Elkin is the Head of Board and inspirer of EdCamp Ukraine, a strong 45,000 teachers’ professional development community. Prior to this Oleksandr managed Ukrainian and international EdTech projects, as well as held university academic and administrative positions training future computer scientists.
    Between 2016 and 2020, he was serving as an adviser to three Ministers of Education and Science of Ukraine and a member of the two cohorts of Public Council of the Ministry. In 2021, Dr. Elkin became a member of Presidential Advisory Council on Promoting the Development of the General Secondary Education System.
    As a co-author of the New Ukrainian School concept and state standards, Oleksandr is currently participating in initiatives aimed at reforming secondary education. He was instrumental in the demonopolization reform of teachers’ professional development and the launch of the EdWay innovative platform designed to support the reform. Dr. Elkin has been advocating social and emotional learning with Emory University's SEE Learning program in Ukraine. He is the National project manager in Ukraine for OECD international Survey on Social and Emotional Skills. Co-founder of the educational platform POVIR for overcoming educational gaps and losses.
    Awarded managerial honors. In 2021, Oleksandr was ranked among the 100 most influential Ukrainians according to FOCUS magazine and named Ukrainian Prometheus in education by NV. In 2022, he and the team received the award for the best practice in school-based family counseling from Oxford Symposium; the following year – the NGO was honored with the Ambassador of Resiliency distinction by Trauma Resource Institute (USA).
    Additionally, Dr. Elkin is a founding curator of Kharkiv Hub of Global Shapers community, the World Economic Forum; a delegate at the 2014 Forum in Davos. He is the Global Dignity Country Chair for Ukraine. Oleksandr is also a board member of V. N. Karazin Kharkiv National University and Jewish cultural center Beith Dan. He has a solid academic foundation, with a Ph.D. in Technical Sciences, M.A. in Computer Sciences and M.A. in Economics & Entrepreneurship and has had 15+ years of management experience in education, IT, and non-profits.
    `,
    image: ElkinIMG,
    websites: ["https://mon.gov.ua/eng", "https://povir.in.ua/", "https://www.edcamp.ua/en/main-page/"]
  },
  {
    name: "Joanna Marinova",
    role: "Executive Director of the “Lachezar Tsotsorkov” Foundation",
    bio: `Joanna Marinova attended the University of Toronto on a full scholarship for academic excellence and community leadership. She graduated with high honors in International Relations and Economics. After graduating, she worked for Wellington Management, a leading private investment management firm. An advocate at heart, Joanna left the world of finance to pursue her deep commitment to building healthy and vibrant communities through education.
    A life-long learner, she has completed a certificate program in management from Harvard Business School and holds an MBA from the American University in Bulgaria, which she attended on a scholarship for NGO specialists. Joanna’s work is the recipient of numerous awards including the Universal Forum of Cultures in Spain by the United Nations, Harvard’s Harbus Award for Excellence in Journalism and Education, and a citation for the state of Massachusetts for achievements in youth work.
    During 2020, she designed a project on Roma financial inclusion and led the first Eastern European team to compete and win the international Rockefeller Acumen Social Enterprise Accelerator.After over 25 years of living abroad, Joanna returned to Bulgaria in late 2016 in order to contribute her experience to the development of the NGO sector in her native country. With 14 years of experience and a proven track record in organizational leadership, Joanna has taken on the role of Executive Director at the “Lachezar Tsotsorkov” Foundation.
    Joanna is a member of the Management Board of the Bulgarian Donations Forum and the Media Literacy Coalition. She is a member of the “Council of Women in Business in Bulgaria” and part of the ChangeMakers collective.
    `,
    image: JoanaIMG
  },
  {
    name: "Olena Zelenska",
    role: "First Lady of Ukraine",
    bio: `Personal life 
    Mrs Olena Zelenska was born on 6 February 1978 in Kryvyi Rih. In 2003, she and Volodymyr Zelenskyy got married. After the marriage, the family moved to Kyiv. Olena and Volodymyr Zelenskyy are raising two children: daughter Oleksandra (born in 2004) and son Kyrylo (born in 2013). The family loves animals, they have two dogs, a cat, a parrot and a guinea pig. The First Lady of Ukraine actively goes in for sports, likes to read and is a fan of cinematography
    
    Activities of the First Lady of Ukraine 
    
     Health of future generations 
    (1) The reform of the school nutrition system of Ukraine. The reform strategy encompasses four strategic goals: 
    1. Strengthening the financial and institutional capacity of communities; 
    2. Modernizing the infrastructure of educational institutions; 
    3. Ensuring the development of human resources; 
    4. Cultivating a healthy food culture. 
    
    (2) All-Ukrainian mental health program "How are you?". The program is designed to help Ukrainians overcome the consequences of the traumatic events of the war. The development and implementation of the program is coordinated by the Ministry of Health in close cooperation with Ukrainian and international specialists. The initiative is implemented with the support of the World Health Organization
    
     Cultural diplomacy 
    Projects to popularize the Ukrainian language – audio guides. As part of the initiative of the First Lady of Ukraine, more than 79 audio guides in the Ukrainian language have already been launched in the best cultural institutions in more than 44 countries. The project started in 2020. 
    
    Bookshelves ("Ukrainian bookshelf"). It provides for the distribution of Ukrainian literature and its translations in the world's leading libraries. More than 46 countries have already joined the initiative. 190 bookshelves have 47,000 publications. To reinforce the connection with the Homeland for forcibly displaced people, the First Lady initiated the project "Books without borders". 280,000 books in Ukrainian were printed for children who left their homes due to Russian aggression and found shelter in 20 countries. 
    
    Better Time Stories - the project was initiated by the foundation of the Dutch princess Laurentien. First Lady of Ukraine Olena Zelenska, First Lady of Germany Elke Büdenbender and First Lady of Austria Doris Schmidauer became patrons of the project. The initiative is implemented in four countries: Germany, Austria, the Netherlands and Belgium.
    
    Patronage of cultural and artistic events. 
    • The country of equal opportunities (accessibility, Biarritz partnership, combating genderbased and domestic violence, development of family forms of upbringing) 
    • Public diplomacy (the Summit of First Ladies and Gentlemen, representational function abroad and in Ukraine) 
    • Charitable activities (projects of the Olena Zelenska Foundation
    
    Olena Zelenska Foundation 
    On September 22, Olena Zelenska presented her Foundation during a charity evening in New York during the 77th UN General Assembly. The Foundation's primary goal is to restore Ukraine's human capital so that every Ukrainian feels physically and mentally healthy, protected, and able to exercise their right to education, work, and build a future in Ukraine. The Foundation has three key directions: healthcare, education and humanitarian aid. Within these areas, it will provide targeted assistance, invest in reconstructing preschool and school education institutions, polyclinics and outpatient clinics, and provide grants for training and scientific developments. The Foundation sees foreign business and other international foundations as its key partners and donors.
    
    Summit of First Ladies and Gentlemen
    
    On August 23, 2021, the First Kyiv Summit of First Ladies and Gentlemen took place in Kyiv at the initiative of Olena Zelenska. Eleven first ladies from different countries joined the event. 3 The First "Soft Power in the New Reality" Summit was held at St. Sophia Cathedral, one of the most important Christian holy places in Eastern Europe. The cross - cutting themes of the event were the most pressing humanitarian issues of our time: equal access to quality healthcare, the challenges of post-pandemic reality, and equal opportunities for development and self-expression for all people. At the end of the event, the participants approved a joint declaration. 
    
    The Second First Ladies and Gentlemen's Summit 2022, entitled "Ukraine and the World: The Future We (Re)Build Together", was held in Kyiv last year, on the fifth month of Russia's fullscale invasion of Ukraine. The event aimed to draw the world's attention to Russia's aggression in Ukraine and focus on the humanitarian challenges facing Ukraine and the world as a result of the war. The Summit was held in the format of a teleconference between five countries, with the main studio in Kyiv on the territory of the National Conservation Area St. Sophia of Kyiv and four others in Warsaw, Brussels, Washington, and London. The event was broadcast in more than 20 countries. In 2022, the number of participants and international speakers doubled. In-person and online, 23 wives of different countries leaders. Within the Summit, fundraising for C-type ambulance vehicles was announced. USD 6.4 million was raised in total, allowing to purchase 92 ambulance vehicles for the Ministry of Health of Ukraine. These ambulance vehicles are equipped with everything necessary to quickly and safely transport the seriously injured to the hospitals. 
    
    The Third First Ladies and Gentlemen Summit 2023 took place on September 6, 2023, in Kyiv. The theme- "Mental Health: Fragility and Resilience of the Future". It was held at St. Sophia of Kyiv National Reserve, was attended by 29 participants in person or via video conference. The first panel featured a presentation of the results of an international sociological survey on mental health and attitude to it around the world. According to its results, mental health is among the five biggest global challenges of the future. As part of the 3rd Summit of First Ladies and Gentlemen, 25 memoranda of medical partnership were signed. At a special additional location of the Summit, Mental Stage, the influence of culture on the mental state was discussed. 
    
    The purpose of the summits is to unite the first ladies and gentlemen for the purpose of creating an international platform to share experience and implement joint projects for the well-being of people in the world; to discuss the current problems and activities of the first ladies and gentlemen to solve them; to make the voice of every first lady and gentleman more influential
    `,
    image: OlenaIMG,
    large: true,
    sections: [
      {
        title: "Personal life ",
        content: " Mrs Olena Zelenska was born on 6 February 1978 in Kryvyi Rih. In 2003, she and Volodymyr Zelenskyy got married. After the marriage, the family moved to Kyiv. Olena and Volodymyr Zelenskyy are raising two children: daughter Oleksandra (born in 2004) and son Kyrylo (born in 2013). The family loves animals, they have two dogs, a cat, a parrot and a guinea pig. The First Lady of Ukraine actively goes in for sports, likes to read and is a fan of cinematography.        ",
        list: []
      },
      {
        title: "Activities of the First Lady of Ukraine",
        content: "",
        list: []
      },
      {
        title: " Health of future generations ",
        content: `(1) The reform of the school nutrition system of Ukraine. The reform strategy encompasses four strategic goals: 
        `,
        list: ["1. Strengthening the financial and institutional capacity of communities; ",
          "2. Modernizing the infrastructure of educational institutions;           ",
          "3. Ensuring the development of human resources;           ",
          "4. Cultivating a healthy food culture.           "]
      },
      {
        title: `(2) All-Ukrainian mental health program "How are you?".`,
        content: `The program is designed to help Ukrainians overcome the consequences of the traumatic events of the war. The development and implementation of the program is coordinated by the Ministry of Health in close cooperation with Ukrainian and international specialists. The initiative is implemented with the support of the World Health Organization
        `

      },
      {
        title: ` Cultural diplomacy         `,
        content: `Projects to popularize the Ukrainian language – audio guides. As part of the initiative of the First Lady of Ukraine, more than 79 audio guides in the Ukrainian language have already been launched in the best cultural institutions in more than 44 countries. The project started in 2020. 

        Bookshelves ("Ukrainian bookshelf"). It provides for the distribution of Ukrainian literature and its translations in the world's leading libraries. More than 46 countries have already joined the initiative. 190 bookshelves have 47,000 publications. To reinforce the connection with the Homeland for forcibly displaced people, the First Lady initiated the project "Books without borders". 280,000 books in Ukrainian were printed for children who left their homes due to Russian aggression and found shelter in 20 countries. 
        
        Better Time Stories - the project was initiated by the foundation of the Dutch princess Laurentien. First Lady of Ukraine Olena Zelenska, First Lady of Germany Elke Büdenbender and First Lady of Austria Doris Schmidauer became patrons of the project. The initiative is implemented in four countries: Germany, Austria, the Netherlands and Belgium.
        
        `

      },
      {
        title: `Patronage of cultural and artistic events. 
        `,
        content: ``,
        list: ["The country of equal opportunities (accessibility, Biarritz partnership, combating genderbased and domestic violence, development of family forms of upbringing)         ", "Public diplomacy (the Summit of First Ladies and Gentlemen, representational function abroad and in Ukraine)         ", "Charitable activities (projects of the Olena Zelenska Foundation)"]

      },
      {
        title: `Olena Zelenska Foundation         `,
        content: `On September 22, Olena Zelenska presented her Foundation during a charity evening in New York during the 77th UN General Assembly. The Foundation's primary goal is to restore Ukraine's human capital so that every Ukrainian feels physically and mentally healthy, protected, and able to exercise their right to education, work, and build a future in Ukraine. The Foundation has three key directions: healthcare, education and humanitarian aid. Within these areas, it will provide targeted assistance, invest in reconstructing preschool and school education institutions, polyclinics and outpatient clinics, and provide grants for training and scientific developments. The Foundation sees foreign business and other international foundations as its key partners and donors.
        `
      },
      {
        title: `Summit of First Ladies and Gentlemen`,
        content: `On August 23, 2021, the First Kyiv Summit of First Ladies and Gentlemen took place in Kyiv at the initiative of Olena Zelenska. Eleven first ladies from different countries joined the event. 3 The First "Soft Power in the New Reality" Summit was held at St. Sophia Cathedral, one of the most important Christian holy places in Eastern Europe. The cross - cutting themes of the event were the most pressing humanitarian issues of our time: equal access to quality healthcare, the challenges of post-pandemic reality, and equal opportunities for development and self-expression for all people. At the end of the event, the participants approved a joint declaration. 

        `
      },
      {
        title: `The Second First Ladies and Gentlemen's Summit 2022,`,
        content: `entitled "Ukraine and the World: The Future We (Re)Build Together", was held in Kyiv last year, on the fifth month of Russia's fullscale invasion of Ukraine. The event aimed to draw the world's attention to Russia's aggression in Ukraine and focus on the humanitarian challenges facing Ukraine and the world as a result of the war. The Summit was held in the format of a teleconference between five countries, with the main studio in Kyiv on the territory of the National Conservation Area St. Sophia of Kyiv and four others in Warsaw, Brussels, Washington, and London. The event was broadcast in more than 20 countries. In 2022, the number of participants and international speakers doubled. In-person and online, 23 wives of different countries leaders. Within the Summit, fundraising for C-type ambulance vehicles was announced. USD 6.4 million was raised in total, allowing to purchase 92 ambulance vehicles for the Ministry of Health of Ukraine. These ambulance vehicles are equipped with everything necessary to quickly and safely transport the seriously injured to the hospitals. 


        `
      },
      {
        title: `The Third First Ladies and Gentlemen Summit 2023`,
        content: `took place on September 6, 2023, in Kyiv. The theme- "Mental Health: Fragility and Resilience of the Future". It was held at St. Sophia of Kyiv National Reserve, was attended by 29 participants in person or via video conference. The first panel featured a presentation of the results of an international sociological survey on mental health and attitude to it around the world. According to its results, mental health is among the five biggest global challenges of the future. As part of the 3rd Summit of First Ladies and Gentlemen, 25 memoranda of medical partnership were signed. At a special additional location of the Summit, Mental Stage, the influence of culture on the mental state was discussed. 

        The purpose of the summits is to unite the first ladies and gentlemen for the purpose of creating an international platform to share experience and implement joint projects for the well-being of people in the world; to discuss the current problems and activities of the first ladies and gentlemen to solve them; to make the voice of every first lady and gentleman more influential
               
        `
      },

    ],
  }
];

const PanelistsEN = () => {
  return (
    <>
      <Typography variant="h4" component="h2" gutterBottom align="center">
        Panelists Bios
      </Typography>
      <Grid container spacing={2} alignItems="stretch">
        {panelistsData.map((panelist, index) => (
          <Grid item xs={12} sm={6} md={panelist.large ? 6 : 4} key={index}>
            <PanelistCardEN panelist={panelist} />
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default PanelistsEN;

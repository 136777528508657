import React from 'react';
import { connect } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import ForgotPasswordView from './ForgotPasswordView';

import { forgottenPassword } from 'actions/authActions';
import Layout from 'components/Layout';

import 'styles/Auth.scss';


const ForgotPassword = ({
    forgottenPassword,
}) => {
    const navigate = useNavigate();
    const onFormFinish = (email) => {
        forgottenPassword(email)
            .then((res) => {
                navigate('/');
            })
            .catch(err => {
                // navigate('/login')
                // setLoginButtonDisabled(false);
            });
    };

    return (
        <Layout title='Забравена парола'>
            <section className="auth-form-section">
                <div className="auth-form-container container">
                    <nav className="nav-container">
                        <Link to="/login" className="active" title="login"> Вход</Link>
                        <Link to={{
                            pathname: '/register',
                        }}
                            title="register"
                        >
                            Регистрация
                        </Link>
                    </nav>
                    <ForgotPasswordView
                        onFormFinish={onFormFinish}
                    />
                </div>
            </section>
        </Layout>
    )
};

const mapDispatchToProps = {
    forgottenPassword
}

export default connect(null, mapDispatchToProps)(ForgotPassword);
import React, { useState } from "react";
import { SCHOOLS_BG } from 'constants.js';
import { Link } from 'react-router-dom';
import {
    Paper,
    Button,
    Grid,
    TextField,
    Select,
    FormControl,
    InputLabel,
    Grow,
    Checkbox,
    FormControlLabel,
    FormHelperText
} from '@mui/material';

const RegisterFormView = ({
    onFinish,
    onKeyDownHandler,
    isCapslockOn,
}) => {

    const [email, setEmailValue] = useState("");
    const [selectedRegion, setSelectedRegion] = useState(undefined);
    const [selectedTown, setSelectedTown] = useState(undefined);
    const [selectedSchool, setSelectedSchool] = useState(undefined);
    const [password, setPasswordValue] = useState("");
    const [firstName, setNameValue] = useState("");
    const [lastName, setLastNameValue] = useState("");
    const [acceptTerms, setAcceptTerms] = useState(false);
    const [acceptError, setAcceptError] = useState(false); 

    const onEmailChange = (event) => setEmailValue(event.target.value);
    const onPasswordChange = (event) => setPasswordValue(event.target.value);
    const onNameChange = (event) => setNameValue(event.target.value);
    const onLastNameChange = (event) => setLastNameValue(event.target.value);

    const onSubmit = (event) => {
        event.preventDefault();
        if (!acceptTerms) {
            setAcceptError(true);
            return;
        }
        let schoolData = { region: selectedRegion.region, town: selectedTown.town, name: selectedSchool.name, pass: "123" }
        onFinish({ userData: { email, password, firstName, lastName }, schoolData });
    }

    const setRegion = (value) => {
        setSelectedRegion(SCHOOLS_BG.filter(s => s.region === value)[0]);
        setSelectedTown(undefined);
        setSelectedSchool(undefined);

    }

    const setTown = (value) => {
        setSelectedTown(selectedRegion.towns.find(t => t.town === value))
        setSelectedSchool(undefined);
    }


    const setSchool = (value) => {
        setSelectedSchool(selectedTown.schools.find(s => s.name === value));
    }

    const schools = SCHOOLS_BG.filter(x => {
        return x.towns.filter(x => {
            return x.schools.filter(x => x.type = "училище, средно")
        });
    });

    return (
        <Paper className="auth-form">
            <form onSubmit={onSubmit}>
                <h2>Създайте своя профил</h2>
                <Grid
                    container
                    spacing={3}
                    direction={'column'}
                    justify={'center'}
                    alignItems={'center'}
                >
                    <Grid item xs={12} pl={"0px !important"}>
                        <TextField label="Email" onChange={onEmailChange}></TextField>
                    </Grid>
                    <Grid item xs={12} pl={"0px !important"}>
                        <TextField label="Парола" type={'password'} onChange={onPasswordChange} onKeyDown={onKeyDownHandler}></TextField>
                    </Grid>
                    <Grid item xs={12} pl={"0px !important"}>
                        <TextField label="Име" onChange={onNameChange}></TextField>
                    </Grid>
                    <Grid item xs={12} pl={"0px !important"}>
                        <TextField label="Фамилия" onChange={onLastNameChange}></TextField>
                    </Grid>
                    <h2>Училище</h2>
                    <Grow in={true}>
                        <div>
                            <Grid item xs={12}>
                                <FormControl sx={{ m: 1, minWidth: 210, maxWidth: 210 }}>
                                    <InputLabel sx={{ maxWidth: 240 }}
                                        htmlFor="grouped-native-select2">Община</InputLabel>
                                    <Select
                                        native
                                        value={selectedRegion ? selectedRegion.region : ""}
                                        id="grouped-native-select"
                                        label="Община"
                                        onChange={(event) => setRegion(event.target.value)}>
                                        <option aria-label="None" value="" />
                                        {schools.map((r, x) => (
                                            <option key={x} value={r.region}>{r.region}</option>

                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                        </div>
                    </Grow>
                    {selectedRegion ?
                        <Grow in={true}>
                            <div>
                                <Grid item xs={12}>
                                    <FormControl sx={{ m: 1, minWidth: 210, maxWidth: 210 }}>
                                        <InputLabel htmlFor="grouped-native-select">Населено място</InputLabel>
                                        <Select
                                            native
                                            value={selectedTown ? selectedTown.town : ""}
                                            id="grouped-native-select"
                                            label="Населено място"
                                            onChange={(event) => setTown(event.target.value)}>
                                            <option aria-label="None" value="" />
                                            {selectedRegion.towns.map((t, x) => (
                                                <option key={x} value={t.town}>{t.town}</option>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </div>
                        </Grow> :
                        null}
                    {selectedTown ?
                        <Grow in={true}>
                            <div>
                                <Grid item xs={12}>
                                    <FormControl sx={{ m: 1, minWidth: 210, maxWidth: 210 }}>
                                        <InputLabel htmlFor="grouped-native-select2">Училище</InputLabel>
                                        <Select
                                            native
                                            // defaultValue=""
                                            value={selectedSchool ? selectedSchool.name : ""}
                                            id="grouped-native-select"
                                            label="Училище"
                                            onChange={(event) => setSchool(event.target.value)}>
                                            <option aria-label="None" value="" />
                                            {selectedTown.schools.sort(function (a, b) { return a.name.localeCompare(b.name) }).map((s, x) => (
                                                <option key={x} value={s.name}>{s.name}</option>

                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </div>
                        </Grow> :
                        null}
                    <Grid item xs={12}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={acceptTerms}
                                    onChange={(e) => setAcceptTerms(e.target.checked)}
                                    name="acceptTerms"
                                />
                            }
                            label={(
                                <span>
                                    <span>Съгласявам се с </span>
                                    <Link to="/terms" target="_blank" rel="noopener noreferrer">общите условия</Link>
                                </span>
                            )}
                        />
                        <FormHelperText style={{ color: "red" }}>
                            {acceptError && !acceptTerms
                                ? "Следва да се съгласите с общите условия"
                                : " "}
                        </FormHelperText>
                    </Grid>
                    <Grid item xs={12}>
                        <Button type="submit"
                            className='auth-button'
                            fullWidth
                            variant="contained"
                            color="primary"
                            size="large"
                            sx={{ "borderRadius": "20px", "marginBottom": "20px" }}
                        > Регистрация </Button>
                    </Grid>
                </Grid>
                {isCapslockOn && <p className="capslock-watcher">CapsLock</p>}
            </form>
        </Paper >
    );
};

export default RegisterFormView;

import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';

import { register } from 'actions/authActions';
import RegisterFormView from './RegisterFormView';
import Layout from 'components/Layout';

import 'styles/Auth.scss';


const RegisterForm = ({
    register,
}) => {
    const history = useNavigate();
    const [isCapslockOn, setIsCapslockOn] = useState(false);

    const redirectUrl = '/login';

    const onFormFinish = (values) => {
        register(values)
            .then(() => {
                history(redirectUrl);
            })
            .catch(err => {

            });
    };

    // const confirmPasswordValidator = ({ getFieldValue }) => ({
    //     validator(_, value) {
    //         if (!value || getFieldValue('password') === value) {
    //             return Promise.resolve();
    //         }
    //         return Promise.reject('Въведените пароли не съвпадат');
    //     },
    // });

    const onKeyDownHandler = (keyEvent) => {
        if (keyEvent.getModifierState("CapsLock")) {
            setIsCapslockOn(true);
        } else {
            setIsCapslockOn(false);
        }
    };

    return (
        <Layout title='Регистрация'>
            <section className="auth-form-section">
                <div className="auth-form-container container">
                    <nav className="nav-container">
                        <Link to="/login">Вход</Link>
                        <Link to="/register" className="active" title="register"> Регистрация</Link>
                    </nav>
                    <RegisterFormView
                        onFinish={onFormFinish}
                        onKeyDownHandler={onKeyDownHandler}
                        isCapslockOn={isCapslockOn}
                    />
                </div>
            </section>
        </Layout>
    );
};



const mapDispatchToProps = {
    register,
}

export default connect(null, mapDispatchToProps)(RegisterForm);

import { Link } from "react-router-dom";

import Layout from 'components/Layout';

import { ReactComponent as Kids } from './assets/kids.svg';
import { ReactComponent as Kindergarden } from './assets/kindergarden.svg';
import { ReactComponent as Teddy } from './assets/teddy.svg';
import infoCasel from './assets/CASEL.jpg';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { Typography } from '@mui/material';
import {
    Paper,
} from '@mui/material';

import './Home.scss';
import HomeInformation from './HomeInformation';
import { info } from 'sass';


const ForSchools = () => {
    return (
        <Layout>
            <section className="home-section">
                <Paper elevation={12}>
                    <div className="home-container container">
                        <div className="quote">
                            <h1>“Основно умение в социалното познание е емпатията - осъзнаването, че другите чувстват и мислят, без да ни го назовават с думи”.
                            </h1>
                            <h2>Даниъл Голман</h2>
                        </div>
                    </div>
                </Paper>
                <Paper elevation={12}>
                    <div className="home-container container">

                        <div className="home-info">
                            <h1>“Окрилено начало: Програма за социално-емоционално образование”</h1>
                            <p style={{ display: "block" }}>Фондация “Лъчезар Цоцорков” финансира <a href="https://tsotsorkovfoundation.bg/project/%d0%bf%d1%80%d0%be%d0%b3%d1%80%d0%b0%d0%bc%d0%b0-%d1%81%d0%be%d1%86%d0%b8%d0%b0%d0%bb%d0%bd%d0%be-%d0%b8-%d0%b5%d0%bc%d0%be%d1%86%d0%b8%d0%be%d0%bd%d0%b0%d0%bb%d0%bd%d0%be-%d0%be%d0%b1%d1%80%d0%b0/" target="blank">13 организации</a>, които разработиха различни интервенции в сферата на социално-емоционалното образование в продължение на една година.  Проектите обхванаха училища в над 80 населени места, 400+ учители, 6300+ родители, 4100+ деца и 3 детски градини.
                            </p>
                            <br />
                            <p>След апробация и оценка, интервенциите бяха синтезирани в система за въвеждане на СЕУ от екип от педагози, директори, методисти, и детски психолози.  Системата “Окрилено начало: Програма за социално-емоционално образование” съдържа 4 помагала, обучение за учители, подкрепящи дейности и е тествана в 8 начални училища сред 174 учители и 1947 деца.
                            </p>
                        </div>
                        <div className="home-graphics">
                            <Kindergarden />
                        </div>
                    </div>
                </Paper>
                <Paper elevation={12}>
                    <div className="home-container container">
                        <div className="home-info">
                            <h1>Помагала 1-4 клас</h1>
                            <p>
                                Развиването на социално-емоционалните умения в училище е подход, който адресира неравенството между децата и улеснява постигането на академични успехи. Подготовката на учителите за използване на подхода за социално-емоционално образование е неразделна част от въвеждането на такава програма в училище. Имайки предвид, че 95% от поведенческите модели на личността се формират в началната училищна възраст, както и че дори и кратка интервенция показва трайни резултати за физическото и
                                психическото здраве, фондацията разработи следните помагала в помощ на учителите при внедряването на СЕУ.
                            </p>
                            <br />
                            <h4>Линкове към помагалата:</h4>
                            <ul>
                                <li><a target="blank" href="https://drive.google.com/file/d/13n-A7zCtRb38wVl00QBNuGFneZJyZkHv/view?usp=drive_link">Учебно помагало за първи клас</a></li>
                                <li><a target="blank" href="https://drive.google.com/file/d/117L0hH9_pcuLWPRx7ytzkkkcEDaD4SwQ/view?usp=drive_link">Учебно помагало за втори клас </a></li>
                                <li><a target="blank" href="https://drive.google.com/file/d/184efrNedymeYxhtiAW3WBaVlUaHzveep/view?usp=drive_link">Учебно помагало за трети клас</a></li>
                                <li><a target="blank" href="https://drive.google.com/file/d/1_l4Pdlo-xsrU-4i4iMdgzghcpUwOVStU/view?usp=drive_link">Учебно помагало за четвърти клас</a></li>
                            </ul>
                        </div>
                    </div>
                </Paper>
                <Paper elevation={12}>
                    <div className="home-container container">
                        <div className="home-info">
                            <h1>Оценка</h1>
                            <h4>За Инструмента</h4>
                            <p>Инструментът за директна оценка на социално емоционалните умения при деца от 6 до 10 години е една от разработките на Фондация “Лъчезар Цоцорков” в осъществяване на мисията за развиване на социално-емоционалното образование в България (СЕО).</p>
                            <br />
                            <h4>Защо е важен този инструмент?</h4>
                            <p>Оценката на социално-емоционалните умения (СЕУ) е необходимата първа стъпка за идентифициране на дефицитите и определяне на интервенциите и средствата за тяхното компенсиране.

                                Резултатите от неговото ползване ще ни позволят да научим от какво имат нужда нашите деца, за да се развиват пълноценно в динамично променящите се условия на съвременния свят.
                            </p>
                            <br />
                            <h4>Разработка</h4>
                            <p>Разработката на инструмент за директна оценка е пионерско начинание за нашата страна. Освен това ни поставя сред първите в света, които насочват усилия в посока приоритизиране на СЕО като част от националните образователни политики и предприемане на практически стъпки за обезпечаване на процеса.

                                Сходни инструменти разбира се съществуват - например, проучването на социално-емоционалните умения (СЕУ) на 15 годишните ученици, осъществявано от Организацията за икономическо сътрудничество и развитие (ОИСР). Към 2023 г. едва 17 държави в света са взели участие в това проучване - България е една от тях, което отново подчертава навременността на настоящата разработка.
                            </p>
                            <br />
                            <h4>Качество</h4>
                            <p>Качеството на настоящия инструмент се гарантира от два ключови критерия, които бяха поставени в началото на неговото създаване:

                                Разработката е съобразена със спецификата на нашата страна и е направена от водещи специалисти в областта на оценяването на социално-емоционалните умения;

                                Разработката е специално съобразена с възрастовата група 1-4 клас.
                            </p>
                            <br />
                            <h4>Какво представлява инструментът?</h4>
                            <p>Добре дошли в приложението за директна оценка на социално-емоционалните умения на учениците в 1-4 клас. Приложението представя ситуации, които пресъздават моменти от живота на най-малките ученици, а решенията, които те самостоятелно взимат преминавайки през тези ситуации, ни помагат да определим нивото им спрямо различни социално-емоционални компетентности.</p>
                            <br />
                            <Link to="/demo-boy">Демо момче</Link>
                            <br />
                            <Link to="/demo-girl">Демо момиче</Link>
                        </div>
                    </div>
                </Paper>




            </section>
        </Layout>
    )
}

export default ForSchools;
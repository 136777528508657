import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import LoginFormView from './LoginFormView';

import { login } from 'actions/authActions';
import Layout from 'components/Layout';

import 'styles/Auth.scss';


const Login = ({
    login,
}) => {
    const navigate = useNavigate();
    const [isLoginButtonDisabled, setLoginButtonDisabled] = useState(false);
    const [isCapslockOn, setIsCapslockOn] = useState(false);

    const onKeyDownHandler = (keyEvent) => {
        if (keyEvent.getModifierState("CapsLock")) {
            setIsCapslockOn(true);
        } else {
            setIsCapslockOn(false);
        }
    };

    const onFormFinish = (values) => {
        login(values)
            .then((res) => {
                navigate('/');
            })
            .catch(err => {
                navigate('/login')
                setLoginButtonDisabled(false);
            });
    };

    return (
        <Layout title='Вход'>
            <section className="auth-form-section">
                <div className="auth-form-container container">
                    <nav className="nav-container">
                        <Link to="/login" className="active" title="login"> Вход</Link>
                        <Link to={{
                            pathname: '/register',
                        }}
                            title="register"
                        >
                            Регистрация
                        </Link>
                    </nav>
                    <LoginFormView
                        onFormFinish={onFormFinish}
                        isLoginButtonDisabled={isLoginButtonDisabled}
                        isCapslockOn={isCapslockOn}
                        onKeyDownHandler={onKeyDownHandler}
                    />
                    <div style={{display: "flex"}}>
                        <Link to={{
                            pathname: '/forgotpassword',
                        }}
                            title="forgotpassword"
                            style={{margin: "10px auto"}}
                        >
                            Забравена парола
                        </Link>
                    </div>
                </div>
            </section>
        </Layout >
    )
};

const mapDispatchToProps = {
    login
}

export default connect(null, mapDispatchToProps)(Login);
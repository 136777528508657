import React, { useState } from 'react';
import {
    Paper,
    Button,
    Grid,
    TextField,
} from '@mui/material';

import 'styles/Auth.scss';

const ForgotPasswordView = ({
    onFormFinish,
}) => {

    const [email, setEmailValue] = useState("");
    const onEmailChange = (event) => setEmailValue(event.target.value);

    const onSubmit = (event) => {
        event.preventDefault()
        onFormFinish(email);
    }

    return (
        <Paper className="auth-form">
            <form onSubmit={onSubmit}>
                <h2>Забравена парола</h2>
                <Grid
                    container
                    spacing={3}
                    direction={'column'}
                    justify={'center'}
                    alignItems={'center'}
                >
                    <Grid item xs={12}>
                        <TextField label="Email" onChange={onEmailChange}></TextField>
                    </Grid>
                    <Grid item xs={12}>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            size="large"
                            sx={{ "borderRadius": "20px", "marginBottom": "20px" }}
                        > Изпрати ми имейл </Button>
                    </Grid>
                </Grid>
            </form>
        </Paper>
    )
}

export default ForgotPasswordView;

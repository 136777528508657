import React from 'react';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import * as XLSX from 'xlsx';
import moment from "moment";
import { getSchools, getSessions, getAnswers, getUsers } from 'reducers';

import { fetchSessions } from 'actions/sessionActions';
import { fetchSchools } from 'actions/schoolActions';
import { fetchQuizAnswers } from 'actions/quizActions';
import { activateUser, disableUser, fetchUsers } from 'actions/authActions';

import { DataGrid } from '@mui/x-data-grid';
import {
    Tabs,
    Tab,
    Typography,
    Box,
    Slide,
    Alert,
    Paper,
    Modal,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Button
} from '@mui/material'

import PropTypes from 'prop-types';
import Layout from 'components/Layout'


const AdminPanel = ({
    schools,
    fetchSchools,
    fetchQuizAnswers,
    answers,
    sessions,
    fetchSessions,
    fetchUsers,
    activateUser,
    users,
}) => {
    const [areAnswersLoading, setAreAnswersLoading] = useState(true);
    const [areSchoolsLoading, setAreSchoolsLoading] = useState(true);
    const [schoolData, setSchoolData] = useState([]);
    const [areSessionsLoading, setAreSessionsLoading] = useState(true);
    const [areUsersLoading, setAreUsersLoading] = useState(true);
    const [newUsers, setNewUsers] = useState([]);


    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedSchool, setSelectedSchool] = useState(null);

    const openModalWithSchool = (school) => {
        setSelectedSchool(school);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setSelectedSchool(null);
    };

    useEffect(() => {
        fetchQuizAnswers().then(() => {
            setAreAnswersLoading(false);
        })

    }, []);

    useEffect(() => {
        fetchSessions().then(() => {
            setAreSessionsLoading(false);
        })

        fetchSchools().then(() => {
            setAreSchoolsLoading(false);
            setSchoolData(Object.values(schools));

        })

        fetchUsers().then(() => {
            setAreUsersLoading(false);
        })


    }, []);

    useEffect(() => {
        setNewUsers(users.filter(x => x.active == false));
    }, [users])

    function TransitionUp(props) {
        return <Slide {...props} direction="up"><Alert variant="filled" severity="success" sx={{ width: '100%' }}>
            Успешно добавихте класа си!
        </Alert></Slide>;
    }
    function TabPanel(props) {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box sx={{ p: 3 }}>
                        <Typography component={'div'}>{children}</Typography>
                    </Box>
                )}
            </div>
        );
    }

    TabPanel.propTypes = {
        children: PropTypes.node,
        index: PropTypes.number.isRequired,
        value: PropTypes.number.isRequired,
    };

    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    const [value, setValue] = React.useState(0);
    const handleTabChange = (event, newValue) => {
        setValue(newValue);
    };


    const columns = [
        { field: 'name', headerName: 'Име', minWidth: 500, headerAlign: 'center', },
        { field: 'town', headerName: 'Населено място', minWidth: 300, headerAlign: 'center', },
        {
            field: 'teachers',
            headerName: 'Учители',
            // description: 'This column has a value getter and is not sortable.',
            // sortable: false,
            minWidth: 150,
            headerAlign: 'center',
            valueGetter: (data) =>
                `${data.row.teachers?.length || ''}`,
        },
    ];

    const columnsWithButton = [
        ...columns,
        {
            field: 'details',
            headerName: 'Details',
            minWidth: 150,
            headerAlign: 'center',
            renderCell: (params) => (
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => openModalWithSchool(params.row)}
                >
                    Детайли
                </Button>
            ),
        },
    ];
    function activate(userId) {
        activateUser(userId);
        setNewUsers(newUsers.filter(x => x.id != userId));
    }

    function disable(userId) {
        disableUser(userId);
        setNewUsers(newUsers.filter(x => x.id != userId));
    }

    function downloadStatistics() {

        function findClassById(data, classId) {
            // Ensure teachers data is available and iterable
            if (data.teachers && Array.isArray(data.teachers)) {
                // Loop through each teacher
                for (let teacher of data.teachers) {
                    // Ensure classes data is available and iterable
                    if (teacher.classes && Array.isArray(teacher.classes)) {
                        // Loop through each class
                        for (let classObj of teacher.classes) {
                            // Check if the class ID matches
                            if (classObj.id === classId) {
                                return classObj;
                            }
                        }
                    }
                }
            }
            // Return null if class was not found
            return null;
        }
        const ws_data = [];
        ws_data.push([
            "град",
            "училище",
            "клас",
            "сесия",
            "година",
            "номер",
            "дата на раждане",
            "име",
            "пол",
            "дата на попълване",
            "общо време",
            "общо точки",
            "отговор",
            "точки",
            "време",
        ])
        for (const key in answers) {
            if (answers.hasOwnProperty(key)) {
                const item = answers[key];
                // Extracting data from the item and adding it to ws_data in desired format.
                let currSchool = schoolData.filter(x => x.id == item.schoolId)[0];
                if (!currSchool) {
                    console.log("missing school", item.schoolId);
                }

                let currClass = findClassById(currSchool, item.classId);
                const answersData = [];
                for (let i = 0; i < item.selectedAnswers.length; i++) {
                    answersData.push(item.selectedAnswers[i], item.answersPoints[i], item.answersTime[i]);
                };

                ws_data.push([
                    currSchool.town,
                    currSchool.name,
                    currClass.name,
                    item.type == "INITIAL" ? "Първоначална" : "Финална",
                    item.year,
                    item.student.number,
                    item.student.birthdate,
                    item.student.name,
                    item.student.gender == "boy" ? "момче" : "момиче",
                    moment(item.startTime.seconds * 1000 + item.startTime.nanoseconds / 1e6).format("YYYY-MM-DD HH:mm:ss"),
                    item.totalTime,
                    item.points,
                    ...answersData
                ]);

               
            }
        }

        // Creating a workbook and a worksheet.
        const ws = XLSX.utils.aoa_to_sheet(ws_data);
        const wb = XLSX.utils.book_new();

        // Adding the worksheet to the workbook and naming it.
        XLSX.utils.book_append_sheet(wb, ws, "Answers");

        // Writing the workbook to an xls file and downloading it.
        XLSX.writeFile(wb, "statistics.xls");
    }

    return (
        <Layout>
            <section className="teacher-panel-section">
                <Paper elevation={12}>
                    <Box sx={{ width: '100%' }}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <Tabs value={value} onChange={handleTabChange} aria-label="basic tabs example">
                                <Tab label="Училищa" {...a11yProps(0)} />
                                <Tab label="Резултати" {...a11yProps(1)} />
                                <Tab label="Заявки за одобрение" {...a11yProps(2)} />
                            </Tabs>
                        </Box>
                        <TabPanel value={value} index={0}>
                            {areSchoolsLoading ?
                                null
                                :
                                <DataGrid
                                    
                                    rows={Object.values(schools)}
                                    columns={columnsWithButton}
                                    pageSize={5}
                                    rowsPerPageOptions={[5, 10]}
                                    getRowId={(row) => row.id}
                                    autoHeight
                                />
                            }
                            <Modal
                                open={isModalOpen}
                                onClose={closeModal}
                                aria-labelledby="school-details-modal"
                                aria-describedby="school-details-description"
                            >
                                <Box sx={{
                                    position: 'absolute',
                                    component:'div',
                                    top: '50%',
                                    left: '50%',
                                    transform: 'translate(-50%, -50%)',
                                    width: "60%",
                                    bgcolor: 'background.paper',
                                    border: '2px solid #000',
                                    borderRadius: "15px",
                                    boxShadow: 24,
                                    p: 4,
                                }}>
                                    <Typography variant="h5" component="h2">
                                        Информация: {selectedSchool?.name}
                                    </Typography>
                                    <Typography variant="h6" component="h2">
                                        Администратор: {selectedSchool?.teachers.filter(x => x.id == selectedSchool?.administrator)[0].firstName} {selectedSchool?.teachers.filter(x => x.id == selectedSchool?.administrator)[0].lastName}
                                    </Typography>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Учител</TableCell>
                                                <TableCell>Имейл</TableCell>
                                                <TableCell>Класове</TableCell>
                                                {/* Add other columns as needed */}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {selectedSchool?.teachers.map((teacher) => (
                                                <TableRow key={teacher.id}>
                                                    <TableCell>{teacher.firstName} {teacher.lastName}</TableCell>
                                                    <TableCell>{teacher.email}</TableCell>
                                                    <TableCell>{teacher.classes.length}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </Box>
                            </Modal>
                        </TabPanel>
                        <TabPanel value={value} index={1}>
                            <Button
                                variant="contained"
                                style={{ marginLeft: 10 }}
                                color="primary"
                                onClick={() => downloadStatistics()}
                            >
                                Свали статистика
                            </Button>
                        </TabPanel>
                        <TabPanel value={value} index={2}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Имейл</TableCell>
                                        <TableCell>Имена</TableCell>
                                        <TableCell>Роля</TableCell>
                                        <TableCell>Община</TableCell>
                                        <TableCell>Град</TableCell>
                                        <TableCell>Училище</TableCell>
                                        <TableCell>Действия</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {newUsers.map((user) => {
                                        const [region, town, schoolName] = user.school.split('|');
                                        return (<TableRow key={user.id}>
                                            <TableCell>{user.email}</TableCell>
                                            <TableCell>{user.firstName} {user.lastName}</TableCell>
                                            <TableCell>{user.roles.includes("ADMINISTRATOR") ? "Администратор" : 'Учител'}</TableCell>
                                            <TableCell>{region}</TableCell>
                                            <TableCell>{town}</TableCell>
                                            <TableCell>{schoolName}</TableCell>
                                            <TableCell>
                                                {/* Button to activate */}
                                                <Button
                                                    variant="contained"
                                                    style={{ marginRight: 10 }}
                                                    color="primary"
                                                    onClick={() => activate(user.id)}
                                                >
                                                    Одобри
                                                </Button>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={() => disable(user.id)}
                                                >
                                                    Отхвърли
                                                </Button>
                                            </TableCell>
                                        </TableRow>)
                                    })}
                                </TableBody>
                            </Table>
                        </TabPanel>
                    </Box>
                </Paper>
            </section >
        </Layout>
    )


};
const mapStateToProps = (state) => ({
    sessions: getSessions(state),
    schools: getSchools(state),
    users: getUsers(state),
    answers: getAnswers(state),
});

const mapDispatchToProps = {
    fetchSessions,
    fetchSchools,
    fetchUsers,
    activateUser,
    fetchQuizAnswers,

};

export default connect(mapStateToProps, mapDispatchToProps)(AdminPanel);
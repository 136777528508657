import {
  Grid,
  Tooltip,
  IconButton,
  Paper,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { styled } from "@mui/material/styles";
import StudentCard from "../StudentCard";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const ClassCard = ({
  userId,
  classData,
  deleteClass,
  editMode = true,
  fullWidth = false,
  quizLink = false,
  showEditClassModal,
  to,
}) => {
  return (
    <Grid item xs={fullWidth ? 12 : 4}>
      <Item>
        <>{classData.name}</>
        {editMode ? (
          <>
            <Tooltip title="редактирай">
              <IconButton
                onClick={() => {
                  showEditClassModal(classData);
                }}
              >
                <EditIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="изтрий">
              <IconButton
                onClick={() => {
                  deleteClass(userId, classData.id);
                }}
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </>
        ) : null}
      </Item>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
         ученици
        </AccordionSummary>
        <AccordionDetails>
          {classData.students.map((st) => {
            return (
              <StudentCard
                key={st.name}
                student={st}
                quizLink={quizLink ? true : false}
                to={`${to}&class_id=${classData.id}`}
              />
            );
          })}
        </AccordionDetails>
      </Accordion>
    </Grid>
  );
};

export default ClassCard;

import { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { hideNotification } from 'actions/notificationActions';
import {
    hasNotification,
    getMessage,
    getType,
} from 'reducers';

import {
    Slide,
    Alert,
    Snackbar
} from "@mui/material";


const Notification = ({
    hasNotification,
    hideNotification,
    message,
    type,
}) => {
    const [open, setSnackBarOpen] = useState(false);
    const handleClose = () => {
        setSnackBarOpen(false);
        hideNotification();
    };

    function TransitionUp(props) {
        return <Slide {...props} direction="up"><Alert variant="filled" severity={type} sx={{ width: '100%' }}>
            {message}</Alert></Slide>;
    }

    useEffect(() => {
        // // const correctType = !isSessionExpired ? type : 'info';
        // const correctMessage = message ? "" : message;
        if(hasNotification){
            setSnackBarOpen(true);
        }
    }, [hasNotification, hideNotification, type, message]);
    // return null; 
    // if (hasNotification) {
       return <Snackbar
            autoHideDuration={3000}
            open={open}
            onClose={handleClose}
            TransitionComponent={TransitionUp}
            message={message}
            transitionDuration={500}
            anchorOrigin={{ vertical: "top", horizontal: "left" }}
        />
    // }

};




const mapStateToProps = (state) => ({
    message: getMessage(state),
    hasNotification: hasNotification(state),
    type: getType(state),
});

const mapDispatchToProps = {
    hideNotification,
};

export default connect(mapStateToProps, mapDispatchToProps)(Notification);

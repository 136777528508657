import {
    SHOW_NOTIFICATION,
    HIDE_NOTIFICATION,
} from './actionTypes';

// Simple action creators
const showNotificationSuccess = (payload) => ({
    type: SHOW_NOTIFICATION,
    payload,
});

const hideNotificationSuccess = () => ({
    type: HIDE_NOTIFICATION
});

// Complex action creators
export const showNotification = (data) => (dispatch) => dispatch(showNotificationSuccess(data));
export const hideNotification = () => (dispatch) => dispatch(hideNotificationSuccess());

import { combineReducers } from 'redux';

import {
    FETCH_SCHOOL_SUCCESS,
    FETCH_SCHOOLS_SUCCESS,
} from '../actions/actionTypes';



const schools = (state = [], action) => {
    switch (action.type) {
        case FETCH_SCHOOLS_SUCCESS:
            return { ...action.payload };
        default:
            return state;
    }
};

const currentSchoolInitialState = {
    _id: '',
    name: '',
    town: '',
    password: '',
    verified: false,
    teachers: [{}],
    administrator: {},
};

const currentSchool = (state = currentSchoolInitialState, action) => {
    switch (action.type) {
        case FETCH_SCHOOL_SUCCESS:
            return { ...currentSchoolInitialState, ...action.payload };
        default:
            return state;
    }
};

export default combineReducers({
    list: schools,
    current: currentSchool,
});

export const getSchools = (state) => state.list;
export const getSchool = (state) => state.current;
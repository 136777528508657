import React, { useEffect, useState } from "react";
import moment from 'moment';
import {
  Box,
  TextField,
  Button,
  Icon,
  Paper,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  DialogActions,
  Slide,
  Typography,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from "@mui/material";
import Draggable from "react-draggable";

import Table from "@mui/material/Table";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import { defaultStudentStateShape } from "./constants";

import "./EditClassModal.scss";

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper style={{ maxWidth: "800px" }}{...props} />
    </Draggable>
  );
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const EditClassModal = ({ classData, open, handleClose, handleSubmitForm }) => {
  const [name, setNameValue] = useState(classData.name);
  const [students, setStudents] = useState([defaultStudentStateShape]);
  const [classId, setClassId] = useState(classData.id);
  const onNameChange = (event) => setNameValue(event.target.value);
  const addStudentRow = () => {
    classData.students.push(defaultStudentStateShape);
    setStudents([...students, defaultStudentStateShape]);
  };

  useEffect(() => {
    setStudents(classData.students);
    setClassId(classData.id);
    setNameValue(classData.name);
  }, [classData, students])

  const handleSubmit = (event) => {
    event.preventDefault();
    const studentsWithNumbers = students.map((s, i) => ({
      ...s,
      number: i + 1,
    }));
    handleSubmitForm({ id: classId, name, students: studentsWithNumbers });
    handleClose();
  };

  const handleStudentChange = (e, index) => {
    setStudents(() => {
      classData.students[index] = {
        ...classData.students[index], [e.target.name]: e.target.value,
      }
    });
  };

  const handleDialogClose = () => {
    setStudents([defaultStudentStateShape]);
    handleClose();
  };

  const handleDateChange = (e, index) => {
    if (e?.isValid()) {
      classData.students[index] = {
        ...classData.students[index], birthdate: e.toISOString().split("T")[0]
      }
    }
  };
  return (
    <Dialog
      open={open}
      onSubmit={handleSubmit}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
      scroll="paper"
      TransitionComponent={Transition}
      students={students}
    >
      <form>
        <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
          Редактирай клас
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Редактирайте името на класа и учениците в него.
          </DialogContentText>
          <Box className="class-primary-data">
            <TextField
              required
              id="outlined-basic"
              label="Име на клас"
              value={name}
              variant="outlined"
              onChange={onNameChange}
            />
          </Box>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align="justify">Номер</TableCell>
                  <TableCell align="justify">Имена</TableCell>
                  <TableCell align="justify">Пол</TableCell>
                  <TableCell align="justify">Дата на раждане</TableCell>
                </TableRow>
              </TableHead>
              {classData.students?.map((student, index) => (
                <TableRow
                  key={index}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell align="justify" className="student-number">
                    <Typography align="center">{index + 1}</Typography>
                  </TableCell>
                  <TableCell align="justify" className="student-name">
                    <TextField
                      required
                      id="outlined-required"
                      label="Имена"
                      defaultValue={student?.name}
                      size="small"
                      name="name"
                      onChange={(e) => handleStudentChange(e, index)}
                    />
                  </TableCell>
                  <TableCell>
                    <FormControl className="student-gender" size="small">
                      <InputLabel id="student-gender-label">Пол</InputLabel>
                      <Select
                        labelId="student-gender-label"
                        required
                        id="student-gender"
                        name="gender"
                        value={student?.gender}
                        label="Пол"
                        onChange={(e) => handleStudentChange(e, index)}
                      >
                        <MenuItem value="boy">Момче</MenuItem>
                        <MenuItem value="girl">Момиче</MenuItem>
                      </Select>
                    </FormControl>
                  </TableCell>
                  <TableCell align="justify" className="student-birthdate">
                    <DatePicker
                      required
                      id="outlined-required"
                      label="Дата"
                      defaultValue={moment(student?.birthdate)}
                      size="small"
                      name="birthdate"
                      onChange={(e) => handleDateChange(e, index)}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </Table>
          </TableContainer>
          <Box>
            <Button className="add-button" onClick={addStudentRow}>
              <span>Добави ученик</span>
              <Icon>add_circle</Icon>
            </Button>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose}>Отказ</Button>
          <Button type="submit">Запази промени</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default EditClassModal;


import { Link } from "react-router-dom";

import Layout from 'components/Layout';

import { ReactComponent as Kids } from './assets/kids.svg';
import { ReactComponent as Kindergarden } from './assets/kindergarden.svg';
import { ReactComponent as Teddy } from './assets/teddy.svg';
import infoCasel from './assets/CASEL.jpg';
import oecdWeb from './assets/oecdWeb.jpg';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { Typography } from '@mui/material';
import {
    Paper, Button
} from '@mui/material';

import './Home.scss';
import HomeInformation from './HomeInformation';
import { info } from 'sass';


const Materials = () => {
    return (
        <Layout>
            <section className="home-section">
                <Paper elevation={12}>
                    <div className="home-container container">
                        <div className="home-info">
                            <h1>Помагала 1-4 клас</h1>
                            <p>
                                Развиването на социално-емоционалните умения в училище е подход, който адресира неравенството между децата и улеснява постигането на академични успехи. Подготовката на учителите за използване на подхода за социално-емоционално образование е неразделна част от въвеждането на такава програма в училище.
                            </p>
                            <br></br>
                            <p>
                                Имайки предвид, че 95% от поведенческите модели на личността се формират в началната училищна възраст, както и че дори и кратка интервенция показва трайни резултати за физическото и психическото здраве, фондацията разработи следните помагала в помощ на учителите при внедряването на СЕУ.
                            </p>
                        </div>
                    </div>
                </Paper>
                <Paper elevation={12}>
                    <div className="home-container container">
                        <div className="home-info">
                            <Typography>
                                <a href="https://drive.google.com/file/d/13n-A7zCtRb38wVl00QBNuGFneZJyZkHv/view?usp=sharing" target="_blank">1-ви клас</a>
                            </Typography> <br />
                            <Typography>
                                <a href="https://drive.google.com/file/d/117L0hH9_pcuLWPRx7ytzkkkcEDaD4SwQ/view?usp=sharing" target="_blank" rel="noopener">2-ри клас</a>
                            </Typography> <br />
                            <Typography>
                                <a href="https://drive.google.com/file/d/184efrNedymeYxhtiAW3WBaVlUaHzveep/view?usp=sharing" target="_blank" rel="noopener">3-ти клас</a>
                            </Typography> <br />
                            <Typography>
                                <a href="https://drive.google.com/file/d/1_l4Pdlo-xsrU-4i4iMdgzghcpUwOVStU/view?usp=sharing" target="_blank" rel="noopener">4-ти клас</a>
                            </Typography> <br />
                        </div>
                    </div>
                </Paper>
            </section>
        </Layout>
    )
}

export default Materials;
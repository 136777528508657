import IconButton from '@mui/material/IconButton';
import VolumeUpOutlinedIcon from '@mui/icons-material/VolumeUpOutlined';

const AudioButton = ({
  audioPath,
  isAudioPlaying,
  setIsAudioPlaying,
  setAudio,
  audio
}) => {

  const playAudio = () => {
    if (isAudioPlaying) {
      audio.pause();
    }

    setIsAudioPlaying(true);
    let sound = new Audio(audioPath);
    sound.onerror = () => {
      setIsAudioPlaying(false);
    };
    sound.onended = () => {
      setIsAudioPlaying(false);
    }
    setAudio(sound);
    try {
      sound.play();
    } catch{
      
    }
  }

  return (
    <IconButton color="primary" className="audio-button" variant="contained" onClick={playAudio} style={{border:"1px solid"}}>
      <VolumeUpOutlinedIcon />
    </IconButton>
  )
}

export default AudioButton;

import { Provider } from 'react-redux';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'

import Notification from 'components/Notification';
import ProtectedRoute from 'components/ProtectedRoute';
import Home from 'components/Home';
import About from 'components/About';
import Register from 'components/Register';
import Login from 'components/Login';
import Profile from 'components/Profile';
import Administration from 'components/Administration';
import TeacherPanel from 'components/TeacherPanel';
import AdminPanel from 'components/AdminPanel';
import QuizPanel from 'components/QuizPanel';
import Demo from 'components/Demo';
import FAQ from 'components/FAQ';
import store from './utils/store';
import { ROLES } from 'constants.js';
import ForgotPassword from 'components/ForgotPassword/ForgotPassword';

import './App.scss';
import TermsAndConditions from 'components/TermsAndConditions';
import OECD from 'components/OECD';
import LiveStream from 'components/LiveStream';
import ForSchools from 'components/Home/ForSchools';
import AboutUs from 'components/About/AboutUs';
import ForTheProgram from 'components/Home/ForTheProgram';
import Evaluation from 'components/Home/Evaluation';
import Reports from 'components/Home/Reports';
import Materials from 'components/Home/Materials';
import OISR from 'components/Home/OISR';


const App = () => {
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <Router>
        <Provider store={store}>

          <Notification />
          <Routes>
            <Route path='/' element={<Home />}></Route>
            <Route path='/about' element={<AboutUs />}></Route>
            <Route path="/register" element={<Register />} />
            <Route path="/login" element={<Login />} />
            <Route path="/forgotpassword" element={<ForgotPassword />} />
            <Route path="/panel" element={<ProtectedRoute roles={[ROLES.TEACHER]} outlet={<TeacherPanel />} />} />
            <Route path="/quiz-panel" element={<QuizPanel />} />
            <Route path="/profile" element={<ProtectedRoute roles={[ROLES.TEACHER]} outlet={<Profile />} />} />
            <Route path="/administration" element={<ProtectedRoute roles={[ROLES.ADMINISTRATOR]} outlet={<Administration />} />} />
            <Route path="/admin-panel" element={<ProtectedRoute roles={[ROLES.ADMIN]} outlet={<AdminPanel />} />} />
            <Route path="/demo-boy" key="boy" element={<Demo boy={true} />} />
            <Route path="/demo-girl" key="girl" element={<Demo />} />
            <Route path="/terms" key="terms" element={<TermsAndConditions />} />
            <Route path="/oecd" key="OECD" element={<OECD />} />
            <Route path="/oisr" key="oisr" element={<OISR />} />
            <Route path="/oecdedu" key="oecdedu" element={<OECD />} />
            <Route path="/schools" key="schools" element={<ForSchools/>}/>
            <Route path="/program" key="program" element={<ForTheProgram/>}/>
            <Route path="/reports" key="reports" element={<Reports/>}/>
            <Route path="/materials" key="materials" element={<Materials/>}/>
            <Route path="/evaluation" key="evaluation" element={<Evaluation/>}/>
            <Route path="/live" key="live" element={<LiveStream />} />
            <Route path="*" element={<Home />}></Route>
            
          </Routes>
        </Provider>
      </Router >
    </LocalizationProvider>
  );
}

export default App;

import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from '../reducers';

const loadState = () => {
    try {
        const currentLocalVersion = localStorage.getItem('app-version');
       
        if (currentLocalVersion !==  process.env.REACT_APP_VERSION) {
            localStorage.setItem('app-version',  process.env.REACT_APP_VERSION)
            return undefined;
        }

        let serializedState = localStorage.getItem('state');
        
        if (serializedState) {
            return JSON.parse(serializedState);
        }
    } catch (error) {
        if (process.env.NODE_ENV === 'development') {
            console.error(`Cannot load state: ${error}`)            
        }

        return undefined;       
    }
};

const saveState = state => {
    try {
        let serializedState = JSON.stringify(state);

        localStorage.setItem('state', serializedState);
    } catch (error) {
        if (process.env.NODE_ENV === 'development') {
            console.error(`Cannot save state: ${error}`)
        }
    }
};

const middlewares = [
    thunk,
];

const persisedState = loadState();
const composeEnhancers = (typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

const store = createStore(rootReducer, persisedState, composeEnhancers(
    applyMiddleware(...middlewares)
));

store.subscribe(() => {
    saveState(store.getState());
});

export default store;


import { Link } from "react-router-dom";

import Layout from 'components/Layout';

import { ReactComponent as Kids } from './assets/kids.svg';
import { ReactComponent as Kindergarden } from './assets/kindergarden.svg';
import { ReactComponent as Teddy } from './assets/teddy.svg';
import infoCasel from './assets/CASEL.jpg';
import oecdWeb from './assets/oecdWeb.jpg';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { Typography } from '@mui/material';
import {
    Paper, Button
} from '@mui/material';

import './Home.scss';
import HomeInformation from './HomeInformation';
import { info } from 'sass';


const Reports = () => {
    return (
        <Layout>
            <section className="home-section">
                <Paper elevation={12}>
                    <div className="home-container container">
                        <div className="home-info">
                            <h1>Доклади</h1>
                            <a href="Резюме%20на%20резултатите%20от%20Изследването%20на%20СЕУ,%20ОИСР%202023.pdf" download>
                                <Button variant="contained" color="secondary" style={{ marginTop: '10px' }}>
                                    Резюме на резултатите от Изследването на СЕУ, ОИСР 2023
                                </Button>
                            </a>
                            <br></br>
                            <a href="БГ Доклад ИСЕУ 2023-2.pdf" download>
                                <Button variant="contained" color="secondary" style={{ marginTop: '10px' }}>
                                    БГ Доклад ИСЕУ 2023
                                </Button>
                            </a>

                        </div>
                    </div>
                </Paper>
                <Paper elevation={12}>
                    <div className="home-container container">
                        <div className="home-info">
                            <h1>Полезни ресурси на английски език</h1>
                            <Typography>
                                <a href="https://drive.google.com/file/d/1fr5c8BYqpZFAN9zpLbYyuC8qs2ZAs18W/view?usp=drive_link" target="_blank">Handbook of Social and Emotional Learning</a>
                            </Typography>
                            <Typography>
                                <a href="https://drive.google.com/file/d/16ojus-yGSBSCM85LLnwLLSjaZB3D-g9Q/view?usp=drive_link" target="_blank" rel="noopener">Infusing Social Emotional Learning into the Teacher Education Curriculum</a>
                            </Typography>
                            <Typography>
                                <a href="https://drive.google.com/file/d/13Ub0515WlMZUWHTwi9Qzbjk0zu_W5ztr/view?usp=drive_link" target="_blank" rel="noopener">Evaluating Social and Emotional Learning Programs</a>
                            </Typography>
                        </div>
                    </div>
                </Paper>
            </section>
        </Layout>
    )
}

export default Reports;
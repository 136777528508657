import { combineReducers } from 'redux';

import auth, * as fromAuth from './authReducer';
import session, * as fromSession from './sessionReducer';
import answers, *as fromQuiz from './quizReducer';
import schools, *as fromSchools from './schoolReducer';
import notification, * as fromNotification from './notificationReducer';

const root = combineReducers({
    auth,
    session,
    schools,
    notification,
    answers
});

export default root;

// Auth
export const isAuthenticated = (state) => fromAuth.isAuthenticated(state.auth);
export const isAdmin = (state) => fromAuth.isAdmin(state.auth);
export const isAdministrator = (state) => fromAuth.isAdministrator(state.auth);
export const isActive = (state) => fromAuth.isActive(state.auth);
export const isTeacher = (state) => fromAuth.isTeacher(state.auth);

export const getUser = (state) => fromAuth.getUser(state.auth);
export const getUsers = (state) => fromAuth.getUsers(state.auth);
export const getUserRoles = (state) => fromAuth.getUserRoles(state.auth);
export const getUserSchool = (state) => fromAuth.getUserSchool(state.auth);
export const getIdToken = (state) => fromAuth.getIdToken(state.auth);

// Session
export const getSession = (state) => fromSession.getSession(state.session);
export const getSessions = (state) => fromSession.getSessions(state.session);

// Schools
export const getSchools = (state) => fromSchools.getSchools(state.schools);
export const getSchool = (state) => fromSchools.getSchool(state.schools);

// Notification
export const hasNotification = (state) => fromNotification.hasNotification(state.notification);
export const getMessage = (state) => fromNotification.getMessage(state.notification);
export const getType = (state) => fromNotification.getType(state.notification);

// Quiz
export const getAnswers = (state) => fromQuiz.getQuizAnswers(state.answers);
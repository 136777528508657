import React from 'react';
import { Typography, Paper, Link, Button, Grid, List, ListItem, ListItemText } from '@mui/material';
import eventBanner from "./oecdBanner.jpg";
import EventProgram from './EventProgram/EventProgram';
import Panelists from './Panelists/Panelists';

const EventDetails = () => {
  return (
    <Paper style={{ padding: '20px', margin: '20px' }}>

      <Typography variant="h4" gutterBottom>
        Овластяваме новото поколение
      </Typography>
      <Typography variant="h4" gutterBottom>
        Изводи от проучването на ОИСР за 2023 г. относно социалните
        и емоционални умения на ученици
      </Typography>
      <Typography variant="h4" gutterBottom>
        Международно оповестяване

      </Typography>
      <img style={{ maxWidth: "100%" }} src={eventBanner} alt={"img"} />
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Typography variant="h6">Дата</Typography>
          <Typography>26 април 2024г. от 11:00 до 16:30 българско време</Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="h6">Локация</Typography>
          <Typography>хотел Хилтън, София, България, бул. България 1</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6">Организирано от</Typography>
          <Typography>Организация за икономическо сътрудничество и развитие (ОИСР) и Фондация “Лъчезар Цоцорков”</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6">За събитието</Typography>
          <Typography>
            Проучването на ОИСР за социални и емоционални умения е международно проучване, което идентифицира и оценява условията и практиките, които насърчават или възпрепятстват развитието на социално-емоционални умения за 10- и 15-годишни ученици.
          </Typography>
          <Typography>
            Изследванията показват, че както когнитивните, така и социалните и емоционалните умения подобряват резултатите от живота на обществено и индивидуално ниво. Съществува значителна информация за развитието и значението на когнитивните умения, но липсва такава за социалните и емоционалните умения на подрастващите. <a href="https://www.oecd.org/education/ceri/social-emotional-skills-study/">Проучването на социалните и емоционални умения (SSES)</a> има за цел да допринесе за запълването на тази информационна празнина.
          </Typography>
          <Typography>
            В настоящия, втори кръг на проучването, участват 16 локации (както държави, така и отделни градове), представляващи 15 държави. България беше една от държавите-участниците и сега е домакин на глобалното представяне на резултатите от проучването.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6">Цели на проучването</Typography>
          <List>
            <ListItem>
              <ListItemText primary="Обяснява как социалните и емоционални умения на учениците са свързани с ключовите постижения в живота." />
            </ListItem>
            <ListItem>
              <ListItemText primary="Идентифицира факторите в семейната, училищната и обществената среда на учениците, които насърчават или възпрепятстват развитието на социално-емоционални умения." />
            </ListItem>
            <ListItem>
              <ListItemText primary="Проучва как по-широките политически, културни и социално-икономически фактори влияят на тези умения." />
            </ListItem>
            <ListItem>
              <ListItemText primary="Предоставя на участващите градове и държави информация за социалните и емоционални умения на техните ученици." />
            </ListItem>
          </List>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6">Достъп и регистрация за събитието</Typography>
          <Typography>
          Събитието ще бъде излъчено на живо на този сайт. След регистрация, участници от цял свят могат да се присъединят чрез Twitch или д агледат излъчването на живо: <a href="/live">sel.bg/live</a>
          </Typography>
          <Typography>
          Присъственото участие на събитието е с покана поради ограничените места и след регистрация. 
          </Typography>
          <Typography>
          Моля, обърнете внимание, че разходите за пътуване и настаняване няма да бъдат покрити и са за сметка на пътуващите участници.

          </Typography>
          <a href="/registration-oecd-bg.html">
            <Button variant="contained" color="primary" style={{ marginTop: '10px' }}>
              Регистрирай се сега
            </Button>
          </a>
        </Grid>
        <Grid item xs={12}>
          <EventProgram></EventProgram>
        </Grid>
        <Grid item xs={12}>
          <Panelists></Panelists>
          <br></br>
          <Typography>На място гостите ще имат възможност да разгледат изложба на учениците от <a href="https://ngpisvetiluka.com/">Националната гимназия по приложни изкуства „Св. Лука“ – София</a>, която представя осемте специалности на гимназията - художествена дърворезба, художествена тъкан, художествена обработка на метали, детски играчки и сувенири, рекламна графика, силикатен дизайн, пространствен дизайн и моден дизайн.

          </Typography>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default EventDetails;

import { useEffect, useState, useRef } from 'react';
import {
  Slide,
  Alert,
  Snackbar
} from "@mui/material";
import Layout from "components/Layout";
import QuizHeader from "components/QuizPanel/QuizHeader";
import QuestionView from "components/QuizPanel/QuestionView";
import QuizNavigation from 'components/QuizPanel/QuizNavigation/QuizNavigation';
import ResultsModal from 'components/QuizPanel/ResultsModal';
import { SKILLS, QUESTIONS } from 'constants.js';
import './Demo.scss';

const Demo = ({
  boy
}) => {
  const [activeStep, setActiveStep] = useState(0);
  const [selectedAnswers, setSelectedAnswers] = useState([]);
  const [open, setSnackBarOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [severity, setSeverity] = useState('success');
  const [isResultModalVisible, setIsResultsModalVisible] = useState(false);
  const [results, setResults] = useState({ data: {} });
  const [isAudioPlaying, setIsAudioPlaying] = useState(false);
  const [audio, setAudio] = useState(null);

  let answersPoints = {
    SELF_AWARENESS: 0,
    SOCIAL_AWARENESS: 0,
    SELF_MANAGEMENT: 0,
    RELATIONSHIP_SKILLS: 0,
    RESPONSIBLE_DECISION_MAKING: 0,
  };

  let maxPointsArr = {
    SELF_AWARENESS: 0,
    SOCIAL_AWARENESS: 0,
    SELF_MANAGEMENT: 0,
    RELATIONSHIP_SKILLS: 0,
    RESPONSIBLE_DECISION_MAKING: 0,
  }


  const filteredQuestions = boy ? QUESTIONS.filter(x => x.gender == "boy") : QUESTIONS.filter(x => x.gender == "girl");

  const maxPoints = filteredQuestions.map(x => {
    let pointsArr = x.points.split(",").map(str => Number(str));
    let max = pointsArr.reduce((a, b) => Math.max(a, b), -Infinity);
    return max;
  }).reduce((sum, a) => sum + a, 0);

  useEffect(() => {
    stopAudio();

  }, [activeStep])

  useEffect(() => {
    setActiveStep(0);
    setSelectedAnswers([]);

  }, [boy])


  const stopAudio = () => {
    if (isAudioPlaying) {
      audio.pause();
      audio.currentTime = 0;
      setIsAudioPlaying(false);
    }
  }

  const handleClose = () => {
    setSnackBarOpen(false);
  };

  const handleModalClose = () => {
    setIsResultsModalVisible(false);
  };

  const submitQuiz = () => {

    if (selectedAnswers.length !== filteredQuestions.length) {
      setMessage("Не сте попълнили всички въпроси");
      setSeverity('error');
      setSnackBarOpen(true);
      return;
    }

    let myPoints = 0;

    for (let index = 0; index < filteredQuestions.length; index++) {
      let answerIndex = filteredQuestions[index].responses.split("\r\n").indexOf(selectedAnswers[index]);
      let points = filteredQuestions[index].points.split(",").map(x => Number(x));
      let currPoints = points[answerIndex];
      let max = Math.max(...points);
      myPoints += currPoints;
      switch (SKILLS[filteredQuestions[index].type]) {
        case SKILLS.SELF_AWARENESS:
          answersPoints.SELF_AWARENESS += currPoints;
          maxPointsArr.SELF_AWARENESS += max;
          break;

        case SKILLS.SOCIAL_AWARENESS:
          answersPoints.SOCIAL_AWARENESS += currPoints;
          maxPointsArr.SOCIAL_AWARENESS += max;
          break;

        case SKILLS.SELF_MANAGEMENT:
          answersPoints.SELF_MANAGEMENT += currPoints;
          maxPointsArr.SELF_MANAGEMENT += max;
          break;

        case SKILLS.RELATIONSHIP_SKILLS:
          answersPoints.RELATIONSHIP_SKILLS += currPoints;
          maxPointsArr.RELATIONSHIP_SKILLS += max;
          break;

        case SKILLS.RESPONSIBLE_DECISION_MAKING:
          answersPoints.RESPONSIBLE_DECISION_MAKING += currPoints;
          maxPointsArr.RESPONSIBLE_DECISION_MAKING += max;
          break;

        default:
          console.log("Problem", filteredQuestions[index].type, filteredQuestions[index].codeId);
          break;
      }

    }
    answersPoints.SELF_AWARENESS *= 100 / maxPointsArr.SELF_AWARENESS;
    answersPoints.SOCIAL_AWARENESS *= 100 / maxPointsArr.SOCIAL_AWARENESS;
    answersPoints.SELF_MANAGEMENT *= 100 / maxPointsArr.SELF_MANAGEMENT;
    answersPoints.RELATIONSHIP_SKILLS *= 100 / maxPointsArr.RELATIONSHIP_SKILLS;
    answersPoints.RESPONSIBLE_DECISION_MAKING *= 100 / maxPointsArr.RESPONSIBLE_DECISION_MAKING;
    let res = { points: myPoints, data: answersPoints, max: maxPoints }
    setResults(res);
    setIsResultsModalVisible(true);

  }

  function TransitionUp(props) {
    return <Slide {...props} direction="down"><Alert variant="filled" severity={severity} sx={{ width: '100%' }}>
      {message}</Alert></Slide>;
  }

  return (
    <Layout footer={false}>
      <ResultsModal
        open={isResultModalVisible}
        onClose={handleModalClose}
        results={results}

      />

      <Snackbar
        autoHideDuration={3000}
        open={open}
        onClose={handleClose}
        TransitionComponent={TransitionUp}
        message={message}
        transitionDuration={500}
        anchorOrigin={{ vertical: "top", horizontal: "left" }}

      />

      <>
        <QuizHeader
          question={filteredQuestions[activeStep]}
          index={activeStep}
          isAudioPlaying={isAudioPlaying}
          setIsAudioPlaying={setIsAudioPlaying}
          audio={audio}
          setAudio={setAudio}
        />
        <QuestionView
          steps={filteredQuestions.length}
          selectedAnswers={selectedAnswers}
          question={filteredQuestions[activeStep]}
          activeStep={activeStep}
          setActiveStep={setActiveStep}
          submitQuiz={submitQuiz}
          isAudioPlaying={isAudioPlaying}
          setIsAudioPlaying={setIsAudioPlaying}
          audio={audio}
          setAudio={setAudio}
        />
        <QuizNavigation
          steps={filteredQuestions.length}
          activeStep={activeStep}
          setActiveStep={setActiveStep}
        />
      </>

    </Layout>
  )

}


export default Demo;
import {
  UPDATE_IS_CREATE_CLASS_MODAL_VISIBLE,
  UPDATE_IS_EDIT_CLASS_MODAL_VISIBLE,
  UPDATE_IS_CREATE_SESSION_MODAL_VISIBLE,
  UPDATE_IS_SNACKBAR_OPEN,
  UPDATE_TAB,
  UPDATE_SELECTED_CLASS,
} from "./action-types";

export const localInitialState = {
  tab: 0,
  isCreateClassModalVisible: false,
  isEditClassModalVisible: false,
  selectedClass: {name:"", students: []},
  isCreateSessionModalVisible: false,
  isSnackbarOpen: false,
};

export const localReducer = (state, action) => {
  if (!action || !action.hasOwnProperty("payload")) {
    return state;
  }

  const { payload } = action;

  switch (action.type) {
    case UPDATE_TAB:
      return { ...state, tab: payload };
    case UPDATE_IS_SNACKBAR_OPEN:
      return { ...state, isSnackbarOpen: payload };
    case UPDATE_IS_CREATE_CLASS_MODAL_VISIBLE:
      return { ...state, isCreateClassModalVisible: payload };
    case UPDATE_IS_EDIT_CLASS_MODAL_VISIBLE:
      return { ...state, isEditClassModalVisible: payload };
    case UPDATE_SELECTED_CLASS:
      return { ...state, selectedClass: payload };
    case UPDATE_IS_CREATE_SESSION_MODAL_VISIBLE:
      return { ...state, isCreateSessionModalVisible: payload };
    default:
      return state;
  }
};

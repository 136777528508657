import React from 'react';
import { Container, Typography, Accordion, AccordionSummary, AccordionDetails, useMediaQuery, useTheme } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Timeline, TimelineItem, TimelineSeparator, TimelineConnector, TimelineContent, TimelineDot } from '@mui/lab';

const EventProgram = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const renderTimelineItem = (time, title, details, listNames, moderator) => (
    <TimelineItem sx={{ '&::before': isMobile ? { display: 'none' } : { content: '""""' } }}>
      <TimelineSeparator >
        <TimelineDot sx={{ display: isMobile ? 'none' : 'block' }} />
        <TimelineConnector sx={{ display: isMobile ? 'none' : 'block' }} />
      </TimelineSeparator>
      <TimelineContent>
        {isMobile ? (
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>{time} - {title}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>{details}</Typography>
              <ul>
                {listNames?.map((el, idx) => {
                  return <p key={idx}>{el}</p>
                })}
              </ul>
              {moderator ? <Typography>{moderator}</Typography> : null}
            </AccordionDetails>
          </Accordion>
        ) : (
          <>
            <Typography variant="h5" component="span">{time}</Typography>
            <Typography variant="h6">{title}</Typography>
            <Typography>{details}</Typography>
            <ul>
              {listNames?.map((element, idx) => {
                return <p key={idx}>{element}</p>
              })}
            </ul>
            {moderator ? <Typography>{moderator}</Typography> : null}
          </>
        )}
      </TimelineContent>
    </TimelineItem>
  );

  return (
    <>
      <Typography>
        Хибридна среща с присъствено участие на панелистите
      </Typography>
      <Typography variant="h4" component="h2" gutterBottom align="center">
        Програма
      </Typography>
      <Typography>
        Водещ на събитието: г-жа Елена Розберг, радио и телевизионен водещ
      </Typography>
      <Timeline position={isMobile ? "left" : "alternate-reverse"}>
        {renderTimelineItem(
          "11:00-11:30",
          "Регистрация",
          ""
        )}
        {renderTimelineItem(
          "11:30-11:40",
          "Музикално изпълнение",
          "камерен хор “Нови български гласове”"
        )}
        {renderTimelineItem(
          "11:40-12:00",
          "Откриване и приветствия",
          "",
          ["Обръщение от г-н Димитър Главчев, Министър-председател на Р. България (очаква се потвърждение)", "Обръщение от г-жа Илияна Йотова, Вицепрезидент на Р. България", "Обръщение г-н Димитър Цоцорков, председатeл на Надзорния съвет на „Асарел-Медет“АД  и член на Управителния съвет на фондация „Лъчезар Цоцорков”", "Обръщение от проф. д-р Галин Цоков, министър на образованието и науката"]
        )}
        {renderTimelineItem(
          "12:00-12:30",
          "Международно оповестяване",
          "на изследването на ОИСР на социално-емоционалните умения на учениците, 2023г. ",
          ["г-н. Андреас Шлайхер, Директор на департамент “Образование и умения”, Организация за икономическо сътрудничество и развитие (ОИСР) "]
        )}
        {renderTimelineItem(
          "12:30-13:00",
          "Главни изводи за филантропските организации и бизнес сектора",
          "",
          ["г-н Робърт Добъл, директор сектор “Изграждане на бъдещи поколения”, филантропска мрежа “Портикъс”", "г-н Димитър Цоцорков, председатeл на Надзорния съвет на „Асарел-Медет“АД  и член на Управителния съвет на фондация „Лъчезар Цоцорков“", "г-н Добромир Иванов, изпълнителен директор, Българска предприемаческа асоциация"],
          "Модератор: г-жа Даринка Георгиева, изпълнителен директор на Мрежа на Глобалния договор на ООН за България"
        )}
        {renderTimelineItem(
          "13:00-14:15",
          "Пауза за обяд",
          "Пресконференция за български журналисти"
        )}
        {renderTimelineItem(
          "14:15-14:30",
          "Специална сесия, посветена на участието на Украйна в изследването",
          "г-н Олександър Елкин, основател и председател на борда на неправителствената организация “EdCamp Украйна”",
          ["г-н. Андреас Шлайхер, директор “Образование и умения”, ОИСР"]
        )}
        {renderTimelineItem(
          "14:30-15:15",
          "Панелна дискусия #1:",
          "От данните на изследването към политики",
          ["г-н Красимир Вълчев, Председател на Парламентарната комисия по образование и наука, Министър на Образованието и науката на България в периода 2017 - 2021", "г-жа Лина Бенете, главен изпълнителен директор на Think Equal - глобална инициатива за системна промяна в образованието", "г-н Шалейндра Шарма, бивш главен съветник на Директор Образование на Делхи, Индия", "др. Алия А. Самюел, Президент и изпълнителен директор на института Касел, САЩ"],
          "Модератор: г-н Андреас Шлайхер, Директор “Образование и умения”, ОИСР"
        )}
        {renderTimelineItem(
          "15:15-15:45",
          "Кафе пауза",
          ""
        )}
        {renderTimelineItem(
          "15:45-16:30",
          "Панелна дискусия #2:",
          "От данните на изследването към практиката",
          ["проф. Ракел Tейшейра, Секретар по образованието в щата Рио Гранде до Сул, Бразилия",
            "г-н Петри Лами, съветник по оценяване, отдел “Образование”, Хелзинки, Финландия",
            "др. Стефани Джоунс, директор “Лаборатория за екологични подходи към социално-емоционалното образование”, университет “Харвард”, САЩ",
            "г-жа Мила Иванова, старши експерт “Управление на съдържанието и развойна дейност, фондация “Заедно в час”"],
          "Модератор:  д-р Ноеми Ле Дон, Старши проектен мениджър ОИСР"
        )}
        {renderTimelineItem(
          "16:30",
          "Закриване",
          "г-жа Йоана Маринова, изпълнителен директор, фондация “Лъчезар Цоцорков”"
        )}
      </Timeline>
    </>
  );
};

export default EventProgram;

export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';

// Notification
export const SHOW_NOTIFICATION = 'SHOW_NOTIFICATION';
export const HIDE_NOTIFICATION = 'HIDE_NOTIFICATION';

export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const FORGOTTEN_PASSWORD_SUCCESS = 'FORGOTTEN_PASSWORD_SUCCESS';

// QUIZ actions
export const SUBMIT_QUIZ_SUCCESS = 'SUBMIT_QUIZ_SUCCESS';
export const FETCH_QUIZ_ANSWERS_SUCCESS = 'FETCH_QUIZ_ANSWERS_SUCCESS';
export const FETCH_QUIZ_ANSWER_SUCCESS = 'FETCH_QUIZ_ANSWER_SUCCESS';

// Class actions
export const ADD_CLASS_SUCCESS = 'ADD_CLASS_SUCCESS';
export const EDIT_CLASS_SUCCESS = 'EDIT_CLASS_SUCCESS';
export const DELETE_CLASS_SUCCESS = 'DELETE_CLASS_SUCCESS';

// Session actions
export const CREATE_SESSION_SUCCESS = 'CREATE_SESSION_SUCCESS';
export const EDIT_SESSION_SUCCESS = 'EDIT_SESSION_SUCCESS';
export const DELETE_SESSION_SUCCESS = 'DELETE_SESSION_SUCCESS';
export const FETCH_SESSION_SUCCESS = 'FETCH_SESSION_SUCCESS';
export const FETCH_SESSION_FAIL = 'FETCH_SESSION_FAIL';
export const FETCH_SESSIONS_SUCCESS = 'FETCH_SESSIONS_SUCCESS';

// School actions
export const FETCH_SCHOOLS_SUCCESS = 'FETCH_SCHOOLS_SUCCESS';
export const FETCH_SCHOOL_SUCCESS = 'FETCH_SCHOOL_SUCCESS';

// Admin actions
export const ACTIVATE_USER_SUCCESS = 'ACTIVATE_USER_SUCCESS';
export const FETCH_USERS_SUCCESS = 'FETCH_USERS_SUCCESS';



import React from 'react';
import { Container, Typography, Accordion, AccordionSummary, AccordionDetails, useMediaQuery, useTheme } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Timeline, TimelineItem, TimelineSeparator, TimelineConnector, TimelineContent, TimelineDot } from '@mui/lab';


const EventProgramEN = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const renderTimelineItem = (time, title, details, listNames, moderator) => (
    <TimelineItem sx={{ '&::before': isMobile ? { display: 'none' } : { content: '""""' } }}>
      <TimelineSeparator >
        <TimelineDot sx={{ display: isMobile ? 'none' : 'block' }} />
        <TimelineConnector csx={{ display: isMobile ? 'none' : 'block' }} />
      </TimelineSeparator>
      <TimelineContent>
        {isMobile ? (
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>{time} - {title}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>{details}</Typography>
              <ul>
                {listNames?.map((el, idx) => {
                  return <p key={idx}>{el}</p>
                })}
              </ul>
              {moderator ? <Typography>{moderator}</Typography> : null}
            </AccordionDetails>
          </Accordion>
        ) : (
          <>
            <Typography variant="h5" component="span">{time}</Typography>
            <Typography variant="h6">{title}</Typography>
            <Typography>{details}</Typography>
            <ul>
              {listNames?.map((element, idx) => {
                return <p key={idx}>{element}</p>
              })}
            </ul>

            {moderator ? <Typography>{moderator}</Typography> : null}
          </>
        )}
      </TimelineContent>
    </TimelineItem>
  );

  return (
    <>
      <Typography>
        Hybrid meeting with in-person attendance of all speakers
      </Typography>
      <Typography variant="h4" component="h2" gutterBottom align="center">
        Agenda
      </Typography>
      <Typography>
        Master of Ceremony: Mrs. Elena Rozberg, TV and radio presenter
      </Typography>
      <Timeline position={isMobile ? "left" : "alternate-reverse"}>
        {renderTimelineItem(
          "11:00-11:30",
          "Registration",
          ""
        )}
        {renderTimelineItem(
          "11:30-11:40",
          "Musical performance",
          "New Bulgarian Voices"
        )}
        {renderTimelineItem(
          "11:40-12:00",
          "Welcome and introductory remarks",
          "",
          ["Welcome by Mr. Dimitar Glavchev, Prime Minister  of the Republic of Bulgaria  (TBC)","Welcome by Mrs. Iliana Iotova, Vice President of the Republic of Bulgaria",

            "Introductory remarks by Mr. Dimitar Tsotsorkov, Chairman of the Management Board of Assarel-Medet JSC,Board member of Lachezar Tsotsorkov Foundation            ",
            "Welcome by Prof. Dr. Galin Tsokov, Minister of Education and Science of Bulgaria",]
        )}
        {renderTimelineItem(
          "12:00-12:30",
          "Global launch of the OECD’s 2023 Survey on Social and Emotional Skills",
          "Mr. Andreas Schleicher, OECD Director for Education and Skills          "
        )}
        {renderTimelineItem(
          "12:30-13:00",
          "Main take-aways according to philanthropic foundations and the business sector",
          "",
          ["Mr. Robert Doble, Director of Building Future Generations Sector, Porticus", "Mr. Dimitar Tsotsorkov, Chairman of the Management Board of Assarel-Medet JSC, Board member of Lachezar Tsotsorkov Foundation", "Mr. Dobromir Ivanov, BESCO "],
          "Moderator: Mrs. Darinka Georgieva, Executive director for Bulgaria, UN Global Compact          "
        )}
        {renderTimelineItem(
          "13:00-14:15",
          "Lunch Break",
          "Press conference with Bulgarian journalists"
        )}
        {renderTimelineItem(
          "14:15-14:30",
          "Special session dedicated to the participation of Ukraine in the SSES study",
          "Mr.  Oleksandr Elkin, Inspirer, Chair of the Board of “Ed Camp” Ukraine ",
          ["Мr. Andreas Schleicher, OECD Director for Education and Skills"]
        )}
        {renderTimelineItem(
          "14:30-15:15 ",
          "Panel discussion #1:",
          "From survey data to policy",
          ["Krasimir Valchev, Chairman of the Parliamentary Committee on Education and Science of Bulgaria, former Minister of Education and Science of Bulgaria 2017 - 2021",
            "Mrs. Lina Benete, CEO of Think Equal Global Initiative for a System Change in Education",
            "Mr. Shailendra Sharma, Former Principal Advisor to the Director of Education of Delhi, India",
            "Dr. Aaliyah A. Samuel, CASEL President and CEO",],
          "Moderator: Mr Andreas Schleicher, OECD Director for Education and Skills."
        )}
        {renderTimelineItem(
          "15:15-15:45",
          "Coffee break",
          ""
        )}
        {renderTimelineItem(
          "15:45-16:30",
          "Panel discussion #2:",
          "From survey data to practice",
          ["Prof. Raquel Teixeira, Secretary of Education in the State of Rio Grande do Sul, Brazil", "Mr. Petteri Lammi, Evaluation Advisor, Education Division City of Helsinki", "Dr. Stephanie Jones, Director of Harvard EASEL Lab", "Ms. Mila Ivanova, Senior Specialist in Development, “Teach for Bulgaria”"],
          "Moderator: Dr. Noémie Le Donné, OECD Senior Project Manager"
        )}
        {renderTimelineItem(
          "16:30",
          "Closing remarks",
          "Ms. Joanna Marinova, Executive Director of Tsotsorkov Foundation"
        )}
      </Timeline>
    </>
  );
};

export default EventProgramEN;

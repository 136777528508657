
// export const throttle = (funct, wait) => {
//     let isThrottled = false;

//     return () => {
//         if (!isThrottled) {
//             isThrottled = true;
//             setTimeout(() => isThrottled = false, wait);

//             return funct();
//         }
//     };
// };
export const handleFirebaseError = (code) => {
  let message = "Възникна неочаквана грешка";
  switch (code) {
    case "auth/wrong-password":
      message = "Грешна парола";
      break;
    case "auth/user-not-found":
      message = "Несъществуващ потребител";
      break;
    case "auth/email-already-in-use":
      message = "Имейлът вече е регистриран"
      break;
    case "auth/invalid-email":
      message = "Невалиден имейл адрес"
      break;
    case "auth/weak-password":
      message = "Паролата трябва да съдържа поне 6 символа";
      break;
    default:
      break;
  }

  return message;

}
import React from 'react';
import { connect } from 'react-redux';
import { getUserSchool } from 'reducers';
import { Box } from '@mui/material'
import './SchoolInfo.scss';


const SchoolInfo = ({
    school
}) => {

    return (

        <section className="teacher-panel-section">
            <Box sx={{ width: '100%' }}>
                {school.name}
            </Box>
            <Box sx={{ width: '100%' }}>
                област: {school.region}
            </Box>
            <Box sx={{ width: '100%' }}>
                населено място: {school.town}
            </Box>
            

        </section >

    )
}

const mapStateToProps = (state) => ({
    school: getUserSchool(state),
});

const mapDispatchToProps = {

};

export default connect(mapStateToProps, mapDispatchToProps)(SchoolInfo);
import {  db } from '../firebase.js';
import { collection, doc, addDoc, getDoc, getDocs, query, where, updateDoc, arrayUnion, deleteDoc } from 'firebase/firestore';

import {
    UPDATE_USER_SUCCESS,
    FETCH_SESSION_SUCCESS,
    FETCH_SESSIONS_SUCCESS,
    FETCH_SESSION_FAIL
} from './actionTypes';

// Simple Action Creators
const fetchSessionSuccess = (payload) => ({
    type: FETCH_SESSION_SUCCESS,
    payload,
});

const fetchSessionFail = () => ({
    type: FETCH_SESSION_FAIL
});

// Simple Action Creators
const fetchSessionsSuccess = (payload) => ({
    type: FETCH_SESSIONS_SUCCESS,
    payload,
});

const fetchCreateSessionSuccess = (payload) => ({
    type: UPDATE_USER_SUCCESS,
    payload,
});

const fetchDeleteSessionSuccess = (payload) => ({
    type: UPDATE_USER_SUCCESS,
    payload,
})

// Complex Action Creators
export const createSession = (sessionData) => async (dispatch) => {
    try {
        let session = await addDoc(collection(db, "sessions"), sessionData);
        const userDoc = await getDoc(doc(db, "users", sessionData.teacher));
        const userData = userDoc.data();
        userData.id = sessionData.teacher;
        let schoolDoc = await getDoc(doc(db, "schools", userData.school));
        let schoolData = schoolDoc.data();
        schoolData.id = userData.school;
        if (userData.roles.includes("ADMINISTRATOR")) {
            let teachersInSchool = await getDocs(query(collection(db, "users"), where("school", "==", userData.school)));
            let teachers = [];
            teachersInSchool.forEach(t => {
                teachers.push(t.data());
            });
            schoolData.teachers = teachers;
        }

        await updateDoc(doc(db, "users", sessionData.teacher), {
            sessions: arrayUnion(session.id)
        })

        let teacherSessions = await getDocs(query(collection(db, "sessions"), where("teacher", "==", userData.id)));
        let sessions = [];
        teacherSessions.forEach(session => {
            let sessionData = session.data();
            sessionData.id = session.id;
            sessions.push(sessionData);
        });

        let user = {
            id: userData.id,
            email: userData.email,
            roles: userData.roles,
            classes: userData.classes,
            school: schoolData,
            sessions: sessions,
            firstName: userData.firstName,
            lastName: userData.lastName,
            active: userData.active,
        };

        dispatch(fetchCreateSessionSuccess(user));

    } catch (error) {
        throw error;
    }
};

export const deleteSession = (userId, sessionId) => async (dispatch) => {
    try {

        const userRef = doc(db, "users", userId);
        let updatedUser = await getDoc(userRef);
        let userData = updatedUser.data();
        userData.id = userId;
        let newSessions = userData.sessions.filter(s => s != sessionId);
        await updateDoc(userRef, {
            sessions: newSessions
        })

        await deleteDoc(doc(db, "sessions", sessionId));

        if (userData.roles.includes("ADMINISTRATOR")) {
            let schoolDoc = await getDoc(doc(db, "schools", userData.school));
            let schoolData = schoolDoc.data();
            schoolData.id = userData.school;
            let teachersInSchool = await getDocs(query(collection(db, "users"), where("school", "==", userData.school)));
            let teachers = [];
            teachersInSchool.forEach(t => {
                teachers.push(t.data());
            });
            schoolData.teachers = teachers;
            userData.school = schoolData;
        }

        let teacherSessions = await getDocs(query(collection(db, "sessions"), where("teacher", "==", userData.id)));
        let sessions = [];
        teacherSessions.forEach(session => {
            let sessionData = session.data();
            sessionData.id = session.id;
            sessions.push(sessionData);
        });
        userData.sessions = sessions;
        // const response = await sessionService.deleteSession(sessionId);
        dispatch(fetchDeleteSessionSuccess(userData));
    } catch (error) {
        throw error;
    }
};

export const fetchSession = (sessionId) => async (dispatch) => {
    try {
        let sessionDoc = await getDoc(doc(db, "sessions", sessionId));
        let session = sessionDoc.data();
        let teacherDoc = await getDoc(doc(db, "users", session.teacher));
        session.teacher = teacherDoc.data();
        session.teacherId = teacherDoc.id;
        session.id = sessionDoc.id;

       
        dispatch(fetchSessionSuccess(session));
    } catch (error) {
        dispatch(fetchSessionFail());
    }
};

export const fetchSessions = () => async (dispatch) => {
    try {
        let sessionsDocs = await getDocs(collection(db, "sessions"));
        let sessions = [];
        sessionsDocs.docs?.forEach(async (x) => {
            let sessionData = x.data();
            let teacher = await getDoc(doc(db, "users", sessionData?.teacher));
            sessionData.teacherId = sessionData.teacher;
            sessionData.teacher = teacher.data();
            sessionData.id = x.id;
            sessions.push(sessionData);
        })
        dispatch(fetchSessionsSuccess(sessions));
    } catch (error) {
        throw error;
    }
};






import React, { useState } from 'react';
import {
  Grid, Card, CardContent, CardActions, Button, Typography, Avatar, Collapse
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const PanelistCard = ({ panelist }) => {
  const [expanded, setExpanded] = useState(false);
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Card>
      <CardContent>
        <Avatar
          alt={panelist.name}
          src={panelist.image}
          sx={{ width: 200, height: 200, margin: 'auto' }}
        />
        <br></br>
        <Typography gutterBottom align="center" variant="h5" component="div">
          {panelist.name}
        </Typography>
        <Typography variant="body2" align="center" minHeight={80} color="text.secondary">
          {panelist.role}
        </Typography>
        <Typography variant="body2" color="text.secondary">
        {panelist.large ? panelist.bio.substring(0, 250) : panelist.bio.substring(0, 150)}...
        </Typography>

      </CardContent>
      <CardActions disableSpacing>
        <Button onClick={handleExpandClick} endIcon={<ExpandMoreIcon />}>
          {expanded ? 'Скрий' : 'Повече'}
        </Button>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <Typography paragraph>{panelist.sections ? "" : panelist.bio}</Typography>
          {panelist.websites?.map((url, idx) => (
            <Typography key={idx} variant="body2">
              <a href={url} target="_blank" rel="noopener noreferrer">{url}</a>
            </Typography>
          ))}
          {panelist.sections?.map((section, idx) => {
            return (<>
              <Typography key={idx} variant="body1" color="text.primary">{section.title}</Typography>
              <Typography key={idx} variant="body1" color="text.primary">{section.content}</Typography>
              <ul>
                {section.list?.map((item, idx) => {
                  return <li>{item}</li>
                })}
              </ul>
              <br></br>
            </>)

          })}
        </CardContent>
      </Collapse>
    </Card>
  );
};


export default PanelistCard;

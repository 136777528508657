
import { Link } from "react-router-dom";

import Layout from 'components/Layout';

import { ReactComponent as Kids } from './assets/kids.svg';
import { ReactComponent as Kindergarden } from './assets/kindergarden.svg';
import { ReactComponent as Teddy } from './assets/teddy.svg';
import infoCasel from './assets/CASEL.jpg';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { Typography } from '@mui/material';
import {
    Paper,
} from '@mui/material';

import './Home.scss';
import HomeInformation from './HomeInformation';
import { info } from 'sass';


const Evaluation = () => {
    return (
        <Layout>
            <section className="home-section">
                <Paper elevation={12}>
                    <div className="home-container container">
                        <div className="home-info">
                            <h1>Оценка</h1>
                            <h4>За Инструмента</h4>
                            <p>Инструментът за директна оценка на социално емоционалните умения при деца от 6 до 10 години е една от разработките на Фондация “Лъчезар Цоцорков” в осъществяване на мисията за развиване на социално-емоционалното образование в България (СЕО).</p>
                            <br />
                            <h4>Защо е важен този инструмент?</h4>
                            <p>Оценката на социално-емоционалните умения (СЕУ) е необходимата първа стъпка за идентифициране на дефицитите и определяне на интервенциите и средствата за тяхното компенсиране.

                                Резултатите от неговото ползване ще ни позволят да научим от какво имат нужда нашите деца, за да се развиват пълноценно в динамично променящите се условия на съвременния свят.
                            </p>
                            <br />
                            <h4>Разработка</h4>
                            <p>Разработката на инструмент за директна оценка е пионерско начинание за нашата страна. Освен това ни поставя сред първите в света, които насочват усилия в посока приоритизиране на СЕО като част от националните образователни политики и предприемане на практически стъпки за обезпечаване на процеса.

                                Сходни инструменти разбира се съществуват - например, проучването на социално-емоционалните умения (СЕУ) на 15 годишните ученици, осъществявано от Организацията за икономическо сътрудничество и развитие (ОИСР). Към 2023 г. едва 17 държави в света са взели участие в това проучване - България е една от тях, което отново подчертава навременността на настоящата разработка.
                            </p>
                            <br />
                            <h4>Качество</h4>
                            <p>Качеството на настоящия инструмент се гарантира от два ключови критерия, които бяха поставени в началото на неговото създаване:

                                Разработката е съобразена със спецификата на нашата страна и е направена от водещи специалисти в областта на оценяването на социално-емоционалните умения;

                                Разработката е специално съобразена с възрастовата група 1-4 клас.
                            </p>
                            <br />
                            <h4>Какво представлява инструментът?</h4>
                            <p>Добре дошли в приложението за директна оценка на социално-емоционалните умения на учениците в 1-4 клас. Приложението представя ситуации, които пресъздават моменти от живота на най-малките ученици, а решенията, които те самостоятелно взимат преминавайки през тези ситуации, ни помагат да определим нивото им спрямо различни социално-емоционални компетентности.</p>
                            <br />
                            <Link to="/demo-boy">Демо момче</Link>
                            <br />
                            <Link to="/demo-girl">Демо момиче</Link>
                        </div>
                    </div>
                </Paper>




            </section>
        </Layout>
    )
}

export default Evaluation;
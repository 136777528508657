import React, { useEffect } from 'react';
import { Typography, Paper, Link, Button, Grid, List, ListItem, ListItemText } from '@mui/material';
import eventBanner from "./oecdBanner.jpg";
import EventProgramEN from './EventProgram/EventProgramEN';
import PanelistsEN from './Panelists/PanelistsEN';
const EventDetailsEn = () => {
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, []);
  return (
    <Paper style={{ padding: '20px', margin: '20px' }}>
      <Typography variant="h4" gutterBottom>
        Global Launch of the OECD’s 2023 Survey on Social and Emotional Skills
      </Typography>
      <img style={{ maxWidth: "100%" }} src={eventBanner} alt={"img"} />
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Typography variant="h6">Date & Time</Typography>
          <Typography>April 26th from 11:00am to 4:45pm Local time</Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="h6">Location</Typography>
          <Typography>Hilton Hotel, Sofia, Bulgaria</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6">Organized by</Typography>
          <Typography>OECD and the “Lachezar Tsotsorkov” Foundation</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6">About the Event</Typography>
          <Typography>
            The event will mark the global launch of the new OECD report, Social and Emotional Skills for Better Lives, presenting findings from the largest survey of its type on the social and emotional skills of students.
          </Typography>
          <Typography>
            During the event, international experts, practitioners, policymakers and industry representatives will be invited to explore the current state social and emotional development of students and identify ways to translate the findings of the report into policy and practice within classrooms and schools. The conference will also include a session on the implications of the report’s findings on the business and philanthropic sectors. <a href="https://www.oecd.org/education/ceri/social-emotional-skills-study/" target="_blank">Learn more about the OECD survey</a>.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6">Objectives of the Survey</Typography>
          <List>
            <ListItem>
              <ListItemText primary="Provide participating cities and countries with information on their students' social and emotional skills." />
            </ListItem>
            <ListItem>
              <ListItemText primary="Provide insights as to how students’ social and emotional skills relate to key life outcomes." />
            </ListItem>
            <ListItem>
              <ListItemText primary="Identify factors in students' home, school, and peer environments that promote or hinder the development of social and emotional skills." />
            </ListItem>
            <ListItem>
              <ListItemText primary="Explore how broader policy, cultural, and socio-economic contexts influence these skills." />
            </ListItem>
          </List>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6">Access</Typography>
          <Typography>
          The Global Launch will be livestreamed on this site. After registration, participants from all over the world may join via Twitch or watch on our live stream:  <a href="/live">sel.bg/live</a>
          </Typography>
         
          <Typography>In person participants can attend by invitation due to limited space and after registration. Please note that travel and accommodations costs will not be covered.
          </Typography>
          <a href="/registration-oecd-en.html">
            <Button variant="contained" color="primary" style={{ marginTop: '10px' }}>
              Register Now
            </Button>
          </a>
        </Grid>
        <Grid item xs={12}>
          <EventProgramEN></EventProgramEN>
        </Grid>
        <Grid item xs={12}>
          <PanelistsEN></PanelistsEN>
          <br></br>
          <Typography>On site, the guests will have the opportunity to look at an exhibition of the students from the National School of Applied Arts ‘St. Luke’ – Sofia, Bulgaria. The exhibition will represent the eight majors of the school: Artistic textile, Graphic design, Spatial design, Silicate design, Children's toys and souvenirs design, Artistic woodwork, Artistic metalwork and Fashion design.</Typography>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default EventDetailsEn;

import { connect } from "react-redux";
import { getUser, isAuthenticated, isAdmin } from "reducers";

import { addClass, editClass, deleteClass } from "actions/authActions";
import { createSession, deleteSession } from "actions/sessionActions";

import TeacherPanel from "./TeacherPanel";

const mapStateToProps = (state) => ({
  isAuthenticated: isAuthenticated(state),
  isAdmin: isAdmin(state),
  user: getUser(state),
});

const mapDispatchToProps = {
  addClass,
  editClass,
  deleteClass,
  createSession,
  deleteSession,
};

export default connect(mapStateToProps, mapDispatchToProps)(TeacherPanel);

import { db } from '../firebase.js';
import { collection, doc, getDoc, getDocs } from 'firebase/firestore';

import {
    FETCH_SCHOOLS_SUCCESS,
} from './actionTypes';

// Simple Action Creators
const fetchSchoolsSuccess = (payload) => ({
    type: FETCH_SCHOOLS_SUCCESS,
    payload,
});

export const fetchSchools = () => async (dispatch) => {
    try {
        const schools = await getDocs(collection(db, "schools"));
        let data = [];
        schools.docs.forEach(schoolDoc => {
            let schoolData = schoolDoc.data();
            schoolData.id = schoolDoc.id;
            let teachers = [];
            schoolData.teachers.forEach(async (t) => {
                let teacher = await getDoc(doc(db, "users", t));
                let teacherData = teacher.data();
                teacherData.id = t;

                let sessions = [];
                teacherData.sessions.forEach(async (s) => {
                    let session = await getDoc(doc(db, "sessions", s));
                    let sessionData = session.data();
                    sessionData.id = s;
                    sessions.push(sessionData);
                })
                teacherData.sessions = sessions;

                teachers.push(teacherData);
            });
            schoolData.teachers = teachers;
            data.push(schoolData);

        });
        dispatch(fetchSchoolsSuccess(data));
    } catch (error) {
        throw error;
    }
};







import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { getUser, getUsers, getAnswers } from 'reducers';
import { activateUser, fetchUsers } from 'actions/authActions';
import { fetchQuizAnswers } from 'actions/quizActions';
import { SKILLS_TEXTS } from "constants";
import { styled } from "@mui/material/styles";
import * as XLSX from 'xlsx';

import {
    Typography,
    Slide,
    Alert,
    Button,
    Grid,
    Tabs,
    Tab,
    Snackbar,
    Icon,
    Box,
    Paper,
    Modal,
    useTheme,
    useMediaQuery,
    IconButton,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Divider,
} from "@mui/material";
import PropTypes from 'prop-types';
import Layout from 'components/Layout'
import TeacherCard from 'components/TeacherCard';
import SchoolInfo from 'components/SchoolInfo';

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import PrintIcon from '@mui/icons-material/Print';
import CloseIcon from '@mui/icons-material/Close';

const Administration = ({
    user,
    answers,
    fetchQuizAnswers,
    fetchUsers,
    activateUser,
    users,
}) => {
    const [areAnswersLoading, setAreAnswersLoading] = useState(true);
    const [value, setValue] = React.useState(0);
    const [resultsByClass, setResultsByClass] = useState(null);
    const [schoolAnswers, setSchoolAnswers] = useState(null);
    const [open, setOpen] = useState(false);
    const [currentAnswer, setCurrentAnswer] = useState(null);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const handleTabChange = (event, newValue) => {
        setValue(newValue);
    };

    const [areUsersLoading, setAreUsersLoading] = useState(true);
    const [newUsers, setNewUsers] = useState([]);

    const Item = styled(Paper)(({ theme }) => ({
        backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
        ...theme.typography.body2,
        padding: theme.spacing(1),
        textAlign: "center",
        color: theme.palette.text.secondary,
    }));

    function activate(userId) {
        activateUser(userId);
        setNewUsers(newUsers.filter(x => x.id != userId));
    }
    const generateExcel = () => {
        const ws = XLSX.utils.json_to_sheet([
            ...currentAnswer.answersPoints.slice(1).map((point, idx) => ({
                '#': idx + 1,
                'отговор': currentAnswer.selectedAnswers[idx + 1],
                'точки': point,
                'време': `${currentAnswer.answersTime[idx + 1]}s`
            }))
        ]);

        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Отговори");

        XLSX.writeFile(wb, `${currentAnswer.student.name}-${currentAnswer.year}-${currentAnswer.type}.xls`);
    };

    function TransitionUp(props) {
        return <Slide {...props} direction="up"><Alert variant="filled" severity="success" sx={{ width: '100%' }}>
            Успешно добавихте класа си!
        </Alert></Slide>;
    }
    function TabPanel(props) {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box sx={{ p: 3 }}>
                        <Typography>{children}</Typography>
                    </Box>
                )}
            </div>
        );
    }

    TabPanel.propTypes = {
        children: PropTypes.node,
        index: PropTypes.number.isRequired,
        value: PropTypes.number.isRequired,
    };

    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    useEffect(() => {
        fetchQuizAnswers().then(() => {
            setAreAnswersLoading(false);
        })

        fetchUsers().then(() => {
            setAreUsersLoading(false);
        })
    }, []);

    useEffect(() => {
        setNewUsers(users.filter(x => x.active == false && x.school == user.school.id));
    }, [users])

    useEffect(() => {
        if (!areAnswersLoading) {
            let filteredBySchoolId = Object.values(answers).filter(x => x.schoolId === user.school.id);
            setSchoolAnswers(filteredBySchoolId);
        }
    }, [answers, areAnswersLoading]);


    useEffect(() => {
        if (schoolAnswers) {
            let groupedBySessionId = groupBy(schoolAnswers, "sessionId");
            let answersData = [];
            Object.keys(groupedBySessionId).forEach(s => {
                let groupedByClass = groupBy(groupedBySessionId[s], "classId");

                let groupedClasses = Object.values(groupedByClass);
                let classesData = [];
                groupedClasses.forEach(cl => {
                    let filteredTeacher = user.school.teachers.filter(t => t.email === cl[0].teacher?.email)[0];
                    if (filteredTeacher) {
                        let userData = filteredTeacher.classes.filter(c => c.id === cl[0].id)[0];
                        let classObj = {
                            id: cl[0].classId,
                            name: "",
                            answers: cl,
                        }
                        classesData.push(classObj);
                    }

                })


                let data = {
                    year: groupedBySessionId[s][0].year,
                    type: groupedBySessionId[s][0].type,
                    sessionId: s,
                    classes: classesData
                }



                answersData.push(data)

            })

            setResultsByClass(answersData);
        }
    }, [schoolAnswers]);

    var groupBy = function (xs, key) {
        return xs.reduce(function (rv, x) {
            (rv[x[key]] = rv[x[key]] || []).push(x);
            return rv;
        }, {});
    };
    const handleDetailsOpen = (answer) => {
        setCurrentAnswer(answer);
        setOpen(true);
    };

    const handleDetailsClose = () => {
        setOpen(false);
    };

    return (

        <Layout>
            <section className="teacher-panel-section">
                <Box sx={{ width: '100%' }}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={value} onChange={handleTabChange} aria-label="basic tabs example">
                            <Tab label="Училище" {...a11yProps(0)} />
                            <Tab label="Учители" {...a11yProps(1)} />
                            {/* <Tab label="Резултати" {...a11yProps(2)} /> */}
                            <Tab label="Заявки за одобрение" {...a11yProps(3)} />
                        </Tabs>
                    </Box>
                    <TabPanel value={value} index={0}>
                        <SchoolInfo></SchoolInfo>
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <Grid container
                            direction={{ xs: 'column', sm: 'row' }}
                            spacing={{ xs: 1, sm: 2, md: 4 }}
                        >
                            {user.school.teachers.map((t, idx) => {
                                return (
                                    <TeacherCard key={t._id} teacher={t} editMode={false} />
                                )
                            })}
                        </Grid>
                    </TabPanel>
                    {/* <TabPanel value={value} index={2}>
                        Резултати
                        {
                            false ?
                                resultsByClass ?
                                    resultsByClass.map((s) => {
                                        return (<

                                            Grid item xs={4} key={s.sessionId}>
                                            <Item>
                                                {s.year} {s.type}
                                            </Item>
                                            <Accordion>
                                                <AccordionSummary
                                                    expandIcon={<ExpandMoreIcon />}
                                                    aria-controls="panel1a-content"
                                                    id="panel1a-header"
                                                >
                                                    <Typography>класове</Typography>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    {s.classes?.map((cl) => {
                                                        return cl ? (
                                                            <Grid item xs={12} key={cl.id}>
                                                                <Item>
                                                                    <>{cl.name}</>
                                                                </Item>
                                                                <Accordion>
                                                                    <AccordionSummary
                                                                        expandIcon={<ExpandMoreIcon />}
                                                                        aria-controls="panel1a-content"
                                                                        id="panel1a-header"
                                                                    >
                                                                        ученици
                                                                    </AccordionSummary>
                                                                    <AccordionDetails>
                                                                        <Box>
                                                                            <Grid container spacing={2} alignItems="center" style={{ marginLeft: 0 }}>
                                                                                <Grid item xs={2}>
                                                                                    {"#"}
                                                                                </Grid>

                                                                                <Grid item xs={2}>
                                                                                    {"Име"}
                                                                                </Grid>

                                                                                <Grid item xs={2}>
                                                                                    {"Време(сек)"}
                                                                                </Grid>
                                                                            </Grid>
                                                                            <Divider></Divider>
                                                                        </Box>
                                                                        {cl.answers.map((answer) => {
                                                                            return (
                                                                                <Box>
                                                                                    <Grid key={answer.id} container spacing={2} alignItems="center" style={{ margin: 0 }}>
                                                                                        <Grid item xs={2} style={{ padding: 8 }}>
                                                                                            {answer.student.number}{" "}
                                                                                        </Grid>

                                                                                        <Grid item xs={2} style={{ padding: 8 }}>
                                                                                            {answer.student.name}{" "}
                                                                                        </Grid>

                                                                                        <Grid item xs={2} style={{ padding: 8 }}>
                                                                                            {answer.totalTime}{" s"}
                                                                                        </Grid>
                                                                                        <Grid item xs={2} style={{ padding: 8 }}>
                                                                                            {answer.points}{" / "}{answer.max}
                                                                                        </Grid>
                                                                                        <Grid item style={{ padding: 8 }}>
                                                                                            <Button variant="outlined" color="primary" onClick={() => handleDetailsOpen(answer)}>Детайли</Button>
                                                                                        </Grid>
                                                                                    </Grid>
                                                                                    <Divider></Divider>
                                                                                </Box>

                                                                            );
                                                                        })}
                                                                        {currentAnswer ?
                                                                            <Modal
                                                                                open={open}
                                                                                onClose={handleDetailsClose}
                                                                                aria-labelledby="student-details-modal"
                                                                                aria-describedby="modal-to-display-student-answer-details"
                                                                            >
                                                                                <Box
                                                                                    style={{
                                                                                        margin: 'auto',
                                                                                        width: isMobile ? '80%' : '60%',
                                                                                        backgroundColor: 'white',
                                                                                        padding: '20px',
                                                                                        overflowY: 'auto',
                                                                                        borderRadius: '8px'
                                                                                    }}
                                                                                >
                                                                                    <Box display="flex" justifyContent="space-between" alignItems="center">
                                                                                        <Box display="flex" alignItems="center">
                                                                                            <Typography variant="h6">Повече информация: {currentAnswer.student.name}</Typography>
                                                                                            <IconButton onClick={generateExcel}>
                                                                                                <PrintIcon />
                                                                                            </IconButton>
                                                                                        </Box>
                                                                                        <IconButton onClick={handleDetailsClose}>
                                                                                            <CloseIcon />
                                                                                        </IconButton>
                                                                                    </Box>

                                                                                   
                                                                                    <Box mb={3}>
                                                                                        {Object.entries(currentAnswer.pointsByType).map(([key, value]) => (
                                                                                            <Typography key={key} variant="body1">{SKILLS_TEXTS[key]}: {value.toFixed(2)}%</Typography>
                                                                                        ))}
                                                                                        <Typography variant="body1">{"Време"}: {Math.floor(currentAnswer.totalTime / 60)}мин {currentAnswer.totalTime % 60}сек</Typography>
                                                                                    </Box>

                                                                                    <Typography variant="h6">Отговори на въпроси</Typography>

                                                                                    {currentAnswer && (
                                                                                        <Grid
                                                                                            container
                                                                                            spacing={2}
                                                                                            wrap="wrap"
                                                                                            alignItems="center"
                                                                                            className="scrollable"
                                                                                            style={{
                                                                                                margin: 0,
                                                                                                maxHeight: '400px',
                                                                                                overflowY: 'scroll',
                                                                                                scrollbarWidth: 'thin',
                                                                                                scrollbarColor: '#888 #f5f5f5',

                                                                                            }}
                                                                                        >
                                                                                            <Table style={{ marginTop: 0 }}>
                                                                                                <TableHead>
                                                                                                    <TableRow>
                                                                                                        <TableCell>#</TableCell>
                                                                                                        <TableCell>Отговор</TableCell>
                                                                                                        <TableCell>Точки</TableCell>
                                                                                                        <TableCell>Време</TableCell>
                                                                                                    </TableRow>
                                                                                                </TableHead>
                                                                                                <TableBody>
                                                                                                    {currentAnswer.answersPoints.slice(1).map((point, idx) => (
                                                                                                        <TableRow
                                                                                                            key={idx}
                                                                                                            style={{
                                                                                                                backgroundColor: point === 0 ? '#f77b72' : point === 1 ? "#EDE0A6" : '#5e8c61',
                                                                                                                color: 'white'
                                                                                                            }}
                                                                                                        >
                                                                                                            <TableCell>{idx + 1}</TableCell>
                                                                                                            <TableCell>"{currentAnswer.selectedAnswers[idx + 1]}"</TableCell>
                                                                                                            <TableCell>{point}</TableCell>
                                                                                                            <TableCell>{currentAnswer.answersTime[idx + 1]}s</TableCell>
                                                                                                        </TableRow>
                                                                                                    ))}
                                                                                                </TableBody>
                                                                                            </Table>


                                                                                        </Grid>
                                                                                    )}
                                                                                </Box>
                                                                            </Modal>
                                                                            : null
                                                                        }
                                                                    </AccordionDetails>
                                                                </Accordion>
                                                            </Grid>
                                                        ) : null;
                                                    })}
                                                </AccordionDetails>
                                            </Accordion>
                                        </Grid>)
                                    })
                                    : null
                                : null
                        }
                    </TabPanel> */}
                    <TabPanel value={value} index={3}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Имейл</TableCell>
                                    <TableCell>Имена</TableCell>
                                    <TableCell>Община</TableCell>
                                    <TableCell>Град</TableCell>
                                    <TableCell>Училище</TableCell>
                                    <TableCell>Действия</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {newUsers.map((user) => {
                                    const [region, town, schoolName] = user.school.split('|');
                                    return (<TableRow key={user.id}>
                                        <TableCell>{user.email}</TableCell>
                                        <TableCell>{user.firstName} {user.lastName}</TableCell>
                                        <TableCell>{region}</TableCell>
                                        <TableCell>{town}</TableCell>
                                        <TableCell>{schoolName}</TableCell>
                                        <TableCell>
                                            {/* Button to activate */}
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={() => activate(user.id)}
                                            >
                                                Одобри
                                            </Button>
                                        </TableCell>
                                    </TableRow>)
                                })}
                            </TableBody>
                        </Table>
                    </TabPanel>
                </Box>

            </section >
        </Layout>
    )


};
const mapStateToProps = (state) => ({
    user: getUser(state),
    answers: getAnswers(state),
    users: getUsers(state),
});

const mapDispatchToProps = {
    fetchQuizAnswers,
    fetchUsers,
    activateUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(Administration);
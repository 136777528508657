import { useState } from 'react';
import Layout from "components/Layout";
import {
  Paper,
  Tabs,
  Tab,
  Box,
  Typography
} from '@mui/material';

import PropTypes from 'prop-types';

// import { ReactComponent as Children } from './assets/children.svg';
import { ReactComponent as Kids } from './assets/kids.svg';
import { ReactComponent as Kindergarden } from './assets/kindergarden.svg';
import { ReactComponent as Teddy } from './assets/teddy.svg';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { styled } from '@mui/material/styles';

import './About.scss';

const AboutUs = () => {

  const AntTabs = styled(Tabs)({
    borderBottom: '1px solid #e8e8e8',
    '& .MuiTabs-indicator': {
      backgroundColor: '#1890ff',
    },
  });

  const AntTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
    textTransform: 'none',
    minWidth: 0,
    [theme.breakpoints.up('sm')]: {
      minWidth: 0,
    },
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(1),
    color: 'rgba(0, 0, 0, 0.85)',
    fontSize: '1rem',
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:hover': {
      color: '#40a9ff',
      opacity: 1,
    },
    '&.Mui-selected': {
      color: '#1890ff',
      fontWeight: theme.typography.fontWeightMedium,
    },
    '&.Mui-focusVisible': {
      backgroundColor: '#d1eaff',
    },
  }));


  const [value, setValue] = useState(0);
  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  return (
    <Layout>
      <section className="about-section">
        <Paper elevation={12}>
          <div className="about-container container">
            <div className="quote">
              <h1>“Моментът, в който осъзнаем, че личността не е нещо наготово, а е нещо, което се създава, чрез всекидневните избори и действия, всичко ни се изяснява”.
              </h1>
              <h2>Джон Дюи</h2>
            </div>
          </div>

        </Paper>

        <Paper elevation={12}>
          <div className="about-container container">
            <div className="about-info">
              <p style={{display:"block"}}>Семейната <a target="blank" href="https://tsotsorkovfoundation.bg/">Фондация „Лъчезар Цоцорков”</a> е създадена в чест на вдъхновяващия лидер и голям българин, който вярваше, че смисълът на човешкия живот е да оставим света по-добър след себе си.</p>
              <br/>
              <p>Основана през 2019 г., частната фондация работи с мисията да допринася за благополучието на българското общество, чрез подкрепа за културата и развитие, насърчаване на иновациите и постиженията в образованието и здравеопазването, както и чрез подпомагане на деца в неравностойно положение</p>
              <br/>
              <p>В направление “Образование” от 2020 г. работим приоритетно в сферата на социално-емоционалното образование (СЕО).  След получена подкрепа от Министерство на образованието и науката, финансирахме участието на България в провежданото,от ОИСР на първо по рода си, международно проучване на социално-емоционалните умения на 15-годишните ученици в над двадесет държави от четири континента.</p>
              <br/>
              <p>Междувременно, разработихме система за социално-емоционално образование за деца 1-4 клас и обучения за учители, отново подкрепена от МОН.</p>
              <br/>
              <p>До този момент сме обучавали и работили с над 600 учители, 4500 ученици и 12000 родители, в над 88 населени места в България, в партньорство с над 18 организации и специалисти в сферата.</p>
              <br/>
              <p>Програма за следдипломна квалификационна степен за социално-емоционално образование в партньорство с Пловдивски университет „Паисий Хилендарски“.</p>
              <br/>
              <p>Може да се свържете с нас на:<a href="mailto:sel@tsotsorkovfoundation.bg"> sel@tsotsorkovfoundation.bg</a></p>

            </div>
          </div>

        </Paper>

      </section>
    </Layout>
  )

}

export default AboutUs;
import { combineReducers } from 'redux';

import {
    FETCH_SESSION_SUCCESS,
    FETCH_SESSION_FAIL,
    FETCH_SESSIONS_SUCCESS,
} from '../actions/actionTypes';

const initialState = {
    _id: "",
    name: "",
    type: "",
    year: "",
    teacher: {},
    classes: [],
    results: [],
    isValid: false,
};

const sessions = (state = [], action) => {
    switch (action.type) {
        case FETCH_SESSIONS_SUCCESS:
            return { ...state, ...action.payload };
        default:
            return state;
    }
};

const session = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_SESSION_SUCCESS:
            return { ...action.payload, isValid: true };
        case FETCH_SESSION_FAIL:
            return { ...initialState, isValid: false };
        default:
            return state;
    }
};

export default combineReducers({
    list: sessions,
    current: session,
});
// User
export const getSession = (state) => state.current;
export const getSessions = (state) => state.list;

import React, { useState } from 'react';
import {
    Paper,
    Button,
    Grid,
    TextField,
} from '@mui/material';

import 'styles/Auth.scss';

const LoginFormView = ({
    onFormFinish,
    isCapslockOn,
    onKeyDownHandler
}) => {

    const [email, setEmailValue] = useState("");
    const [password, setPasswordValue] = useState("");
    const onEmailChange = (event) => setEmailValue(event.target.value);
    const onPasswordChange = (event) => setPasswordValue(event.target.value);

    const onSubmit = (event) => {
        event.preventDefault()
        onFormFinish({ email, password });
    }

    return (
        <Paper className="auth-form">

            <form onSubmit={onSubmit}>
                <h2>Влезте в своя профил</h2>
                <Grid
                    container
                    spacing={3}
                    direction={'column'}
                    justify={'center'}
                    alignItems={'center'}
                >
                    <Grid item xs={12}>
                        <TextField label="Email" onChange={onEmailChange}></TextField>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField label="Парола" type={'password'} onChange={onPasswordChange} onKeyDown={onKeyDownHandler}></TextField>
                    </Grid>
                    <Grid item xs={12}>
                        <Button 
                        type="submit" 
                        fullWidth 
                        variant="contained" 
                        color="primary" 
                        size="large"
                        sx={{"borderRadius":"20px","marginBottom":"20px"}}
                        > Вход </Button>
                    </Grid>
                </Grid>
                {isCapslockOn && <p className="capslock-watcher">CapsLock</p>}
            </form>
        </Paper>
    )
}

export default LoginFormView;

import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';

import {
    Slide,
    Alert,
    Snackbar
} from "@mui/material";


import Layout from "components/Layout";
import QuizHeader from "./QuizHeader";
import QuestionView from "./QuestionView";
import QuizNavigation from "./QuizNavigation";
import QuizLoginView from './QuizLoginView';
import ResultsModal from './ResultsModal';

import { getSession, getSchools, getSessions } from 'reducers';
import { fetchSession, fetchSessions } from 'actions/sessionActions';
import { fetchSchools } from 'actions/schoolActions';
import { saveQuizAnswers } from 'actions/quizActions';
import { SKILLS, QUESTIONS, NOTIFICATION_TYPES } from 'constants.js';

import './QuizPanel.scss';
import { showNotification } from 'actions/notificationActions';

const QuizPanel = ({
    sessions,
    session,
    fetchSession,
    fetchSessions,
    fetchSchools,
    schools,
    saveQuizAnswers,
    showNotification,
}) => {
    const history = useNavigate();
    const [gender, setGender] = useState("boy");
    const [classObj, setClassObj] = useState(undefined);
    const [student, setStudent] = useState(undefined);
    const [searchParams] = useSearchParams();
    const sessionId = searchParams.get("session_id");
    const classId = searchParams.get("class_id");
    const studentNumber = searchParams.get("student_number");

    const [activeStep, setActiveStep] = useState(0);
    const [selectedAnswers, setSelectedAnswers] = useState([]);
    const [open, setSnackBarOpen] = useState(false);
    const [message, setMessage] = useState('');
    const [severity, setSeverity] = useState('success');
    const [isResultModalVisible, setIsResultsModalVisible] = useState(false);
    const [results, setResults] = useState({ data: {} });
    const [isAudioPlaying, setIsAudioPlaying] = useState(false);
    const [audio, setAudio] = useState(null);

    const [areSchoolsLoading, setAreSchoolsLoading] = useState(true);
    const [isSessionLoading, setIsSessionLoading] = useState(true);
    const [areSessionsLoading, setAreSessionsLoading] = useState(true);
    const [isQueryValid, setIsQueryValid] = useState(false);
    const [startTime, setStartTime] = useState(new Date());
    const [answerTimes, setAnswerTimes] = useState([]);

    const filteredQuestions = gender == "boy" ? QUESTIONS.filter((x, idx) => {
        return (x.gender == "boy")
    }) : QUESTIONS.filter((x, idx) => x.gender == "girl");

    let totalPoints = {
        SELF_AWARENESS: 0,
        SOCIAL_AWARENESS: 0,
        SELF_MANAGEMENT: 0,
        RELATIONSHIP_SKILLS: 0,
        RESPONSIBLE_DECISION_MAKING: 0,
    };

    let maxPointsArr = {
        SELF_AWARENESS: 0,
        SOCIAL_AWARENESS: 0,
        SELF_MANAGEMENT: 0,
        RELATIONSHIP_SKILLS: 0,
        RESPONSIBLE_DECISION_MAKING: 0,
    }


    const maxPoints = filteredQuestions.map(x => {
        let pointsArr = x.points.split(",").map(str => Number(str));
        let max = pointsArr.reduce((a, b) => Math.max(a, b), -Infinity);
        return max;
    }).reduce((sum, a) => sum + a, 0);

    const stopAudio = () => {
        if (isAudioPlaying) {
            audio.pause();
            audio.currentTime = 0;
            setIsAudioPlaying(false);
        }
    }


    useEffect(() => {
        stopAudio();
        let arr = Array.from(answerTimes);
        arr.push(new Date())
        setAnswerTimes(arr);
    }, [activeStep])

    useEffect(() => {
        setActiveStep(0);
        setSelectedAnswers([]);

    }, [gender])

    useEffect(() => {
        if (areSessionsLoading) {
            fetchSessions().then(() => {
                setAreSessionsLoading(false);
            })

            fetchSchools().then(() => {
                setAreSchoolsLoading(false);
            })
        }

    }, [])

    useEffect(() => {
        if (!areSessionsLoading) {
            if (sessionId && isSessionLoading) {
                fetchSession(sessionId).then((res) => {
                    setIsSessionLoading(false);
                    if (session.isValid) {
                        let classData = session.teacher.classes.filter(c => c.id === classId)[0];

                        if (classData) {
                            setClassObj(classData)
                            let student = classData.students.filter(s => s.number === Number(studentNumber))[0];
                            if (student) {
                                setStudent(student);
                                setGender(student.gender);
                                setIsQueryValid(true);
                            }
                            else {
                                showNotification({
                                    type: NOTIFICATION_TYPES.ERROR,
                                    message: "Невалиден ученик"
                                })
                                history('/');
                            }


                        }
                        else {

                            showNotification({
                                type: NOTIFICATION_TYPES.ERROR,
                                message: "Невалиден клас"
                            })
                            history('/');
                        }
                    }
                    // else {
                    //     showNotification({
                    //         type: NOTIFICATION_TYPES.ERROR,
                    //         message: "Невалидна сесия"
                    //     })
                    //     history('/');

                    // }
                })
            }
        }


    }, [areSessionsLoading, sessionId, session])
    const handleClose = () => {
        setSnackBarOpen(false);
    };

    const handleModalClose = () => {
        setIsResultsModalVisible(false);
    };

    const submitQuiz = () => {

        if (selectedAnswers.length !== filteredQuestions.length) {
            setMessage("Не сте попълнили всички въпроси");
            setSeverity('error');
            setSnackBarOpen(true);
            return;
        }

        let myPoints = 0;
        let answersPoints = [];
        for (let index = 0; index < filteredQuestions.length; index++) {
            let answerIndex = filteredQuestions[index].responses.split("\r\n").indexOf(selectedAnswers[index]);
            let points = filteredQuestions[index].points.split(",").map(x => Number(x));
            let currPoints = points[answerIndex];
            answersPoints.push(currPoints);
            let max = Math.max(...points);
            myPoints += currPoints;
            switch (SKILLS[filteredQuestions[index].type]) {
                case SKILLS.SELF_AWARENESS:
                    totalPoints.SELF_AWARENESS += currPoints;
                    maxPointsArr.SELF_AWARENESS += max;
                    break;

                case SKILLS.SOCIAL_AWARENESS:
                    totalPoints.SOCIAL_AWARENESS += currPoints;
                    maxPointsArr.SOCIAL_AWARENESS += max;
                    break;

                case SKILLS.SELF_MANAGEMENT:
                    totalPoints.SELF_MANAGEMENT += currPoints;
                    maxPointsArr.SELF_MANAGEMENT += max;
                    break;

                case SKILLS.RELATIONSHIP_SKILLS:
                    totalPoints.RELATIONSHIP_SKILLS += currPoints;
                    maxPointsArr.RELATIONSHIP_SKILLS += max;
                    break;

                case SKILLS.RESPONSIBLE_DECISION_MAKING:
                    totalPoints.RESPONSIBLE_DECISION_MAKING += currPoints;
                    maxPointsArr.RESPONSIBLE_DECISION_MAKING += max;
                    break;

                default:
                    console.log("Problem", filteredQuestions[index].type, filteredQuestions[index].codeId);
                    break;
            }
        }
        totalPoints.SELF_AWARENESS *= 100 / maxPointsArr.SELF_AWARENESS;
        totalPoints.SOCIAL_AWARENESS *= 100 / maxPointsArr.SOCIAL_AWARENESS;
        totalPoints.SELF_MANAGEMENT *= 100 / maxPointsArr.SELF_MANAGEMENT;
        totalPoints.RELATIONSHIP_SKILLS *= 100 / maxPointsArr.RELATIONSHIP_SKILLS;
        totalPoints.RESPONSIBLE_DECISION_MAKING *= 100 / maxPointsArr.RESPONSIBLE_DECISION_MAKING;

        let res = { points: myPoints, data: totalPoints, max: maxPoints }
        let end = new Date();

        let answersSeconds = [];
        for (let index = 0; index < answerTimes.length - 1; index++) {

            answersSeconds.push(Math.round((answerTimes[index + 1] - answerTimes[index]) / 1000));
        }
        answersSeconds.push(Math.round((end - answerTimes[answerTimes.length - 1]) / 1000));

        let data = {
            points: myPoints,
            pointsByType: totalPoints,
            answersPoints,
            max: maxPoints,
            selectedAnswers,
            startTime,
            endTime: end,
            totalTime: Math.round((end - startTime) / 1000),
            answersTime: answersSeconds,
            sessionId,
            schoolId: session.school,
            teacherId: session.teacherId,
            classId,
            year: session.year,
            type: session.type,
            gender,
            student,

        };
        setResults(res);
        setIsResultsModalVisible(true);
        saveQuizAnswers(data);

    }

    function TransitionUp(props) {
        return <Slide {...props} direction="down"><Alert variant="filled" severity={severity} sx={{ width: '100%' }}>
            {message}</Alert></Slide>;
    }

    return (
        <Layout footer={false}>
            <ResultsModal
                open={isResultModalVisible}
                onClose={handleModalClose}
                results={results}

            />

            <Snackbar
                autoHideDuration={3000}
                open={open}
                onClose={handleClose}
                TransitionComponent={TransitionUp}
                message={message}
                transitionDuration={500}
                anchorOrigin={{ vertical: "top", horizontal: "left" }}

            />

            {isQueryValid ?
                <>
                    <QuizHeader
                        question={filteredQuestions[activeStep]}
                        index={activeStep}
                        isAudioPlaying={isAudioPlaying}
                        setIsAudioPlaying={setIsAudioPlaying}
                        audio={audio}
                        setAudio={setAudio}
                    />
                    <QuestionView
                        steps={filteredQuestions.length}
                        selectedAnswers={selectedAnswers}
                        question={filteredQuestions[activeStep]}
                        activeStep={activeStep}
                        setActiveStep={setActiveStep}
                        submitQuiz={submitQuiz}
                        isAudioPlaying={isAudioPlaying}
                        setIsAudioPlaying={setIsAudioPlaying}
                        audio={audio}
                        setAudio={setAudio}
                    />
                    <QuizNavigation
                        steps={filteredQuestions.length}
                        activeStep={activeStep}
                        setActiveStep={setActiveStep}
                    />
                </>
                :
                <>
                    {/* {areSchoolsLoading ? null :
                        <QuizLoginView
                            schools={schools}
                            sessions={sessions}
                            session={session.isValid ? session : undefined}
                            classData={classObj}
                            student={student}
                        />
                    } */}

                </>

            }


        </Layout>
    )

}

const mapStateToProps = (state) => ({
    sessions: getSessions(state),
    session: getSession(state),
    schools: getSchools(state),
});

const mapDispatchToProps = {
    fetchSession,
    fetchSessions,
    fetchSchools,
    saveQuizAnswers,
    showNotification
};

export default connect(mapStateToProps, mapDispatchToProps)(QuizPanel);
import { combineReducers } from 'redux';

import {
    FETCH_QUIZ_ANSWERS_SUCCESS,
    FETCH_QUIZ_ANSWER_SUCCESS,
} from '../actions/actionTypes';



const quizAnswers = (state = [], action) => {
    switch (action.type) {
        case FETCH_QUIZ_ANSWERS_SUCCESS:
            return { ...action.payload };
        default:
            return state;
    }
};

const currentQuizAnswerInitialState = {
    _id: '',
    name: '',
    town: '',
    password: '',
    verified: false,
    teachers: [{}],
    administrator: {},
};

const currentQuizAnswer = (state = currentQuizAnswerInitialState, action) => {
    switch (action.type) {
        case FETCH_QUIZ_ANSWER_SUCCESS:
            return { ...currentQuizAnswerInitialState, ...action.payload };
        default:
            return state;
    }
};

export default combineReducers({
    list: quizAnswers,
    current: currentQuizAnswer,
});

export const getQuizAnswers = (state) => state.list;
export const getQuizAnswer = (state) => state.current;
import {
    SHOW_NOTIFICATION,
    HIDE_NOTIFICATION,
} from 'actions/actionTypes';

const initialState = {
    message: '',
    type: ''
}

const error = (state = initialState, action) => {
    switch (action.type) {
        case SHOW_NOTIFICATION:
            return action.payload;
        case HIDE_NOTIFICATION:
            return initialState;
        default:
            return state;
    }
};

export default error;

export const hasNotification = (state) => Boolean(state.message);
export const getMessage = (state) => state.message;
export const getType = (state) => state.type;

import { Link } from "react-router-dom";
import { Paper, Button, Box } from "@mui/material";
import { styled } from "@mui/material/styles";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const StudentCard = ({ student, to, quizLink = false }) => {
  return (
    <Box>
      <Item>
        {student.name}{" "}
        {quizLink ? (
          <Button
            component={Link}
            to={`${to}&student_number=${student.number}`}
          >
            Линк към сесията на ученикa
          </Button>
        ) : null}
      </Item>
    </Box>
  );
};

export default StudentCard;

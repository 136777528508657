
import { Link } from "react-router-dom";

import Layout from 'components/Layout';

import { ReactComponent as Kids } from './assets/kids.svg';
import { ReactComponent as Kindergarden } from './assets/kindergarden.svg';
import { ReactComponent as Teddy } from './assets/teddy.svg';
import infoCasel from './assets/CASEL.jpg';
import oecdWeb from './assets/oecdWeb.jpg';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { Typography } from '@mui/material';
import {
    Paper, Button
} from '@mui/material';

import './Home.scss';
import HomeInformation from './HomeInformation';
import { info } from 'sass';


const OISR = () => {
    return (
        <Layout>
            <section className="home-section">
                <Paper elevation={12}>
                    <div className="home-container container">
                        <div className="home-info">
                            <p>Фондация “Лъчезар Цоцорков”, с подкрепа на МОН договори включване на България в международно проучване за социално-емоционални умения на ученици на възраст 15 г., провеждано от Организацията за икономическо сътрудничество и развитие в Европа (ОИСР).</p>
                            <p>Международното събитие за оповестяване на резултатите се състоя на 26ти април 2024г.
                                <br />
                                <br />
                                За повече информация:
                            </p> <Link to={'/oecdedu'}> <Button variant="contained" color="primary" style={{ marginTop: '10px' }}>
                                линк
                            </Button></Link>
                            <br></br> <br />
                            <a href="Резюме%20на%20резултатите%20от%20Изследването%20на%20СЕУ,%20ОИСР%202023.pdf" download>
                                <Button variant="contained" color="secondary" style={{ marginTop: '10px' }}>
                                    Резюме на резултатите от Изследването на СЕУ, ОИСР 2023
                                </Button>
                            </a>
                            <br></br>
                            <a href="БГ Доклад ИСЕУ 2023-2.pdf" download>
                                <Button variant="contained" color="secondary" style={{ marginTop: '10px' }}>
                                    БГ Доклад ИСЕУ 2023
                                </Button>
                            </a>

                        </div>
                    </div>
                </Paper>
            </section>
        </Layout>
    )
}

export default OISR;

import { Link } from "react-router-dom";

import Layout from 'components/Layout';

import { ReactComponent as Kids } from './assets/kids.svg';
import { ReactComponent as Kindergarden } from './assets/kindergarden.svg';
import { ReactComponent as Teddy } from './assets/teddy.svg';
import infoCasel from './assets/CASEL.jpg';
import oecdWeb from './assets/oecdWeb.jpg';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { Typography } from '@mui/material';
import {
    Paper, Button
} from '@mui/material';

import './Home.scss';
import HomeInformation from './HomeInformation';
import { info } from 'sass';


const Home = () => {
    return (
        <Layout>
            <section className="home-section">
                <Paper elevation={12}>
                    <div className="home-container container">
                        <div className="quote">
                            <h1>“Основно умение в социалното познание е емпатията - осъзнаването, че другите чувстват и мислят, без да ни го назовават с думи”.
                            </h1>
                            <h2>Даниъл Голман</h2>
                        </div>
                    </div>
                </Paper>
                <Paper elevation={12}>
                    <div className="home-container container">
                        <div className="home-graphics">
                            <Kindergarden />
                        </div>
                        <div className="home-info">
                            <h1>Информация</h1>
                            <p>Социално-емоционалното образование (СЕО) помага на учениците да направят връзките между изучаваното съдържание и приложението му в реалните житейски ситуации. Вплитането на социално-емоционалното в различни предмети, уроци, занятия и използването му в многообразни ситуации ни помага в изграждането на функционални и обществено ангажирани личности.
                            </p>
                            <br />
                            <p>Програмите за социално и емоционално образование (СЕО) са сред най-успешните интервенции за насърчаване на положителното развитие на децата. Резултатите от 213 контролирани проучвания показват, че прилагането на такива програми подобрява академичните постижения и просоциалното развитие на учениците, като същевременно намалява риска от поведенчески и емоционални проблеми (Durlak, Weissberg, Dymnicki, Taylor, & Schellinger, 2011).
                            </p>
                        </div>
                    </div>
                </Paper>
                <Paper elevation={12}>
                    <div className="home-container container">
                        <div className="home-info">
                            <h1>Понятия и терминология</h1>
                            <p>
                                Социално-емоционални умения (СЕУ) се разделят на когнитивни, социални и емоционални; динамични са – могат да се развиват през целия живот, и са във взаимовръзка с нашите нагласи, вярвания, характер и ценностна система. Този спектър от умения, чието развиване, пренесено във формална образователна среда, представлява социално-емоционалното образование, СЕО.
                            </p>
                        </div>
                    </div>
                </Paper>
                <Paper elevation={12}>
                    <div className="home-container container">
                        <div className="home-info">
                            <h1>Какви са ползите?</h1>
                            <p>СЕО е сред най-успешните интервенции, с 213 контролирани проучвания, които показват следните ползи в училище:</p>
                            <p><CheckCircleOutlineIcon /> подобрява академичните постижения;</p>
                            <p><CheckCircleOutlineIcon /> укрепва психичното здраве;</p>
                            <p><CheckCircleOutlineIcon /> повишава социалното включване и намалява отпадането от училище;</p>
                            <p><CheckCircleOutlineIcon /> подпомага успешното управление на класната стая;</p>
                            <p><CheckCircleOutlineIcon /> усилва самостоятелността и самоуправлението на учениците;</p>
                            <p><CheckCircleOutlineIcon /> намалява проблемното поведение и нивата на насилие сред учениците;</p>
                        </div>
                        <div className="home-graphics">
                            <Kids />
                        </div>
                    </div>
                </Paper>
                <Paper elevation={12}>
                    <div className="home-container container">
                        <div className="home-graphics">
                            <img src={infoCasel} style={{ maxWidth: "425px", margin: "0 auto" }} />
                        </div>
                        <div className="home-info">
                            <h1>Рамка</h1>
                            <p>Съществуват различни програми и методики, които въвеждат социално-емоционалното образование в училището и академичните среди, които малко или много се адаптират според конкретната среда и държава.
                            </p>
                            <br />
                            <p>Модерна концепция описана през 1990 г от психолога Джон Майер, която категоризира следните социо-емоционални умения (СЕУ):
                            </p>
                            <br />
                            <ol style={{ padding: "0 0 0 40px", margin: "0 0 10px" }}>
                                <li>Себепознание</li>
                                <li>Самообладние</li>
                                <li>Мотивация</li>
                                <li>Социални умения</li>
                                <li>Съпричастност</li>
                            </ol>
                            <p style={{ display: "block" }}>
                                Съществуват <a href="http://exploresel.gse.harvard.edu/" target="_blank" >многобройни рамки</a> за СЕУ,  като най приетата поради опростения и събирателен модел, който представлява е <a href="https://casel.org/what-is-sel/" target="_blank">КАСЕЛ</a>. КАСЕЛ е рамката, която се прилага от Фондация “Лъчезар Цоцорков”.  <a href="https://drive.google.com/file/d/1cv2znSVXbspg7zUSJNSXfOIW42jOkjrF/view?usp=drive_link" target="_blank">Този документ</a> има за цел да сравни социално-емоционалните компетентности, предложени от КАСЕЛ с тези заложени в европейските рамкови документи и българското законодателство.
                            </p>
                        </div>

                    </div>

                </Paper>
            </section>
        </Layout>
    )
}

export default Home;
import MobileStepper from '@mui/material/MobileStepper';

const QuizNavigation = ({
    steps,
    activeStep,
}) => {

    return (
        <MobileStepper
            variant="progress"
            steps={steps}
            LinearProgressProps={{ sx: { "height": "30px", borderRadius: "30px", width: "100%" } }}
            position="static"
            activeStep={activeStep}
        />

    )
}

export default QuizNavigation;
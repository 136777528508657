import React, { useState } from "react";
import moment from 'moment';
import {
  Box,
  TextField,
  Button,
  Icon,
  Paper,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  DialogActions,
  Slide,
  Typography,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from "@mui/material";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Draggable from "react-draggable";

import Table from "@mui/material/Table";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import { defaultStudentStateShape } from "./constants";

import "./CreateClassModal.scss";

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper style={{ maxWidth: "800px" }}{...props} />
    </Draggable>
  );
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const CreateClassModal = ({ open, handleClose, handleSubmitForm }) => {
  const [name, setNameValue] = useState("");
  const [students, setStudents] = useState([defaultStudentStateShape]);
  const onNameChange = (event) => setNameValue(event.target.value);

  const addStudentRow = () => {
    setStudents([...students, defaultStudentStateShape]);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const studentsWithNumbers = students.map((s, i) => ({
      ...s,
      number: i + 1,
    }));
    handleSubmitForm({ name, students: studentsWithNumbers });
    setStudents([defaultStudentStateShape]);
    handleClose();
  };

  const handleDateChange = (e, index) => {
    if (e.isValid()) {
      setStudents((currStudents) => {
        const copyCurrentStudents = currStudents.slice();
        copyCurrentStudents[index] = {
          ...copyCurrentStudents[index],
          birthdate: e.toISOString().split("T")[0],
        };
        return copyCurrentStudents;
      })
    }
  };

  const handleStudentChange = (e, index) => {
    setStudents((currStudents) => {
      const copyCurrentStudents = currStudents.slice();
      copyCurrentStudents[index] = {
        ...copyCurrentStudents[index],
        [e.target.name]: e.target.value,
      };
      return copyCurrentStudents;
    });
  };

  const handleDialogClose = () => {
    setStudents([defaultStudentStateShape]);
    handleClose();
  };

  return (
    <Dialog
      open={open}
      onSubmit={handleSubmit}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
      scroll="paper"
      TransitionComponent={Transition}

    >
      <form style={{ maxWidth: "800px" }}>
        <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
          Добави клас
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Добавете името на класа и учениците в него.
          </DialogContentText>
          <Box className="class-primary-data">
            <TextField
              required
              id="outlined-basic"
              label="Име на клас"
              variant="outlined"
              onChange={onNameChange}
            />
          </Box>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align="justify">Номер</TableCell>
                  <TableCell align="justify">Имена</TableCell>
                  <TableCell align="justify">Пол</TableCell>
                  <TableCell align="justify">Дата на раждане</TableCell>
                </TableRow>
              </TableHead>
              {students.length ? (
                <>
                  {students.map((student, index) => (
                    <TableRow
                      key={index}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell align="justify" className="student-number">
                        <Typography align="center">{index + 1}</Typography>
                      </TableCell>
                      <TableCell align="justify" className="student-name">
                        <TextField
                          required
                          id="outlined-required"
                          label="Имена"
                          defaultValue={student.name}
                          size="small"
                          name="name"
                          onChange={(e) => handleStudentChange(e, index)}
                        />
                      </TableCell>
                      <TableCell>
                        <FormControl className="student-gender" size="small">
                          <InputLabel id="student-gender-label">Пол</InputLabel>
                          <Select
                            labelId="student-gender-label"
                            required
                            id="student-gender"
                            name="gender"
                            value={student.gender}
                            label="Пол"
                            onChange={(e) => handleStudentChange(e, index)}
                          >
                            <MenuItem value="boy">Момче</MenuItem>
                            <MenuItem value="girl">Момиче</MenuItem>
                          </Select>
                        </FormControl>
                      </TableCell>
                      <TableCell align="justify" className="student-birthdate">
                        <DatePicker
                          required
                          id="outlined-required"
                          label="Дата"
                          defaultValue={moment(student.birthdate)}
                          size="small"
                          name="birthdate"
                          onChange={(e) => handleDateChange(e, index)}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </>
              ) : null}
            </Table>
          </TableContainer>
          <Box>
            <Button className="add-button" onClick={addStudentRow}>
              <span>Добави ученик</span>
              <Icon>add_circle</Icon>
            </Button>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose}>Отказ</Button>
          <Button type="submit">Добави клас</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default CreateClassModal;

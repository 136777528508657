import {
    Modal,
    Box,
    Button,
    Typography
} from '@mui/material';
import { useNavigate } from 'react-router-dom';

const ResultsModal = ({
    open,
    onClose,
}) => {

    const history = useNavigate(); // Initialize the useHistory hook

    const handleCloseAndRedirect = () => {
        onClose();
        history('/'); // Redirect to '/'
    }
    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: "40%",
                bgcolor: 'background.paper',
                border: '2px solid #000',
                borderRadius: "15px",
                boxShadow: 24,
                p: 4,
            }}>
                <Typography id="modal-modal-title" variant="h6" textAlign={"center"} component="h2">
                    Браво!
                    Благодаря, че попълнихте въпросника!
                </Typography>
                <Button
                    onClick={handleCloseAndRedirect}
                    variant="contained"
                    color="primary"
                    sx={{ mt: 2, display: 'block', marginLeft: 'auto', marginRight: 'auto' }}
                >
                    Затвори
                </Button>
            </Box>
        </Modal >
    )



}


export default ResultsModal;
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import ArrowForward from '@mui/icons-material/ArrowForward';
import {
    Box,
    Button,
    ToggleButtonGroup,
    Card,
} from "@mui/material";

import ReactPlayer from 'react-player';
import './QuestionView.scss';
import AudioButton from '../AudioButton/AudioButton';

const QuestionView = ({
    steps,
    question,
    selectedAnswers,
    activeStep,
    setActiveStep,
    submitQuiz,
    isAudioPlaying,
    setIsAudioPlaying,
    audio,
    setAudio,
}) => {
    const [alignment, setAlignment] = useState();
    const [showNextButton, setShowNextButton] = useState(false);
    const [activeAnswer, setActiveAnswer] = useState(null);
    const [selected, setSelected] = useState(false);

    useEffect(() => {
        const selectedAnswer = selectedAnswers[activeStep];
        if (selectedAnswer) {
            setAlignment(selectedAnswer);
        }
        else {
            setAlignment(null);
        }
    }, [activeStep, selectedAnswers]);

    const handleChange = (event, newAlignment) => {
        setAlignment(newAlignment);
        selectedAnswers[activeStep] = event.target.value;
        handleNext();
    };

    const handleSelect = (event, newAlignment) => {
        setAlignment(newAlignment);
        selectedAnswers[activeStep] = event.target.value;
        setSelected(event.target.value);
        setActiveAnswer(event.target.value);
        setShowNextButton(true);
    };

    const handleNext = () => {
        if (activeStep < steps - 1) {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
        else {
            submitQuiz();
        }
        setShowNextButton(false);
        setSelected();
    };

    return (
        <section className="question-section">
            {question.video ?

                <Card className="question-video-container">

                    <ReactPlayer
                        className="question-video"
                        // width=" !important"
                        // height=" !important"
                        url={`/video/${question.video}`}
                        playing={true}
                        controls={true}
                        light={<img src='/images/tutorial-thumbnail.png' alt='Thumbnail' />}
                        // muted
                        config={{
                            youtube: {
                                playerVars: {
                                    controls: 1,
                                    autoplay: 1,

                                },
                                embedOptions: {

                                    muted: 1,
                                }
                            }
                        }}

                    >

                    </ReactPlayer>
                    {
                        showNextButton ?
                            <NextButtonWrapper>
                                <Button className="question-next-button"
                                    color="primary"
                                    variant='contained'
                                    endIcon={<ArrowForward />}
                                    onClick={handleNext}
                                    style={{ margin: "0 auto", fontSize: "1.4rem", borderRadius: "50px" }}
                                >
                                    СЛЕДВАЩ ВЪПРОС
                                </Button>
                            </NextButtonWrapper>
                            :
                            null
                    }
                </Card>
                : <Card className="question-video-container">
                    {
                        showNextButton ?
                            <NextButtonWrapper>
                                <Button className="question-next-button"
                                    color="primary"
                                    variant='contained'
                                    endIcon={<ArrowForward />}
                                    onClick={handleNext}
                                    style={{ margin: "0 auto", fontSize: "1.4rem", borderRadius: "50px" }}
                                >
                                    СЛЕДВАЩ ВЪПРОС
                                </Button>
                            </NextButtonWrapper>
                            :
                            null
                    }
                    <img alt={question.question} className="question-image" src={`/images/${question.image}`}>
                    </img>
                </Card>
            }

            {/* <h1 className="question-heading">{question.title}</h1> */}
            <h2 className="question-heading">{question.question}
                {question.audio?.question != undefined ?
                    <AudioButton
                        audioPath={`/audio/${question.audio?.question}`}
                        isAudioPlaying={isAudioPlaying}
                        setIsAudioPlaying={setIsAudioPlaying}
                        audio={audio}
                        setAudio={setAudio}
                    ></AudioButton> : null
                }
            </h2>


            <Box>

                <ToggleButtonGroup
                    className="question-answers-container"
                    color="primary"
                    value={alignment}
                    exclusive={true}
                // onChange={handleChange}
                >
                    {question.responses.split("\r\n").map((answer, x) => {
                        let sound = question.audio?.responses[x];
                        return (
                            <ButtonWrapper key={x}>
                                {selected == answer ?
                                    <Button
                                        className={"question-answer selected"}
                                        key={x}
                                        value={answer}
                                        style={{ border: '1px solid', borderRadius: '10px', width: '100%' }}
                                        onClick={handleSelect}
                                    >{answer}

                                    </Button> :
                                    <Button
                                        className={"question-answer"}
                                        key={x}
                                        value={answer}
                                        style={{ border: '1px solid', borderRadius: '10px', width: '100%' }}
                                        onClick={handleSelect}
                                    >{answer}

                                    </Button>

                                }
                                {question.audio?.responses != undefined ?
                                    <AudioButton
                                        audioPath={`/audio/${sound}`}
                                        isAudioPlaying={isAudioPlaying}
                                        setIsAudioPlaying={setIsAudioPlaying}
                                        audio={audio}
                                        setAudio={setAudio}
                                    ></AudioButton>
                                    : null
                                }
                            </ButtonWrapper>
                        )
                    })}
                </ToggleButtonGroup>
            </Box>
        </section >
    )

}

export default QuestionView;

const ButtonWrapper = styled.div`
    display: flex;
    min-width: 300px;
    margin: 5px;
    margin-left: 5px !important;
`;

const NextButtonWrapper = styled.div`
    display: flex;
    position: absolute;
    bottom: 10px !important;
`;
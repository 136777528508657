import {
  UPDATE_IS_CREATE_CLASS_MODAL_VISIBLE,
  UPDATE_IS_EDIT_CLASS_MODAL_VISIBLE,
  UPDATE_IS_CREATE_SESSION_MODAL_VISIBLE,
  UPDATE_IS_SNACKBAR_OPEN,
  UPDATE_TAB,
  UPDATE_SELECTED_CLASS,
} from "./action-types";

export const updateIsSnackbarOpen = (payload) => ({
  type: UPDATE_IS_SNACKBAR_OPEN,
  payload,
});

export const updateTab = (payload) => ({
  type: UPDATE_TAB,
  payload,
});

export const updateSelectedClass = (payload) => ({
  type: UPDATE_SELECTED_CLASS,
  payload
});

export const updateIsCreateClassModalVisible = (payload) => ({
  type: UPDATE_IS_CREATE_CLASS_MODAL_VISIBLE,
  payload,
});

export const updateIsEditClassModalVisible = (payload) => ({
  type: UPDATE_IS_EDIT_CLASS_MODAL_VISIBLE,
  payload,
});

export const updateIsCreateSessionModalVisible = (payload) => ({
  type: UPDATE_IS_CREATE_SESSION_MODAL_VISIBLE,
  payload,
});

import { Navigate } from "react-router-dom";
import React from 'react';
import { connect } from 'react-redux';
import {
    isAuthenticated as isUserAuthenticated,
    getUserRoles
} from 'reducers';

const ProtectedRoute = ({ isUserAuthenticated, roles, userRoles, outlet }) => {
    if (isUserAuthenticated && userRoles.some(role => roles.includes(role))) {
        return outlet;
    }
    else {
        return <Navigate to={{ pathname: '/' }} />;
    }
}

const mapStateToProps = (state) => ({
    isUserAuthenticated: isUserAuthenticated(state),
    userRoles: getUserRoles(state),
});

export default connect(mapStateToProps)(ProtectedRoute);
import Footer from 'components/Footer';
import Navigation from './Navigation';

const Layout = ({
    children,
    footer = true
}) => {
    return (
        <>
            <Navigation />
            <div className="page-wrapper">
                {children}
            </div>
            {footer ?
                <Footer />
                :
                null
            }
        </>

    )
}

export default Layout;
import React, { useEffect } from "react";

import {
  Grid,
  Tooltip,
  IconButton,
  Paper,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";

import DeleteIcon from "@mui/icons-material/Delete";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { styled } from "@mui/material/styles";
import ClassCard from "../ClassCard";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const SessionCard = ({ user, session, deleteSession }) => {
  useEffect(() => { });

  return (
    <Grid item xs={4}>
      <Item>
        {session.year} {session.type}
        <Tooltip title="изтрий">
          <IconButton onClick={() => deleteSession(user.id, session.id)}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      </Item>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>класове</Typography>
        </AccordionSummary>
        <AccordionDetails>
          {user.classes?.map((cl) => {
            return cl ? (
              <ClassCard
                key={cl.id}
                userId={user.id}
                classData={cl}
                fullWidth
                editMode={false}
                quizLink={true}
                to={`/quiz-panel?session_id=${session.id}`}
              />
            ) : null;
          })}
        </AccordionDetails>
      </Accordion>
    </Grid>
  );
};

export default SessionCard;
